import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';

import { RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';
import { FilterPipe } from './pipes/filter.pipe';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ExportModalComponent } from './components/export-modal/export-modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        FilterPipe,
        ExportModalComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        NgSelectModule,
        FormsModule,
        NgxDaterangepickerMd.forRoot(),
    ],
    exports: [
        MaterialModule,
        FilterPipe,
        ExportModalComponent
    ],
    providers: [

    ],
    entryComponents: [
        ExportModalComponent
    ]
})
export class SharedModule { }
