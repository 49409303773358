import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorComponent } from '../components/dialogs/error/error.component';
import { SuccessComponent } from '../components/dialogs/success/success.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public dialog: MatDialog) { }

  success(payload: any) {
    const dialogRef = this.dialog.open(SuccessComponent, {
      width: '560px',
      data: payload
    });
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
         dialogRef.close();
      }, 600)
    })
  }

  error(payload: any) {
    this.dialog.open(ErrorComponent, {
      width: '700px',
      data: payload
    });
  }

  invalidSession(err: any) {
    console.warn('error: ', err);
    this.error(
      {
        message: 'Your session is no longer valid. Try the following steps to restore your session',
        response: [
          'Refresh your browser',
          'Log out and log back in'
        ]
      }
    );
  }

}
