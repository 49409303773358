import { ApiService } from '@/shared/services/api.service';
import { ExportAsConfig, ExportAsService } from '@/shared/services/export.service';
import { ConfigurationService } from '@/shared/services/configuration.service';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastMessageComponent } from '@/cpm/components/toast-message/toast-message.component';
import { UserService } from '@/cpm/shared/services/user.service';

@Component({
    selector: 'app-export-modal',
    templateUrl: "./export-modal.component.html",
    styleUrls: ["./export-modal.component.scss"]
})

export class ExportModalComponent {
    linkCopiedToClipboard: boolean;
    embedCopiedToClipboard: boolean;
    embedLink: any;
    link: any;
    sharableWebpage: boolean;
    linkViewUrl: string;
    cpmURL: any;
    loginUserTypeData: any;

    constructor(public dialogRef: MatDialogRef<ExportModalComponent>,
        public apiService: ApiService,
        private clipboard: Clipboard,
        public configService: ConfigurationService,
        private exportAsService: ExportAsService,
        private toastMessage: MatSnackBar,
        private userService: UserService,
        @Inject(DIALOG_DATA) public data) {

            this.cpmURL = this.configService.getConfig('pasUrl');
        }


    showLoader: boolean = false;
    selectedTab = 'download';
    config: ExportAsConfig = {
        type: 'pdf',
        elementIds: [],
        idType:'id',
        options: {
        jsPDF: {
            unit: 'in', format: 'a2',                 
            orientation: 'landscape',
        },
        allowTaint: false,
        useCORS: true,
        }
    };
    
    exportOptions: Array<any> = [
        {
            type: 'json',
            name: 'JSON',
            source: ['plant']
        },
        {
            type: 'trio',
            name: 'Trio',
            source: ['plant']
        },
        {
            type: 'pdf',
            name: 'PDF',
            source: []
        },
        {
            type: 'png',
            name: 'PNG',
            source: ['click', 'plant']
        },
        {
            type: 'xlsx',
            name: 'XLSX',
            source: ['click']
        }
    ];
    selectedOption: any = '';
    isLoading = false;

    ngOnInit() {
        this.loginUserTypeData = this.userService.getUserType();
        const protocol = window.location.protocol;
        const host = window.location.host;
        let source = this.data.fromPlant ? 'plant':'click'
        this.exportOptions  = this.exportOptions.filter((option)=> option.source.indexOf(source) > -1);
        this.selectedOption = this.exportOptions[0].type;
        this.linkViewUrl = `${protocol}//${host}/plant/public`;
    }

    onOptionChange(event) {
        this.selectedOption = event;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    download() {
        const elementId = this.data.fromPlant ? ['drawflow']: ['clicked-view-modal'];
        this.config.type = this.selectedOption;
        this.config.elementIds = elementId;
        const fileName = `${this.data.fromPlant?this.data.selectedPlantData.chillerPlantName:this.data.selectedPlantData.name}`;
        this.showLoader = true;
        setTimeout(() => {
            if (this.config.type == 'png') {
                this.hideScrollFromTheView();
                this.exportAsService.save(this.config, fileName).subscribe({
                    next: () => {
                        this.showScrollInTheView();
                        this.showLoader = false;
                        this.dialogRef.close();
                    },
                    error: () => {
                        this.showScrollInTheView();
                        this.showLoader = false;
                        this.showToast('error','Failed to download');
                    }
                });
            } else if (this.config.type == 'xlsx') {
                this.exportAsService.exportExcel(fileName,this.data).then(()=>{
                    this.showLoader = false;
                    this.dialogRef.close();
                }).catch(()=>{
                    this.showLoader = false;
                    this.showToast('error','Failed to download');
                })
            } else if(this.config.type == 'pdf') {
                this.hideScrollFromTheView();
                this.exportAsService.getPdf(this.config,fileName).subscribe({
                    next: () => {
                        this.showScrollInTheView();
                        this.showLoader = false;
                        this.dialogRef.close();
                    },
                    error: () => {
                        this.showScrollInTheView();
                        this.showLoader = false;
                        this.showToast('error','Failed to download');
                    }
                });
              
            } else {
                if(!this.loginUserTypeData.isPublic){
                    this.apiService.getPlantJson(this.data.selectedPlantData.chillerPlantId).subscribe({
                        next: (res) => {
                            this.exportJsonTrio(res);
                        },
                        error: () => {
                            this.showToast('error','Failed to download');
                            this.showLoader = false;
                        },
                        complete: () => {}
                    })
                }else {
                    this.apiService.getSharedPlantJson(this.loginUserTypeData.linkShareUuid).subscribe({
                        next: (res) => {
                          this.exportJsonTrio(res);
                        },
                        error: () => {
                            this.showToast('error','Failed to download');
                            this.showLoader = false;
                        },
                        complete: () => {}
                    })
                }
            }
        }, 100);
    }

    exportJsonTrio(res){
        if(this.config.type == 'json') {
            this.exportJsonFile(res);
        }
        else if( this.config.type == 'trio') {
            this.exportTrioFile(res);
        }
    }

    exportTrioFile(res) {
        this.exportAsService.exportTRIOFile(res?.rows || {}, this.data.selectedPlantData.chillerPlantName).subscribe( {
            next: (res) => {
                this.showLoader = false;
                this.dialogRef.close();
            },
            error: () => {
                this.showLoader = false;
                this.showToast('error','Failed to download');
            },
            complete: () => {
            }
        })
    }

    exportJsonFile(res) {
        this.exportAsService.exportJsonFile(res?.rows || {}, this.data.selectedPlantData.chillerPlantName).subscribe( {
            next: (res) => {
                this.showLoader = false;
                this.dialogRef.close();
            },
            error: () => {
                this.showLoader = false;
                this.showToast('error','Failed to download');
            },
            complete: () => {
            }
        })
    }

     // Toast Message 
  showToast(type: string, message: string) {
    this.toastMessage.openFromComponent(ToastMessageComponent, {
      duration: 5000,
      data: {
        type: type,
        message: message
      },
    });
  }

    onTabSelect(tab) {
        this.selectedTab = tab;
        if (tab === 'webpage') {
            // Fetch any sharable Urls for this dashboard -
            this.fetchShareLink();
        }
    }

    fetchShareLink() {
        // Fetch any sharable Urls for this dashboard -
        this.isLoading = true;
        this.apiService.fetchShareLink(this.data.selectedPlantData.chillerPlantId).subscribe(
            (response: any) => {
                this.isLoading = false;
                if (response.linkShareUuid && response.shareEnabled) {
                    this.sharableWebpage = true;
                    this.setLinkAndEmbedLink(response);
                }
            }
        );
    }

    copyLink() {
        this.clipboard.copy(this.link);
        this.linkCopiedToClipboard = true;
        setTimeout(() => {
            this.linkCopiedToClipboard = false;
        }, 1500);
    }

    copyEmbedText() {
        this.clipboard.copy(this.embedLink);
        this.embedCopiedToClipboard = true;
        setTimeout(() => {
            this.embedCopiedToClipboard = false;
        }, 1500);
    }

    setLinkAndEmbedLink(response) {
        const protocol = window.location.protocol;
        const host = window.location.host;
        this.linkViewUrl = `${protocol}//${host}/plant/public`;
        let link = `${this.linkViewUrl}/${response.linkShareUuid}`;
        this.embedLink = `<iframe src="${link}&embed=true">`;
        this.link = link;
    }

    onWebpageChange(event) {
        if (event.checked) {
            this.isLoading = true;
            // First make a call to fetch the link Share uuid
            this.apiService.fetchShareLink(this.data?.selectedPlantData?.chillerPlantId).subscribe(
                (response: any) => {
                    if (response && response.linkShareUuid) {
                        this.apiService.enableDisableLink(this.data?.selectedPlantData?.chillerPlantId,true)
                            .subscribe((_: any) => {
                                this.sharableWebpage = true;
                                this.isLoading = false;
                                this.setLinkAndEmbedLink(response);
                            });
                    } else {
                        this.generateShareLink();
                    }
                }
            );
        } else {
            // This does not make a delete call anymore. Just a PUT request to set it to off
            this.isLoading = true;
            this.apiService.enableDisableLink(this.data?.selectedPlantData?.chillerPlantId,false)
                .subscribe((_: any) => {
                    this.sharableWebpage = false;
                    this.isLoading = false;
                });
        }
    }


    generateShareLink() {
        this.isLoading = true;
        this.apiService.generateSharedLink(this.data?.selectedPlantData?.chillerPlantId)
            .subscribe((response: any) => {
                this.isLoading = false;
                if (response.linkShareUuid) {
                    this.sharableWebpage = true;
                    this.setLinkAndEmbedLink(response);
                }
            });
    }

    hideScrollFromTheView() {
        if (document.getElementById('pumpOrCT')) {
            (<HTMLElement>document.getElementById('pumpOrCT')).style.overflow = 'hidden';
        }
        if (document.getElementsByClassName('pointlist-container')[0]) {
            (<HTMLElement>document.getElementsByClassName('pointlist-container')[0]).style.overflow = 'hidden';
        }  else if (document.querySelectorAll('.pointContainerHeight')?.length) {
            document.querySelectorAll('.pointContainerHeight').forEach(_el => {
                _el['style'].overflow = 'hidden';
            });
        }
    }

    showScrollInTheView() {
        if (document.getElementById('pumpOrCT')) {
            (<HTMLElement>document.getElementById('pumpOrCT')).style.overflow = 'auto';
        }
        if (document.getElementsByClassName('pointlist-container')[0]) {
            (<HTMLElement>document.getElementsByClassName('pointlist-container')[0]).style.overflow = 'auto';
        }  else if (document.querySelectorAll('.pointContainerHeight')?.length) {
            document.querySelectorAll('.pointContainerHeight').forEach(_el => {
                _el['style'].overflow = 'auto';
            });
        }
    }
   
    
}