import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})

export class HelperMethodsService {
    formatEnums(data:any) {
        if (data['enum']) {
          if (data['enum'].includes(',')) {
            data['enum'] = data['enum'].split(',');
          } else {
            data['enum'] = '';
          }
          if (data['enum'] && data['enum'].length) {
            data['enum'] = data['enum'].map((val, i) => {
              const enums = val.split('=');
              if (enums.length > 0) {
                return {
                  value: enums[1] ? enums[1] : i,
                  name: enums[0]
                }
              }
            })
          }
        }
    
        if (data && data.hasOwnProperty('writable') && data['enum'] == undefined) {
          if (!data['enum']) {
            data['options'] = this.generateRange(Number(data['minVal']), Number(data['maxVal']), Number(data['incrementVal']),data['unit']);
          }
        }
        return data;
      }
    
      generateRange(min, max, step, unit) {
        let arr = [];
        for (let i = min; i <= max;) {
          arr.push({name: `${i} ${unit != undefined ? unit:""}`, value: `${i}`});
          i = (i * 10 + step * 10) / 10
        }
        return arr;
      }
}