<div  *ngIf="data.type == 'success'">
    <i class="fa fa-check toast-icon success-toast" aria-hidden="true"></i> <span class="success-toast">Success!</span>
    <span clas="toast-message">
        {{data.message}}
    </span>
</div>

<div  *ngIf="data.type == 'error'">
    <i class="fa fa-exclamation-triangle toast-icon error-toast" aria-hidden="true"></i>  <span class="error-toast">Error!</span>
    <span clas="toast-message">
        {{data.message}}
    </span>
</div>