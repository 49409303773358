import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { AlertService } from '@/shared/services/alert.service';
import { map } from 'rxjs/internal/operators/map';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ApiService } from '@/shared/services/api.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { EditPlantModalComponent } from 'src/app/cpm/components/edit-plant-modal/edit-plant-modal.component';
import { LocalStorageService } from '@/cpm/shared/services/localStorage.service';
import { ConfirmationPopupComponent } from '../../../shared/components/dialogs/confirmation-popup/confirmation-popup.component';
import {ToastMessageComponent} from '../toast-message/toast-message.component';
import { CpmService } from '@/shared/services/cpm.service';

@Component({
  selector: 'cpm-top-bar',
  templateUrl: './cpm-top-bar.component.html',
  styleUrls: ['./cpm-top-bar.component.scss'],
})
export class CPMTopBarComponent implements OnInit {
  buildings = [];
  organizationList = [];
  selectedOrg = { id: '', name: '' };
  localStoredOrg = { id: '', name: '' };
  selectedPlant: any = {};
  existingPlant;
  userSites: any[] = [];
  duplicateOrganizationList = [];
  subscriptions: Subscription;
  plantsList = [];
  templates = ['Template 1', 'Template 2', 'Template 3'];
  selectedBuildingIds = [];
  loadingVal = "Loading...";
  isEmpty = true;
  showHide = true;
  @Input() themeClass:boolean;
  @Input() disableMatTab:boolean = true;
  @Input() isBuilderDataChanged:boolean;
  @Output() isBuildingSelected = new EventEmitter();
  @Output() noPlantSelected = new EventEmitter<any>();
  @Output() plantBuilderView = new EventEmitter<string>();
  @Output() masterViewPreference = new EventEmitter<string>();
  @Output() masterViewThemePreference = new EventEmitter<any>();
  @Output() sharedPlantData = new EventEmitter();
  @Input() loginUserTypeData: any = {};
  plantView: string = "Plant Builder";
  showTabsButtons:boolean = false;
  showButtons:boolean = false;
  editing:boolean = false;
  isFac:boolean = false;
  isBuilderUser:boolean = false;
  plantCount = 0;
  @Input() selectedTabIndex: number = 0;
  image = 'assets/images/cpm-template.png';
  @ViewChild('plantSelector') matSelect: MatSelect;
  plantConfigList: any = [];
  filterPlant: any = [];
  pointStatusIcon = {
    'statusView': 'pointStatusViewIcon',
    'valueView': 'pointvalueIcon_active',
    'listView': 'pointlistIcon',
    'trendView': 'pointtrendIcon'
  }
  selectedPointIcon;
  @Input() isPublished:boolean;
  @Input() userPreferenceData:any;
  @ViewChild('plantSearchField') plantSearchFieldElement: ElementRef;
  buildingName:any = "";
  showPlantDropDown:boolean = false;
  searchText:any = '';
  plantListClone = [];
  lastViewedPlantId: any = '';
  @Input() toggleChecked:boolean;
  @Input() toggleTheme:string;
  isWarningCancelClicked:boolean = false;
  plantName:any;

  constructor(
    public cpmService: CpmService,
    private ref: ChangeDetectorRef,
    public _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public alertService: AlertService,
    public apiService: ApiService,
    public storageService: StorageService,
    public localService: LocalStorageService,
    private toastMessage: MatSnackBar
  ) {}

  ngOnInit(): void {
    if(this.loginUserTypeData?.isPublic){
      this.statusView('valueView', false);
      this.getSharedPlantData();
    }else{
      this.lastViewedPlantId = JSON.parse(localStorage.getItem("lastViewedPlantId"));
      if(JSON.parse(localStorage.getItem("portal")) == 'facilisight'){
        this.isFac = true;

        if(JSON.parse(localStorage.getItem("certificateLevel")) == 'L3'){
          this.isBuilderUser = true;
        }
        this.getOrganizationsForFacilisight()
      }else{
        if (JSON.parse(localStorage.getItem("lastViewedOrg"))) {
          this.initializeOrgData();
        }
        else {
          this.getOrganizations();
        }
      }
    }
  }

  async initializeOrgData() {
    this.localStoredOrg = JSON.parse(localStorage.getItem("lastViewedOrg"));
    await this.getOrganizations();
    const orgFound = this.checkIfOrgExists(this.localStoredOrg.name)
    if (orgFound) {
      this.selectedOrg = { ...this.localStoredOrg };
      this.orgSelectionChanged();
    } else {
      await this.getOrganizations(this.localStoredOrg.name, 'initialization');
      const orgFound = this.checkIfOrgExists(this.localStoredOrg.name)
      if (orgFound) {
        this.selectedOrg = { ...this.localStoredOrg };
        this.orgSelectionChanged();
      } else {
        this.getOrganizations();
      }
    }
  }

  checkIfOrgExists(orgName) {
    let foundOrg = this.organizationList.find(org => org.name == orgName);
    return foundOrg !== undefined;
  }

  getSharedPlantData(){
    this.apiService.getSharedPlantData(this.loginUserTypeData.linkShareUuid).subscribe(plantDataResp => {
          this.showTabsButtons = true;
          if (!plantDataResp.hasOwnProperty('plantJson')) {
            plantDataResp.plantJsonStr = {
              drawflow: {
                Home: {
                  data: {}
                }
              }
            };
          } else {
            plantDataResp.plantJsonStr = JSON.parse(plantDataResp.plantJson);
          }
          this.plantName = plantDataResp.chillerPlantName;
          this.sharedPlantData.emit(plantDataResp);
          // this.isBuildingSelected.emit({ buildingData: selectedBuildingData, plantData: plantDataResp, isNewPlant:isNewPlant});
          // const data = { dashboardId: this.selectedPlant.chillerPlantId };
          // window.parent.postMessage(JSON.stringify(data), '*');
          // localStorage.setItem("lastViewedPlantId",JSON.stringify(this.selectedPlant.chillerPlantId));
        },error => { }
    )
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  selectedBuildingChanged(event) {
    if (this.isPublished) {
      if (event && event.length) {
        this.storageService.selectedSites(event);
        this.isBuildingSelected.emit({ buildingData: event, plantData: this.selectedPlant });
        this.showTabsButtons = true;
      } else {
        this.showTabsButtons = false;
        this.isBuildingSelected.emit({ buildingData: [], plantData: {} });
        this.storageService.selectedSites([]);
      }
    }
  }

  onPlantChange(event, isNewPlant = false) {
    this.buildingName = "";
    this.selectedBuildingIds = [];
    this.showHide = false;
    setTimeout(() => {
      this.showHide = true;
    },100);
    if (this.plantsList.length == 0 || this.selectedPlant.chillerPlantId == '') {
      this.noPlantSelected.emit({event:true, name: this.selectedPlant});
    } else {
      this.noPlantSelected.emit({event:false, name: this.selectedPlant});
      let status = (!this.isFac || this.isFac  && this.isBuilderUser) && this.selectedTabIndex == 1 ? "DRAFT" : "PUBLISHED";
      this.apiService
        .getPlantData(event.chillerPlantId, status)
        .subscribe({
          next: (plantDataResp) => {
          let selectedBuildingData = this.buildings.filter(building => building.id == plantDataResp.siteId);
          this.buildingName = selectedBuildingData[0]?.dis;
          this.showTabsButtons = true;
          if (!plantDataResp.hasOwnProperty('plantJson')) {
            plantDataResp.plantJsonStr = {
              drawflow: {
                Home: {
                  data: {}
                }
              }
            };
          } else {
            plantDataResp.plantJsonStr = JSON.parse(plantDataResp.plantJson);
          }
          this.storageService.setSelectedPlantData(plantDataResp);
          this.isBuildingSelected.emit({ buildingData: selectedBuildingData, plantData: plantDataResp, isNewPlant:isNewPlant});
          const data = { dashboardId: this.selectedPlant.chillerPlantId };
          window.parent.postMessage(JSON.stringify(data), '*');
          localStorage.setItem("lastViewedPlantId",JSON.stringify(this.selectedPlant.chillerPlantId));
        }, error: () => { },
        complete: () => { 
        }
      });
    }
    this.matSelect?.close();
  }

  mapPlantToBuilding() {
    if (this.selectedPlant?.sites?.length > 0) {
      this.selectedBuildingIds = this.selectedPlant.sites.map((site) => site.id);
    
      let selectedBuildingData = this.buildings.filter((item) =>
        this.selectedBuildingIds.includes(item.id)
      );
      this.storageService.selectedSites(selectedBuildingData);
      this.storageService.setSelectedPlantData(this.selectedPlant);
      this.isBuildingSelected.emit({ buildingData: selectedBuildingData, plantData: this.selectedPlant});
      this.showTabsButtons = true;
    }else{
      this.selectedBuildingIds = [];
      this.isBuildingSelected.emit({ buildingData: [], plantData: {} });
      this.showTabsButtons = false;
    }
  }

  getBuildings() {
    this.buildings = [];
    this.selectedBuildingIds = [];
    this.subscriptions['getBuildings'] = this.cpmService
      .findByQuery(`site and organization==\\"${this.selectedOrg.name}\\"`)
      .pipe(
        map(this.cpmService.stripHaystackTypeMapping),
        map(this.sortResponse)
      )
      .subscribe(
        ({ rows }) => {
          if (rows) {
            this.buildings = rows;
          }
          this.fetchPlantList();
        },
        (err) => {
          this.fetchPlantList();
        }
      );
  }

  // Sort building response in ascending order based on display name
  sortResponse(res) {
    res.rows = res?.rows?.sort( (a, b) => {
      let textA = a.dis.toUpperCase();
      let textB = b.dis.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });;
    return res;
  }

  fetchPlantList(){
    let method = this.isFac ? this.isBuilderUser ? 'getPlantList' : 'getPublishedPlantList' : 'getPlantList';
    this.apiService[method](this.selectedOrg.name)
      .subscribe((_res) => {
        if(_res != null){
          if(this.isBuilderUser){
            let siteId = JSON.parse(localStorage.getItem("siteId"));
            _res = _res.filter(plant => plant.siteId == siteId);
          }
          this.plantsList = _res.sort((a, b) => a.chillerPlantName.localeCompare(b.chillerPlantName));
          this.plantListClone = this.plantsList
        }else{
          this.plantsList = [];
          this.plantListClone = this.plantsList
        }
        this.noPlantSelected.emit({event:true, name: this.selectedPlant});
        this.isBuildingSelected.emit("noCanvas")
        this.plantCount = this.plantsList.length;
        this.showPlantDropDown = true;
        if(this.plantListClone.length == 1){
          this.selectedPlant = this.plantListClone[0]
          this.onPlantChange(this.plantListClone[0])
        }
        if( this.lastViewedPlantId !== '' ){
          this.plantListClone.forEach(plant=>{
            if(plant.chillerPlantId == this.lastViewedPlantId){
              this.selectedPlant = plant;
              this.onPlantChange(plant)
            }
          })
        }
      }, (err) => {
        if (err.status == 404) {
          this.plantsList = [];
          this.plantListClone = this.plantsList
          this.showPlantDropDown = true;
        }
      });

  }

  getOrganizations(searchText?, from?) {
    const self = this;
    const params = {};
    if (searchText && searchText.length) {
      params['searchText'] = searchText;
    }
    params['limit'] = '20';
    return new Promise<void>((resolve, reject) => {
        this.subscriptions = this.apiService
            .getOrganizations(params)
            .subscribe((_res) => {
              if (from == 'initialization') {
                let newOrganizations = _res['organizations'].filter(org => org.name.trim().length > 0);
                self.organizationList.push(...newOrganizations);
              } else {
                self.organizationList = _res['organizations'].filter(org => org.name.trim().length > 0) || [];
              }
              self.loadingVal = 'No Results found';
              self.isEmpty = false;
              resolve();
            },(error) => {
                reject(error);
            });
    });
  }

  getOrganizationsForFacilisight() {
    this.subscriptions = this.apiService.getUserSitesOfFacilisight(this.localService.getUserId()).subscribe((res: any) => {
      let siteNameFromFacPortal = JSON.parse(localStorage.getItem("siteNameFromFacPortal"))
      let org: any= {}
      res.sites.forEach(site => {
        if(site.siteName == siteNameFromFacPortal ){
          org['orgId'] = site.orgId
          org['name'] = site.orgName
        }
        
      });
        this.organizationList = []
        this.organizationList.push(org);
        this.loadingVal = "No results Found";
        this.isEmpty = false;
        this.selectedOrg.name = org.name;
        this.selectedOrg.id = org.orgId;
        this.orgSelectionChanged()
    }, (err) => {
      this.alertService.error('Something went wrong, please try again');
    });
  }

  orgSelectionChanged() {
      this.lastViewedPlantId = JSON.parse(localStorage.getItem("lastViewedPlantId"));
      let org = this.organizationList.find(org=>org.orgId == this.selectedOrg.id);
      this.selectedOrg.name = org ? org.name :'';
      if(!this.selectedOrg.id) return;
      if (this.selectedOrg.name && this.localStoredOrg?.id != this.selectedOrg.id) {
        localStorage.setItem("lastViewedOrg",JSON.stringify(this.selectedOrg));
      }
      this.noPlantSelected.emit({event:true, name: this.selectedPlant});
      this.buildingName = "";
      this.showPlantDropDown = false;
      this.showTabsButtons = false;
      this.getBuildings();
  }

  filterOrg($event) {
    if(this.searchText != $event.searchText){
      this.searchText = $event.searchText;
      if(!this.isFac){
        this.getOrganizations(this.searchText);
      }else{
        this.getOrganizationsForFacilisight()
      }
    }
  }

  editDashboard(event) {
    event.stopPropagation();
    this.editing = true;
    this.ref.detectChanges();
    this.existingPlant = this.selectedPlant;
  }

  discardNameEdit(event) {
    this.editing = false;
    this.ref.detectChanges();
  }

  saveNameEdit(enteredName) {
    if (enteredName && enteredName.length > 0) {
      if (this.checkPlantName(enteredName)) {
        this.openSnackBar('Plant with the same name already exists', '');
        return;
      } else {
        this.editing = false;
        this.plantsList = this.plantsList.map((plant) => {
          if (plant.chillerPlantName == this.selectedPlant.chillerPlantName) {
            plant['name'] = enteredName;
          }
          return plant;
        });
        this.plantListClone = this.plantsList.sort((a, b) => a.chillerPlantName.localeCompare(b.chillerPlantName));
        this.selectedPlant.chillerPlantName = enteredName;
      }

      this.ref.detectChanges();
    } else {
      return;
    }
  }

  checkPlantName(chillerPlantName) {
    if (this.plantsList.some((plant) => plant.chillerPlantName === chillerPlantName)) {
      return true;
    }
    return false;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, '', {
      duration: 2400,
    });
  }

  deletePlant() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '450px',
      panelClass: 'confirmation-model-container',
      data: {
        title: 'Delete Plant',
        content: `<p>Are you sure you want to Delete Plant <span class="plantName">${this.selectedPlant.chillerPlantName}</span> ? </p>`,
        buttonText: 'DELETE Plant',
        buttonType: 'cancel'
      },
    });
    dialogRef.componentInstance.isCancel =  true;
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        document.getElementById("loader").style.display = "";
        this.deletePlantAPICall();
      }
    });

  }

  deletePlantAPICall(){
    this.apiService.deletePlant(this.selectedPlant.chillerPlantId).subscribe({
      next: (resp)=>{
        this.showToast('success','Plant deleted successfully');
      this.selectedBuildingIds= [];
      this.selectedBuildingChanged([]);
      this.plantsList = this.plantsList.filter((item) => item.chillerPlantId != this.selectedPlant.chillerPlantId);
      this.plantListClone = this.plantsList.sort((a, b) => a.chillerPlantName.localeCompare(b.chillerPlantName));
      if (this.plantsList.length == 0) {
        this.plantCount = this.plantCount = 0;
      }
      this.selectedPlant = "";
      this.noPlantSelected.emit({event:true, name: this.selectedPlant});
      this.isBuildingSelected.emit("noCanvas");
      this.buildingName = "";
      this.showTabsButtons = false;
      document.getElementById("loader").style.display = "none";
    }, 
    error: (err) =>{
      document.getElementById("loader").style.display = "none";
      this.showToast('error',`${{message : err.error.error}}`)
    }
  })
  }

  // Toast Message 
  showToast(type: string, message: string) {
    this.toastMessage.openFromComponent(ToastMessageComponent, {
      duration: 5000,
      data: {
        type: type,
        message: message
      },
    });
  }

  getUserDeletionConfirmMsg(platName) {
    return `<div>Are you sure you want to remove <div class="color-primary">${platName}.</div> </div>`;
  }

  clearPlantSearchField(){
    this.plantSearchFieldElement.nativeElement.value = '';
    this.plantSearchFieldElement.nativeElement.focus();
    this.plantListClone = this.plantsList.sort((a, b) => a.chillerPlantName.localeCompare(b.chillerPlantName));
  }

  editAddPlant(type) {
    let buildings = JSON.parse(JSON.stringify(this.buildings));

    //For Facilisight L3 user builidng filter only For open building 
    if (this.isFac && this.isBuilderUser) {
      let siteId = JSON.parse(localStorage.getItem("siteId"));
      buildings = buildings.filter(buildingObj => buildingObj.id == siteId);
    }

    let data: any = {
      title: type == 'add' ? 'Add Plant' : 'Edit Plant',
      buttonText: type == 'add' ? 'ADD PLANT' : 'EDIT PLANT',
      building: buildings,
      seletedOrg: {
        orgId: this.selectedOrg.id,
        orgName: this.selectedOrg.name
      },
      plantName: "",
      type: type
    }

    if (type == 'edit') {
      data.plantData = this.selectedPlant;
    }

    const dialogRef = this.dialog.open(EditPlantModalComponent, {
      width: '350px',
      height: 'auto',
      panelClass: 'addPlant-model-container',
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.chillerPlantId) {
        if (type == 'add') {
          this.plantsList.push(result);
          this.plantListClone = this.plantsList.sort((a, b) => a.chillerPlantName.localeCompare(b.chillerPlantName));
          this.selectedPlant = result;
          this.onPlantChange(this.selectedPlant, true);
        } else {
          let index = this.plantsList.findIndex((plantObj => plantObj.chillerPlantId == result.chillerPlantId));
          this.plantsList[index] = result;
          this.selectedPlant = result;
          this.onPlantChange(this.selectedPlant);
        }
      }
    });
  }

  viewChanged(changedView) {
    if (changedView.index == 0 && this.isBuilderDataChanged) {
      this.unsavedWarningPopup(changedView);
    } else {
      this.plantView = changedView.tab.textLabel;
      this.showButtons = !this.showButtons;
      if (!this.isWarningCancelClicked) {
        this.isWarningCancelClicked = false;
        this.plantBuilderView.emit(this.plantView);
      }

    }
  }

  unsavedWarningPopup(changedView) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '450px',
      panelClass: 'confirmation-model-container',
      data: {
        title: 'Unsaved changes',
        content: `<p>Do you want to leave this page? your changes will be lost when you leave this page</p>`,
        buttonText: 'DISCARD & LEAVE',
        buttonType: 'unsaved'
      },
    });

    dialogRef.componentInstance.isCancel =  false;

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        this.isWarningCancelClicked = false;
        this.plantView = changedView.tab.textLabel;
        this.showButtons = !this.showButtons;
        this.plantBuilderView.emit(this.plantView);
      } else {
        this.isWarningCancelClicked = true;
        this.selectedTabIndex = 1;
      }
    });
  }

  getToolTipDEata(data) {
    if (data && data.length) {
      return data;
    }
  }

  statusView(selectedView, update) {
    if(update){
      this.masterViewPreference.emit(selectedView);
    }
    const classList = ['.equipview-container', '.equipview-pointName', '.trendGraphContainer', '.openCollapseIcon', '.openCollapseIconLeft', '.equipNameCollapsedBar', '.equipNameCollapsedBarLeft', '.equipNameContainerStatusView'];
    this.selectedPointIcon = selectedView;
    this.pointStatusIcon = {
      'statusView': 'pointStatusViewIcon',
      'valueView': 'pointvalueIcon',
      'listView': 'pointlistIcon',
      'trendView': 'pointtrendIcon'
    }
    this.pointStatusIcon[selectedView] = this.pointStatusIcon[selectedView] + '_active';
    classList.forEach((_container, index) => {
      let equipContainer: any = document.querySelectorAll(_container);
      // Updating the value on click and showing active tab as selected.
      // When user click son value view from my plant.
      if (selectedView == 'valueView') {
        // Value view Code start here.
        if (equipContainer.length) {
          this.generateValueViewContainer(equipContainer, index);
        }
      }
      
      //List view Code starts here.
      if (selectedView == 'listView') {
        if (equipContainer.length) {
          this.generateListViewContainer(_container, equipContainer, index);
        }
      }

      // Trend view Code starts here.
      if (selectedView == 'trendView') {
        this.generateTrendViewContainers(equipContainer, index, _container);
      }

      if (selectedView == 'statusView') {
        this.generateStatusViewContainers(_container, equipContainer);
      }
    });
  }

  generateValueViewContainer(equipContainer, index) {
    if (equipContainer.length) {
      if (index == 0) {
        // Width will be auto. In this view will shown only value.
        for (let i = 0; i < equipContainer.length; i++) {
          equipContainer[i].style.width = 'auto';
          equipContainer[i].style.display = 'block';
        }
      } else {
        // Name and graph containers div's will be hidden.
        for (let i = 0; i < equipContainer.length; i++) {
          equipContainer[i].style.display = 'none';
        }
      }
    }
  }

  generateListViewContainer(_container, equipContainer, index) {
    if (index == 0) {
      for (let i = 0; i < equipContainer.length; i++) {
        // For list view the container width will be setting as 500px.
        equipContainer[i].style.width = '500px';
        equipContainer[i].style.display = 'block';
      }
    } else {
      // For list view the trend view container will be hidden.
      if (_container == '.trendGraphContainer' || _container == '.equipNameCollapsedBar' || _container == '.equipNameCollapsedBarLeft' || _container == '.equipNameContainerStatusView') {
        for (let i = 0; i < equipContainer.length; i++) {
          equipContainer[i].style.display = 'none';
        }
      } else {
        // Showing point name and value only
        for (let i = 0; i < equipContainer.length; i++) {
          equipContainer[i].style.display = 'block';
        }
      }
    }
  }

  generateTrendViewContainers(equipContainer, index, _container) {
    if (equipContainer.length) {
      if (index == 0) {
        // For list view the container width will be setting as 630px.
        for (let i = 0; i < equipContainer.length; i++) {
          equipContainer[i].style.width = '630px';
          equipContainer[i].style.display = 'block';
        }
      } else {
        // For list view the trend view container will be hidden.
        if (_container == '.equipNameCollapsedBar' || _container == '.equipNameCollapsedBarLeft' || _container == '.equipNameContainerStatusView') {
          for (let i = 0; i < equipContainer.length; i++) {
            equipContainer[i].style.display = 'none';
          }
        } else {
          // Showing point name, trend graph and value.
          for (let i = 0; i < equipContainer.length; i++) {
            equipContainer[i].style.display = 'block';
          }
        }
      }
    }
  }

  generateStatusViewContainers(_container, equipContainer) {
    if (equipContainer.length) {
      // Hiding the status view container.
      if (_container == '.equipNameContainerStatusView') {
        equipContainer.forEach(_element => {
          _element.style.display = 'block';
        });
      } else {
        equipContainer.forEach(_element => {
          _element.style.display = 'none';
        });
      }
    }
  }


  onKey(event, value) {
    event.stopPropagation();
    this.plantListClone = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.plantsList.filter(option =>
      option.chillerPlantName.toLowerCase().startsWith(filter)
    );
  }

  changeTheme() {
    if (this.toggleTheme == 'light') {
      this.toggleTheme = 'dark';
      this.toggleChecked = true;
    } else {
      this.toggleTheme = 'light';
      this.toggleChecked = false;
    }
    this.masterViewThemePreference.emit([this.toggleTheme,this.toggleChecked]);
  }
} 
