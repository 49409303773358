declare const d3: any;
import * as dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
let buildingSchData = [];
let chillerStatusData = [];
let alertStatusData = [];
let chillerEfficencyData: any = [];
let chillerLoadData = [];
let unit;

export function emptyChartData() {
    buildingSchData = [];
    chillerStatusData = [];
    alertStatusData = [];
    chillerEfficencyData = [];
    chillerLoadData = [];
}

export function generateArcChart(chartStyleData, id, tooltip, data, equipName, days) {
    unit = data.unit != undefined ? data.unit : '';
    const svg = d3.select(chartStyleData.id).append("svg")
    .attr("id", id)
    .attr("width", chartStyleData.width).attr("height", chartStyleData.height);
    data = formatChartData(data, days);
    data = setUpData(data);
    if (id == 'buildingSchChart') {
        buildingSchData = data;
    } else {
        chillerStatusData = data;
    }
    svg.selectAll("path")
        .data(data).enter()
        .append("path")
        .attr('value', function (d) {return `${d?.d_d}-${d.time}`})
        .attr("transform", "translate(" + `${chartStyleData.svgWidth}` + "," + `${chartStyleData.svgHeight}` + ")")
        .attr("d", d3.arc()
            .innerRadius(function (d) {
                return chartStyleData.innerRadius;
            })
            .outerRadius(function (d) {
                return chartStyleData.outerRadius;
            })
            .startAngle(function (d) {
                return d.startAngle;
            })
            .endAngle(function (d) {
                return d.endAngle;
            })
        )
        .attr('stroke', function(d) {return d?.d_d != null ? (Number(d?.d_d >= 1) ? chartStyleData.color: '#BABABA') :'none'}) 
        .attr('fill', function(d) {return d?.d_d != null ? (Number(d?.d_d) >= 1 ? chartStyleData.color: '#BABABA') :'#333333'});

    const mouseover = function (d) {
        tooltip.classList.add('show-tooltip');
    }

    const mousemove = function (event) {
        tooltip.innerHTML = '';
        tooltip.appendChild(generateTooltip(event.srcElement.__data__, equipName));
        document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('left');
        document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('right');
        if (event.srcElement.__data__.startAngle > 0) {
            tooltip.style.left = d3.pointer(event)[0] + 400 + "px";
            tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
        } else {
            tooltip.style.left = d3.pointer(event)[0]+ 80 + "px";
            tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
        }
    }

    const mouseleave = function (d) {
        tooltip.classList.remove('show-tooltip');
    }
    svg.on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)
    return svg;
}

export function generateAlertsArcChart(chartStyleData, tooltip, equipName, pointData, days) {
    const svg = d3.select(chartStyleData.id).append("svg").attr("id",'alertsChart').attr("width", chartStyleData.width).attr("height", chartStyleData.height)
    let data = formatChartData(pointData.data, days);
    alertStatusData = data;
    data = setUpData(data);
    svg.selectAll("path")
        .data(data).enter()
        .append("path")
        .attr("transform", "translate(" + `${chartStyleData.svgWidth}` + "," + `${chartStyleData.svgHeight}` + ")")
        .attr("d", d3.arc()
            .innerRadius(function (d) {
                return chartStyleData.innerRadius;
            })
            .outerRadius(function (d) {
                return chartStyleData.outerRadius;
            })
            .startAngle(function (d) {
                return d.startAngle;
            })
            .endAngle(function (d) {
                return d.endAngle;
            })
        )
        .attr('stroke', function(d) {
            let color = 'none';
            if (d?.d_d >= 0 && d?.d_d != null) {
                color = '#FF5147';
            }
            return color;
        })
        .attr('fill', function(d) {
            let color = '#333333';
            if (d?.d_d >= 0 && d?.d_d != null) {
                color = '#FF5147';
            }
            return color;
        });

    const mouseover = function (d) {
        tooltip.classList.add('show-tooltip');
    }
    
    const mousemove = function (event) {
        tooltip.innerHTML = '';
        tooltip.appendChild(generateTooltip(event.srcElement.__data__, equipName));
        document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('left');
        document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('right');
        if (event.srcElement.__data__.startAngle > 0) {
            tooltip.style.left = d3.pointer(event)[0] + 400 + "px";
            tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
        } else {
            tooltip.style.left = d3.pointer(event)[0]+ 80 + "px";
            tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
        }
    }
    
    const mouseleave = function (d) {
        tooltip.classList.remove('show-tooltip');
    }
    svg.on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)
    return svg;
}

export function generateChillerLoadChart(chartStyleData, tooltip, equipName, point, days) {
    unit =  point.unit != undefined ? point.unit : '';
    const svg = d3.select(chartStyleData.id).append("svg").attr("id", 'chillerLoadChart').attr("width", chartStyleData.width).attr("height", chartStyleData.height)
    let data = formatChartData(point.data, days);
    data = setUpData(data);
    chillerLoadData = data;
    let maxVal = Math.max(...chillerLoadData.map(a => {
        if (a.d_d != null) {
            return Number(a.d_d);
        } else {
            return 0;
        }
    }));
    let dividend = calculateDividendForChillerLoad(maxVal);
    svg.selectAll("path")
        .data(data).enter()
        .append("path")
        .attr("transform", "translate(" + `${chartStyleData.svgWidth}` + "," + `${chartStyleData.svgHeight}` + ")")
        .attr("d", d3.arc()
            .innerRadius(function (d) {
                if (parseInt(d?.d_d)) {
                    return (chartStyleData.innerRadius - (((d.d_d / dividend) * 2) + 2));    //Dividing with the dividend value dynamically and scalling it by 2.
                } else {
                    return (chartStyleData.innerRadius);
                }
            })
            .outerRadius(function (d) {
                return chartStyleData.outerRadius;
            })
            .startAngle(function (d) {
                return d.startAngle;
            })
            .endAngle(function (d) {
                return d.endAngle;
            })
        )
        .attr('stroke',function(d) {return d?.d_d ? "#4C69E9": 'none'})
        .attr('fill', function(d) {return d?.d_d ? "#4C69E9": '#333333'});
        const mouseover = function (d) {
            tooltip.classList.add('show-tooltip');
        }
        
        const mousemove = function (event) {
            tooltip.innerHTML = '';
            tooltip.appendChild(generateTooltip(event.srcElement.__data__,equipName));
            document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('left');
            document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('right');
            if (event.srcElement.__data__.startAngle > 0) {
                tooltip.style.left = d3.pointer(event)[0] + 400 + "px";
                tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
            } else {
                tooltip.style.left = d3.pointer(event)[0]+ 80 + "px";
                tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
            }
        }
         
        const mouseleave = function (d) {
            tooltip.classList.remove('show-tooltip');
        }
        svg.on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave);
    return svg;
}

function calculateDividendForChillerLoad(maxVal) {
    if (maxVal <= 100) {
        return 20;
    } else if (maxVal <= 1000) {
        return 100;
    } else if (maxVal < 10000) {
        return 1000;
    } else if (maxVal >= 10000 && maxVal <= 100000) {
        return 10000;
    } else {
        return 100000;
    }
}

export function formatChartData(data, days) {
    let start = days.start;
    let end = days.end;
    let arr = [];
    let stepVal;
    if (days.days == 0 && dayjs(days.today).isSame(dayjs(end))) {
        stepVal = 1;
    }

    end = dayjs(end.add(23, "hours")).add(59, "minutes");
    let endCount = dayjs(end).diff(dayjs(start), "minutes") / stepVal;
    for (let i = 0; i <= endCount; i++) {
        var obj = {};
        start = start.add(stepVal, "minutes")
        obj["time"] = start.format('YYYY-MM-DDTHH:mm:ss');
        arr.push(obj);
    }
    if (arr && arr.length) {
        return arr.map((_res, i) => {
            const time = _res.time;
            let value = null;
            if (data[i] && data[i].val) {
                value = data[i].val.toString().split(' ')[0];
            }
            return {time, d_d: value};
        });
    }
    return data.map(_res => {
        const time = _res.ts.substring(0, 19);
        const value = _res.val ? _res.val.toString().split(' ')[0]: null;
        return {time, d_d: value};
    });
}

export function generateCircularBarPlotChart(tooltip, equipName, point, days, width, height, svgwidth, svgHeight, innerRadius = 180) {
    const
    outerRadius = Math.min(width, height) / 2;
    const data = formatChartData(point.data, days);
    let maxVal = Math.max(...point.data.map(_a => {
        if (_a.val != null) {
            if (_a.val.split(' ')[0] != 'null') {
                return Number(_a.val.split(' ')[0]);
            } else return 0;
        } else return 0;
    }));
    if (!maxVal) {
        maxVal = 100;
    }
    maxVal = maxVal + (maxVal/2);
    chillerEfficencyData = data;
    const svg = d3.select("#graphId-chart")
      .append("svg")
      .attr('id', 'circularBarPlotChart')
      .attr("width", width)
      .attr("height", height);

    const scaleX = d3.scaleBand()
        .range([-3.14, 1 * Math.PI])
        .domain(data.map(function (d) {
          const val = d.time;
          return val;
        })
        ).paddingInner(0.1)
        .paddingOuter(0.2);  // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
    
    const scaleY = d3.scaleLinear()
        .rangeRound([innerRadius, outerRadius]).nice()   // Domain will be define later.
        .domain([0, maxVal]).nice(); // Domain of Y is from 0 to the max seen in the datathis.setUpYscale();
    
    // chart creation starts...
    svg.append("g")
      .attr("transform", "translate(" + svgwidth + "," + svgHeight + ")")
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .attr("d", d3.arc()     // imagine your doing a part of a donut plot
        .innerRadius(innerRadius)
        .startAngle((d: any) => {
          return scaleX(d.time);
        }).outerRadius((d) => {
          return scaleY(d['d_d']);
        }).endAngle((d: any) => {
          d['endAngle'] = 1 * (Math.PI / 140)
          return scaleX(d.time) + scaleX.bandwidth();
        })
      ).style("fill", "#FF5147");
      const mouseover = function (d) {
        tooltip.classList.add('show-tooltip');
    }
    
    const mousemove = function (event) {
        tooltip.innerHTML = '';
        tooltip.appendChild(generateTooltip(event.srcElement.__data__,equipName));
        if (document.getElementsByClassName('tooltip-circular-widget').length) {
            document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('left');
            document.getElementsByClassName('tooltip-circular-widget')[0]['style'].removeProperty('right');
        }
        if (d3.pointer(event)[0] > 223) {
            tooltip.style.left = d3.pointer(event)[0] + 400 + "px";
            tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
        } else {
            tooltip.style.left = d3.pointer(event)[0]+ 80 + "px";
            tooltip.style.top = d3.pointer(event)[1] + 20 + "px";
        }
    }
     
    const mouseleave = function (d) {
        tooltip.classList.remove('show-tooltip');
    }
    svg.on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
}

function generateTooltip(data,equipName) {
    let eneryConsumptionLabel, equipStatusLabel, equipAlertLabel;

    if(equipName == "waterCooledChiller" || equipName == "airCooledChiller"){
        eneryConsumptionLabel = "CHILLER ENERGY CONSUMPTION";
        equipStatusLabel = "CHILLER STATUS";
        equipAlertLabel = "CHILLER ALERT";
    }else if(equipName.includes('pump1Vertical_forward') || equipName.includes('pump1Vertical_backward')){
        eneryConsumptionLabel = "VFD ENERGY CONSUMPTION";
        equipStatusLabel = "PUMP STATUS";
        equipAlertLabel = "PUMP ALERT";
    }else if(equipName == "coolingTowerSingleFan" || equipName == "coolingTowerTwoFan" || equipName == "coolingTowerFourFan"){
        eneryConsumptionLabel = "COOLING TOWER ENERGY CONSUMPTION";
        equipStatusLabel = "FAN STATUS";
        equipAlertLabel = "FAN ALERT";
    }else if(equipName == "steamBoiler" || equipName == "condenserBoiler"){
        eneryConsumptionLabel = "GAS ENERGY (ACCUMULATED)";
        equipStatusLabel = "BOILER STATUS";
        equipAlertLabel = "BOILER ALERT";
    }else{
        eneryConsumptionLabel = "ENERGY CONSUMPTION";
        equipStatusLabel = "STATUS";
    }

    const tooltip = document.createElement('div');

    // DISPLAY TIME TOOLTIP TEXT.
    const timeDiv = document.createElement('div');
    timeDiv.innerHTML = `@${dayjs(data.time).format('ddd MMM DD, YYYY | hh:mm a')}`;
    timeDiv.classList.add('timeDiv');
    tooltip.appendChild(timeDiv);

    // CHILLER ENERGY CONSUMPTION TOOLTIP TEXT.
    if (chillerEfficencyData?.length) {
        const chillerEnergyConsumptionData = chillerEfficencyData.find(_r => _r.time == data.time);
        const chillerEnergyConsumption = document.createElement('div');
        const val = chillerEnergyConsumptionData?.d_d ? Number(chillerEnergyConsumptionData?.d_d).toFixed(2) : 'N/A';
        if (val == 'N/A') {
            chillerEnergyConsumption.innerHTML = eneryConsumptionLabel + `: ${val}`;
            chillerEnergyConsumption.classList.add('chiller_status_off');
        } else {
            chillerEnergyConsumption.innerHTML = eneryConsumptionLabel + `: ${val} ${unit}`;
            chillerEnergyConsumption.classList.add('chiller_energy_consumption');
        }
        tooltip.appendChild( chillerEnergyConsumption);
    }

    // BUILDING SCHEDULE TOOLTIP TEXT.
    if (buildingSchData?.length) {
        const buildingScheduleData = buildingSchData.find(_r => _r.time == data.time);
        const buildingSchedule = document.createElement('div');
        buildingSchedule.innerHTML = `BUILDING SCHEDULE: ${buildingScheduleData?.d_d ? 'OCCUPIED': 'UNOCCUPIED'}`;
        buildingSchedule.classList.add('building_sch');
        tooltip.appendChild(buildingSchedule);
    }

    // CHILLER STATUS TOOLTIP TEXT.
    if (chillerStatusData?.length) {
        const chillerStatusObj = chillerStatusData.find(_r => _r.time == data.time); 
        const chillerStatus = document.createElement('div');
        let className = 'chiller_status_off';
        if (chillerStatusObj?.d_d == null) {
            chillerStatus.innerHTML = equipStatusLabel + ' N/A';
        } else {
            chillerStatus.innerHTML = equipStatusLabel +`: ${Number(chillerStatusObj?.d_d) > 0 ? 'ON': 'OFF'}`;
            if (Number(chillerStatusObj?.d_d) > 0) {
                className = 'chiller_status';
            }
        }
        chillerStatus.classList.add(className);
        tooltip.appendChild(chillerStatus);
    }
    
    // ALERT TOOLTIP TEXT.
    if (alertStatusData?.length) {
        const alertData = alertStatusData.find(_r => _r.time == data.time);
        const alert = document.createElement('div');
        alert.innerHTML = `${equipAlertLabel}: N/A`;
        let alertClass = 'alert';
        if (alertData?.d_d >= 0 && alertData?.d_d != null) {
            alertClass = 'severe_alert';
            alert.innerHTML = `${equipAlertLabel}: ${alertData?.d_d}`;
        }
        alert.classList.add(alertClass);
        tooltip.appendChild(alert);
    }

    // CHILLER LOAD TOOLTIP TEXT.
    if (chillerLoadData?.length) {
        const chillerLoadObj = chillerLoadData.find(_r => _r.time == data.time);
        const chillerLoad = document.createElement('div');
        const val = chillerLoadObj?.d_d ? Number(chillerLoadObj?.d_d).toFixed(2): 'N/A';
        if (val == 'N/A') {
            chillerLoad.innerHTML = `CHILLER LOAD: ${val}`;
            chillerLoad.classList.add('chiller_status_off');
        } else {
            chillerLoad.innerHTML = `CHILLER LOAD: ${val} ${unit}`;
            chillerLoad.classList.add('chiller_load');
        }
        tooltip.appendChild(chillerLoad);
    } 
    return tooltip;
}

function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function setUpData(data) {
    let segmentValueSum = 0;
    let oldEndAngle = -3.14; // It's in radian, so Pi = 3.14 = bottom.
    data.forEach((val) => {
        const date = new Date(val.time);
        if (date && date.getTime()) {
            segmentValueSum += date.getTime();
        }
    });

    return data.map((value) => {
        const date = new Date(value.time);
        const segmentVal = date.getTime();
        const startAngle = oldEndAngle;
        const endAngle = startAngle + (segmentVal / segmentValueSum) * (2) * Math.PI;
        value["startAngle"] = startAngle;
        value["endAngle"] = endAngle;
        oldEndAngle = endAngle;
        return value;
    });
}