import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
    name: 'filter',
    pure: false
})
@Injectable()
export class FilterPipe implements PipeTransform {

    /**
     * @param items object from array
     * @param term term's search
     */
    transform(items: any, term: string, key?: string): any {
        if (!term || !items) {
            return items;
        }

        return this.filter(items, term, key);
    }

    filter(items: Array<{ [key: string]: any }>, term: string, key?: string): Array<{ [key: string]: any }> {
        const toCompare = term.toLowerCase();
        return items.filter(function (item: any) {
            if (key) {
                if (item[key].toString().toLowerCase().includes(toCompare)) {
                    return true;
                }
            } else {
                for (const property in item) {
                    if (item[property] === null || item[property] == undefined) {
                        continue;
                    }
                    if (item[property].toString().toLowerCase().includes(toCompare)) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

}
