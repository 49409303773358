import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/shared/services/toast.service';
import { StorageService } from './storage.service';
import { StorageKeys } from '../enums/StorageKeys.enum';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {ToastMessageComponent} from './../../cpm/components/toast-message/toast-message.component';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private errorSource: Subject<any> = new Subject<any>();
  error$ = this.errorSource.asObservable();

  constructor(public http: HttpClient,
              private toast: ToastService,
              private storage: StorageService,
              private toastMessage: MatSnackBar) { }

  get baseUrl() {
    return this.getConfig('domainModelerServiceUrl');
  }

  get authOnlyHeaders() {
    return new HttpHeaders({
      Authorization: `Bearer ${this.storage.bearerToken}`
    });
  }

  get jsonTypeHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.storage.bearerToken}`
    });
  }

  setHttpParams(params) {
    let httpParams = new HttpParams();
    if (params) {

      Object.keys(params).forEach((key, index) => {
        if (params[key] instanceof Object) {
          httpParams = httpParams.append(key, JSON.stringify(params[key]));
        } else {
          httpParams = httpParams.append(key, params[key]);
        }

      });
    }
    return httpParams;
  }

  getConfig(key: string) {
    return environment.config[key];
  }

  get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  post(url: string, payload: any, options?: any): Observable<any> {
    return this.http.post(url, payload, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  put(url: string, payload: any, options?: any): Observable<any> {
    return this.http.put(url, payload, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(url, options).pipe(
      catchError((err) => this.handleHttpError(err))
    );
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {

    if (err.status === 401 || err.status === 0) {
      this.redirectToLogin();
    } else if (err.status === 500) {
      this.showToast('error',`There was an error processing your request. Please try again. (error code: ${err.status})`)
    }
    return throwError(err);
  }

    // Toast Message 
    showToast(type: string, message: string) {
      this.toastMessage.openFromComponent(ToastMessageComponent, {
        duration: 5000,
        data: {
          type: type,
          message: message
        },
      });
    }

  redirectToLogin() {
    const url = window.location.href.split('?')[0]; // Get rid of any query params in the redirect url
    this.storage.clearItem(StorageKeys.BEARER_TOKEN);
    this.storage.clearItem(StorageKeys.USER);
    this.storage.clearItem(StorageKeys.USER_DETAILS);
    const newUrl = `${this.getConfig('gatekeeperUrl')}?serviceURL=${url}`;
    window.location.href = newUrl;
  }

  getOrganizations(params?): Observable<any> {
  // params = {searchText: 'cpm90', limit: '20'};
    params = params ? this.setHttpParams(params) : {};
    return this.http.get(`${this.getConfig('auth').caretakerUrl}` + '/org/search', { params }).pipe(catchError(this.handleHttpError));
  }

  getUserSitesOfFacilisight(userid) {
    return this.http.get(`${this.getConfig('auth').caretakerUrl}` + '/user/' + userid + '/sites?portal=facilisight');
  }

  getPlantList(orgName): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/list/${orgName}`).pipe(catchError(this.handleHttpError));
  }
  getPublishedPlantList(orgName): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/published/list/${orgName}`).pipe(catchError(this.handleHttpError));
  }

  addPlant(plantData): Observable<any> {
    return this.http.post(`${this.getConfig('cpmUrl')}chillerplant/createPlant`, plantData).pipe(catchError(this.handleHttpError));
  }

  getPlantData(plantId, status): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/${plantId}?status=${status}`).pipe(catchError(this.handleHttpError));
  }

  savePlantDraft(plantId, plantJson): Observable<any> {
    return this.http.put(`${this.getConfig('cpmUrl')}chillerplant/saveDraft/${plantId}/`, plantJson).pipe(catchError(this.handleHttpError));
  }
  publishPlant(plantId, plantJson): Observable<any> {
    return this.http.post(`${this.getConfig('cpmUrl')}chillerplant/publishPlant/${plantId}`, {}).pipe(catchError(this.handleHttpError));
  }

  discardDraft(plantId, plantJson): Observable<any> {
    return this.http.put(`${this.getConfig('cpmUrl')}chillerplant/discardDraft/${plantId}/`, plantJson).pipe(catchError(this.handleHttpError));
  }

  editPlant(plantId, orgName, plantData): Observable<any> {
    return this.http.put(`${this.getConfig('cpmUrl')}chillerplant/editPlant/${plantId}/${orgName}?plantName=${plantData.chillerPlantName}`, {}).pipe(catchError(this.handleHttpError));
  }

  getSites(): Observable<any> {
    const data = `ver:"2.0"\nfilter\n"site"`;
    return this.http.post(`${this.getConfig('haystackUrl')}v1/read/`, data).pipe(catchError(this.handleHttpError));
  }

  getEquipConfigPoint(): Observable<any> {
    return this.http.get('assets/mockData/equipPointData.json').pipe(catchError(this.handleHttpError));
  }

  getAlertList(): Observable<any> {
    return this.http.get('assets/mockData/equipAlertData.json').pipe(catchError(this.handleHttpError));
  }

  getAllEquips(siteId, plantId): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/equip/${siteId}?plantId=${plantId}`).pipe(catchError(this.handleHttpError));
  }

  getAllSharedEquips(linkShareUuid, plantId, siteId): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}shared/equips/${linkShareUuid}/${siteId}?plantId=${plantId}`).pipe(catchError(this.handleHttpError));
  }

  getSharedPointForSubEquips(linkShareId, equipIds) {
    return this.http.post(`${this.getConfig('cpmUrl')}shared/subEquip/points/${linkShareId}`, equipIds).pipe(catchError(this.handleHttpError));
  }

  getPointsForEquipsSubequips(equipIds): Observable<any> {
    return this.http.post(`${this.getConfig('cpmUrl')}chillerplant/equip/subEquip/points`, equipIds).pipe(catchError(this.handleHttpError));
  }

  getEquipConfigs(data): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/equip/${data.plantId}/${data.equipId}?status=${data.status}`).pipe(catchError(this.handleHttpError));
  }

  saveEquipConfigs(data): Observable<any> {
    return this.http.post(`${this.getConfig('cpmUrl')}chillerplant/equip/saveConfig/`+ data.plantId +`/`+ data.equipId, data ).pipe(catchError(this.handleHttpError));
  }

  getAlerts(siteId, params?) {
    params = {"size":500, custom:true}; // setting default number of alerts.
    params = params ? this.setHttpParams(params) : {};
    return this.http.get(`${this.getConfig('alertsUrl')}definitions/sites/${siteId}`,{params}).pipe(catchError(this.handleHttpError));
  }

  getAllAlerts(plantId, equipId, body?) {
    return this.http.post(`${this.getConfig('cpmUrl')}chillerplant/alerts/${plantId}/${equipId}`,body).pipe(catchError(this.handleHttpError));
  }

  // get complete data for an equip.
  getReadByIdMany(pointIds) {
    let data = `ver:"2.0"\nid`;
    for (const point of pointIds) {
      data += '\n@' + point;
    }
    return this.http.post(`${this.getConfig('haystackUrl')}v1/read/`, data).pipe(catchError(this.handleHttpError));
  }

  deleteEquipAndConfig(plantId, equipId): Observable<any> {
    return this.http.delete(`${this.getConfig('cpmUrl')}chillerplant/equip/deleteConfig/${plantId}/${equipId}`).pipe(catchError(this.handleHttpError));
  }

  deletePlant(plantId): Observable<any> {
    return this.http.delete(`${this.getConfig('cpmUrl')}chillerplant/deletePlant/${plantId}`).pipe(catchError(this.handleHttpError));
  }

  getMasterPoint(plantId, equipId, body): Observable<any> {
    return this.http.post(`${this.getConfig('cpmUrl')}chillerplant/view/data/${plantId}/${equipId}`, body).pipe(catchError(this.handleHttpError));
  }

  getEquipAlertStatus(plantId, equipId, status): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/alerts/count/${plantId}/${equipId}/${status}`).pipe(catchError(this.handleHttpError));
  }

  getWeatherAndTimeZone(plantId): Observable<any> {
    return this.http.get(`${this.getConfig('cpmUrl')}shared/weather/${plantId}`).pipe(catchError(this.handleHttpError));
  }

  fetchShareLink(plantId) {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/shareLink/${plantId}`).pipe(catchError(this.handleHttpError))
  }

  enableDisableLink(plantId,status) {
    return this.http.put(`${this.getConfig('cpmUrl')}/chillerplant/shareLink/update/${plantId}/${status}`, null).pipe(catchError(this.handleHttpError))
  }

  generateSharedLink(plantId) {
    return this.http.post(`${this.getConfig('cpmUrl')}/chillerplant/shareLink/create/${plantId}`, null).pipe(catchError(this.handleHttpError))
  }

  getPlantJson(plantId) {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/trio/${plantId}`).pipe(catchError(this.handleHttpError));
  }

  getSharedPlantData(linkShareUuid){
    return this.http.get(`${this.getConfig('cpmUrl')}shared/plant/${linkShareUuid}`).pipe(catchError(this.handleHttpError));
  }

  getSharedMasterData(linkShareUuid, equipId, body) {
    return this.http.post(`${this.getConfig('cpmUrl')}shared/data/${linkShareUuid}/${equipId}`, body).pipe(catchError(this.handleHttpError));
  }

  getSharedAllAlerts(linkShareUuid, equipId, body?) {
    return this.http.post(`${this.getConfig('cpmUrl')}shared/alerts/${linkShareUuid}/${equipId}`,body).pipe(catchError(this.handleHttpError));
  }

  getSharedAlertStatus(linkShareUuid, equipId, body?) {
    return this.http.get(`${this.getConfig('cpmUrl')}shared/alerts/count/${linkShareUuid}/${equipId}`).pipe(catchError(this.handleHttpError));
  }

  getSharedConfig(linkShareUuid, equipId) {
    return this.http.get(`${this.getConfig('cpmUrl')}shared/equipConfig/${linkShareUuid}/${equipId}`).pipe(catchError(this.handleHttpError));
  }

  getSharedPlantJson(linkShareUuid){
    return this.http.get(`${this.getConfig('cpmUrl')}shared/trio/${linkShareUuid}`).pipe(catchError(this.handleHttpError));
  }

  getPlantStatus(plantId) {
    return this.http.get(`${this.getConfig('cpmUrl')}chillerplant/activeDraft/${plantId}`).pipe(catchError(this.handleHttpError));
  }

  getSharedAlertStatusONOrOFF(linkShareUuid, body) {
    return this.http.post(`${this.getConfig('cpmUrl')}shared/pointsByFilter/${linkShareUuid}`, body).pipe(catchError(this.handleHttpError));
  }

  getSharedBulkWritablePointData(pointList: Array<any>, sharedUuId) {
    return this.http.post(`${this.getConfig('cpmUrl')}shared/pointWriteManyData/${sharedUuId}`, pointList).pipe(catchError(this.handleHttpError));
  }

  getSharedCurrenthisReadMany(pointsList: any, sharedUuId) {
    return this.http.post<any>(`${this.getConfig('cpmUrl')}shared/hisReadManyData/${sharedUuId}`, pointsList).pipe(catchError(this.handleHttpError));
  }

  getEnergyConsumed(plantId, equipId, body): Observable<any> {
    return this.http.post(`${this.getConfig('cpmUrl')}chillerplant/view/consumedValue/${plantId}/${equipId}`, body).pipe(catchError(this.handleHttpError));
  }

  getsharedEnergyConsumed(sharedUuId, equipId, body){
    return this.http.post(`${this.getConfig('cpmUrl')}shared/consumedValue/${sharedUuId}/${equipId}`, body).pipe(catchError(this.handleHttpError));
  }
}
