import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@/shared/services/api.service';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-add-alert-model',
  templateUrl: './add-alert-model.component.html',
  styleUrls: ['./add-alert-model.component.scss']
})
export class AddAlertModelComponent {

  constructor(@Inject(DIALOG_DATA) public data, public dialogRef: MatDialogRef<AddAlertModelComponent>, public apiService: ApiService) { }

  alertList:any = [];
  alertSearch: any;
  selectedAlert: any = [];
  loading:boolean = true;

  ngOnInit(): void {
    this.loading = true;
    this.getAlertData();
  }

  alertSelected(alertData) {
    alertData.selected = !alertData.selected;
    this.alertChanged();
  }

  alertChanged() {
    this.selectedAlert = [];
    this.alertList.forEach(alertData => {
      if (alertData.selected) {
        alertData['timeGenerated'] = dayjs(Date.now()).format("MMM Do YYYY | h:mm A")
        this.selectedAlert.push(alertData);
      }
    })
  }

  addAlert() {
    this.dialogRef.close(this.selectedAlert);
  }

  cancel() {
    this.dialogRef.close();
  }

  getAlertData() {
    this.apiService.getAlerts(this.data.siteId).subscribe((response)=>{
      this.alertList = [];
      if(response && response.data.length){
        response.data.forEach(alertData =>{
          if (!this.data.alerts.some(selectedAlert => selectedAlert.alertId === alertData._id)) {
            this.alertList.push({
              "alertId": alertData._id,
              "selected": false,
              "alertName": alertData.alert.mTitle,
              "severity": alertData.alert.mSeverity
            })
          }
        });
      }
      this.loading = false;
    });
  }

}
