import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { CPMModule } from './cpm/cpm.module';
import {JwtInterceptor} from '../app/cpm/shared/helpers/jwt.interceptor'

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './shared/services/configuration.service';
import { HttpCancelInterceptor } from './shared/services/cancel.interceptor.service';
import { AlertComponent } from './shared/components/alert/alert.component';
import { CpmService } from './shared/services/cpm.service';

const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.setConfig(environment.config);
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    CPMModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    CpmService,
    {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFn,
        deps: [ConfigurationService],
        multi: true
    },
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: 'WINDOW', useFactory: getWindow },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    SharedModule
],
})
export class AppModule { 

}

export function getLocalStorage() {
  return (typeof window !== "undefined") ? window.localStorage : null;
}

export function getWindow(): any {
  return (typeof window !== "undefined") ? window : {};
}