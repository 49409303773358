import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {  DIALOG_DATA } from '@angular/cdk/dialog';
@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
 isCancel:boolean;
  constructor( @Inject(DIALOG_DATA) public data: any,
    public dialog: MatDialog, public dialogRef: MatDialogRef<ConfirmationPopupComponent>,) { }


  cancel() {
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.dialogRef.close('confirm');
  }
}
