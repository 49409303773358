import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(private http: HttpClient) { }
	userTypeData:any;

	getUserType(): any {
		return this.userTypeData;
	}
	setUserType(val: any) {
		this.userTypeData = val;
	}

	getConfig(key: string) {
		return environment.config[key];
	}

	setUserPreferenceData(payload) {
		return this.http.post(`${this.getConfig("pasUrl")}` + `/dashboard/pam-user-preference`, payload)
	}

	getUserPreferenceData() {
		return this.http.get(`${this.getConfig("pasUrl")}` + `/dashboard/pam-user-preference`);
	}

	getUserDetails(userId) {
		return this.http.get(`${this.getConfig('auth').caretakerUrl}/user/${userId}`);
	}
}