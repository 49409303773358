import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CPMMainPageComponent } from './cpm/components/cpm-main-page/cpm-main-page.component';

const routes: Routes = [
  {
    path: '**',
    component: CPMMainPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
