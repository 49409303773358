<div mat-dialog-content class="model-content">
    <div class="row p-l-0">
        <div class="col-12 p-l-0">
            <mat-form-field appearance="fill">
                <input matInput placeholder="Enter Plant Name" type="text" autocomplete="off" [(ngModel)]="data.plantName">
            </mat-form-field>
        </div>
    </div>
    <div class="row p-l-0">
        <div class="col-12 p-l-0">
            <app-select-dropdown  #buildingListRef class="notranslate"
                [placeholderLabel]="sitePlaceHolderText" [data]="data.building" [displayKey]="'dis'" [valueKey]="'id'"
                (valueChanged)="buildingSelected($event)" [disableTranslation]="true">
            </app-select-dropdown>
        </div>
    </div>
    <div class="row p-l-0">
        <div class="warning-text" *ngIf="data.building.length == 0"><i
                class="fas fa-exclamation-triangle warning-icon"></i>No Buildings for this Organization! </div>
        <div class="warning-text" *ngIf="errorText.length > 0"><i
                class="fas fa-exclamation-triangle warning-icon"></i>{{errorText}} </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="flex-footer  d-flex justify-content-end">
        <div class="col-8 d-flex justify-content-end align-items-center">
            <button (click)="cancel()" class="text-uppercase btn color-primary">CANCEL</button>
            <span class="separatorIcon">|</span>
            <button (click)="save()" class="text-uppercase btn color-primary"
                [ngClass]="{'disabled' : selectedSite.length == 0 || data.plantName.length == 0}">SAVE</button>
        </div>
    </div>
</div>