<div id="top-bar" class="cpm-header" [ngClass]="themeClass">
  <div class="cpm-header-name p-l-20" >
    Central Plant Manager
  </div>
  <div *ngIf="!loginUserTypeData.isPublic" class="cpm-header-org">
    <app-select-dropdown *ngIf="!isFac" [(ngModel)]="selectedOrg.id" ngDefaultControl class="select-dropdown header-org notranslate" [placeholderLabel]="'Select Organization'" [data]="organizationList"
      [remoteFilter]="true" [searchInExistingList]="false" (filterMode)="filterOrg($event)" [displayKey]="'name'" [noEntriesFoundLabel]="loadingVal" [emptyResults]="isEmpty"
      [showSelectVal]="true" [valueKey]="'orgId'" (ngModelChange)="orgSelectionChanged()" [disableTranslation]="true">
    </app-select-dropdown>
  </div>

  <div *ngIf="!loginUserTypeData.isPublic && showHide" class="cpm-header-plant" [ngClass]="{'pointer-events-none': !showPlantDropDown} ">
    <mat-form-field appearance="standard" *ngIf="selectedOrg.id" [style.display]="editing? 'none': ''"
      class="dashboardSelectorFormField" style="margin-top: -10px; width: 185px;">
      <mat-select (openedChange)="clearPlantSearchField()" [(ngModel)]="selectedPlant" (selectionChange)="onPlantChange($event.value)"
        [placeholder]="!showPlantDropDown ? 'Loading...':'Select a Plant'" disableOptionCentering panelClass="dashboardSelector" #plantSelector
         class=" w-100 plant-dropdown" matTooltip="{{selectedPlant?.chillerPlantName}}">
         <input #plantSearchField id="plantSearchField" autocomplete="off" class="plantSearchField" placeholder="" matInput type="text" (keyup)="onKey($event, $event.target.value)"  (keydown)="$event.stopPropagation()" >
        <mat-select-trigger *ngIf="selectedPlant?.chillerPlantName">
          {{selectedPlant.chillerPlantName}}
        </mat-select-trigger>
        <ng-container *ngIf="plantListClone.length > 0">
          <mat-option *ngFor="let plant of plantListClone" [value]="plant" matTooltip="{{plant.chillerPlantName}}"
            matTooltipPosition='above'>
            {{plant.chillerPlantName}}
          </mat-option>
        </ng-container>
        <mat-option *ngIf="plantListClone.length == 0 && (!isFac || (isFac && isBuilderUser))">
          <button style="padding-left: 2px;" mat-button color="primary" class="add-plant-btn"
            (click)="editAddPlant('add')">ADD PLANT</button>
        </mat-option>
        <button mat-button *ngIf="plantListClone.length > 0 && (!isFac || (isFac && isBuilderUser))" color="primary" class="add-plant-btn"
          (click)="editAddPlant('add')">ADD PLANT</button>
      </mat-select>
      <div>
        <i matSuffix class="fas fa-angle-down customDropdownIcon"></i>
      </div>
    </mat-form-field>

    <mat-form-field appearance="standard" class="dashboardInputFormField" style="margin-top: -15px; width: 181px;"
      *ngIf="editing">
      <input matInput #NameInput autocomplete="off" [value]="selectedPlant.chillerPlantName">
      <div matSuffix>
        <i class="fas fa-times appIcon" (click)="discardNameEdit($event)"></i>
        <i class="fas fa-check appIcon" (click)="saveNameEdit(NameInput.value)"></i>
      </div>
    </mat-form-field>
  </div>

  <div  class="buildingName notranslate" *ngIf="buildingName?.length > 0 && !loginUserTypeData.isPublic">
    {{buildingName}}
  </div>

  <div class="sharedPlantName" *ngIf="loginUserTypeData.isPublic">
    {{plantName}}
  </div>

  <div class="edit-plant-icon" title="Edit Plant" *ngIf="(selectedTabIndex == 1 && buildingName?.length > 0 ) && (!isFac || (isFac && isBuilderUser))" (click)="editAddPlant('edit')">
    <i class="fa fa-pencil" aria-hidden="true"></i>
  </div>

  <div class="delete-plant-icon" title="Delete Plant" *ngIf="(selectedTabIndex == 1 && buildingName?.length > 0) && (!isFac || (isFac && isBuilderUser))" (click)="deletePlant()">
    <i class="fa fa-trash" aria-hidden="true"></i>
  </div>

  <div class="toogle-div">
    <input type="checkbox" (change)="changeTheme()" class="checkbox" id="chk" [checked]="toggleChecked" [value]="toggleTheme"/>
    <label class="label" for="chk">
      <i class="fas fa-sun"></i>
      <i class="fas fa-moon"></i>
      <div class="ball"></div>
    </label>
  </div>

  <div  *ngIf="selectedTabIndex == 0 && showTabsButtons" class="statusSelector">
    <mat-button-toggle-group [(ngModel)]="selectedPointIcon" #statusIcongroup="matButtonToggleGroup"
      *ngIf="selectedTabIndex == 0 && showTabsButtons" >
      <mat-button-toggle title="Status View" [ngClass]="{'statusButton': statusIcongroup.value === 'statusView'} "
        (click)="statusView('statusView', true)" value="statusView">
        <img class="statusIcon" src='{{"../../../../assets/images/" + pointStatusIcon.statusView + ".svg" }}'>
      </mat-button-toggle>
      <mat-button-toggle title="Minimum View" [ngClass]="{'statusButton': statusIcongroup.value === 'valueView'} "
        (click)="statusView('valueView', true)" value="valueView">
        <img class="statusIcon" src='{{"../../../../assets/images/" + pointStatusIcon.valueView + ".svg" }}'>
      </mat-button-toggle>
      <mat-button-toggle title="Expanded View" [ngClass]="{'statusButton': statusIcongroup.value === 'listView'} "
        (click)="statusView('listView', true)" value="listView">
        <img class="statusIcon" src='{{"../../../../assets/images/" + pointStatusIcon.listView + ".svg" }}'>
      </mat-button-toggle>
      <mat-button-toggle title="Expanded with Trends"
        [ngClass]="{'statusButton': statusIcongroup.value === 'trendView'} " (click)="statusView('trendView', true)"
        value="trendView">
        <img class="statusIcon" src='{{"../../../../assets/images/" + pointStatusIcon.trendView + ".svg" }}'>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="plant-builder-view" *ngIf="buildingName?.length > 0">
    <mat-tab-group mat-align-tabs="end" (selectedTabChange)="viewChanged($event)" [(selectedIndex)]="selectedTabIndex" >
      <mat-tab label="My Plant"></mat-tab>
      <mat-tab *ngIf="!isFac || (isFac && isBuilderUser)"  label="Plant Builder" [disabled] = 'disableMatTab'></mat-tab>
    </mat-tab-group>
  </div>
</div>