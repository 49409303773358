import { AfterViewInit, Component, Input, OnChanges, OnInit, SecurityContext, SimpleChanges } from "@angular/core";
import { generateAreaChart } from "../../helpers/generate-area-chart";
import { ConfirmationModelComponent } from "@/cpm/components/confirmation-model/confirmation-model.component";
import { ObjectUtil } from "@/shared/utils/object-util";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject, map, takeUntil } from "rxjs";
import { CpmService } from "@/shared/services/cpm.service";
declare const d3: any;

@Component({
    selector: 'circular-graph-widget',
    styleUrls: ['./circular-widget.component.scss'],
    templateUrl: './circular-widget.component.html',
})
export class CircularWidgetComponent implements OnInit, OnChanges, AfterViewInit {
    selectValue: string = '';
    options = {};
    dataset: any;
    dataSetObjOfArr = {};
    chartData = [];
    minValObjVfdSpeed = {};
    maxValObjVfdSpeed = {};
    minValObjSpeedFeedback = {};
    maxValObjVfdSpeedFeedback = {};
    mulitpleCTFans = [];
    areaChartData = {};
    multipleVfdSelection: any = {};
    rightContainerHeight: any = {'height': 'auto', 'overflow': 'visible'};
    vfd_speed = ['vfd', 'speed'];
    vfd_speed_feedback = ['vfd',  'speed', 'sensor'];
    selectedPoint = {};
    private unsubscribe: Subject<void> = new Subject();

    @Input() startDate;
    @Input() endDate;
    @Input() selectedEquipData: any;
    @Input() userInfo: any;
    @Input() type;
    @Input() loginUserTypeData;
    @Input() responsiveStylingObj;

    constructor(
        public cpmService: CpmService,
        private dialog: MatDialog,
        private dom: DomSanitizer) {

    }
    ngOnInit() {
        this.dynamicHeightForRightContainer();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.startDate?.currentValue) {
            if (this.selectedEquipData.class.includes('pump1Vertical_forward') || this.selectedEquipData.class.includes('pump1Vertical_backward') || this.selectedEquipData.class == 'coolingTowerSingleFan') {
                this.dataset = this.setCTOrPumpData();
                this.buildSingleCircularChart();
            } else if (this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan') {
                this.buildMultipleCircularChart();
            }
        }
        if (changes.userInfo.currentValue) {
            this.userInfo = changes.userInfo.currentValue;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.selectedEquipData.class.includes('pump1Vertical_forward') || this.selectedEquipData.class.includes('pump1Vertical_backward') || this.selectedEquipData.class == 'coolingTowerSingleFan') {
                this.buildSingleCircularChart();
            }
            const areaWidth = this.responsiveStylingObj.rightChart.areaChart.width;
            const areaHeight = this.responsiveStylingObj.rightChart.areaChart.height;
            for (let i = 1; i <= this.mulitpleCTFans.length; i++) {
                this.generateMultipleCircularChart(i);
                const unit = this.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`]?.[0]?.unit;
                const displayName = (this.selectedEquipData.class.includes('pump1Vertical_forward') || this.selectedEquipData.class.includes('pump1Vertical_backward')) ? 'PUMP' : 'COOLING TOWER FAN' + i;
                const vfd_speed_val = this.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`] && this.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`][0]['value'] ? this.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`][0]['value'] : '';
                this.multipleVfdSelection[i] = Number(vfd_speed_val).toFixed(0) + ` ${this.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`]?.[0]?.unit}`;
                if (document.getElementById(`circular-main-div-multi-${i}`)) {
                    if ((this.areaChartData[`CoolingTower${i}VFDSpeedFeedback`] && this.areaChartData[`CoolingTower${i}VFDSpeedFeedback`]?.length) || (this.areaChartData[`CoolingTower${i}VFDSpeed`] && this.areaChartData[`CoolingTower${i}VFDSpeed`]?.length)) {
                        document.getElementById(`circular-main-div-multi-${i}`).appendChild(generateAreaChart('pumpOrCT', this.startDate, this.endDate, this.areaChartData[`CoolingTower${i}VFDSpeedFeedback`], this.areaChartData[`CoolingTower${i}VFDSpeed`],areaWidth, areaHeight, displayName, unit));
                    }
                }
            }
        }, 100);
    }

    buildAreaChart(unit) {
        const areaWidth = this.responsiveStylingObj.rightChart.areaChart.width;
        const areaHeight = this.responsiveStylingObj.rightChart.areaChart.height;
        const displayName = (this.selectedEquipData.class.includes('pump1Vertical_forward') || this.selectedEquipData.class.includes('pump1Vertical_backward')) ? 'PUMP' : 'COOLING TOWER FAN';
        if ((this.areaChartData['VFDSpeedFeedback'] && this.areaChartData['VFDSpeedFeedback']?.length) || (this.areaChartData['VFDSpeed'] && this.areaChartData['VFDSpeed']?.length)) {
            document.getElementById('pumpOrCT').appendChild(generateAreaChart('pumpOrCT', this.startDate, this.endDate, this.areaChartData['VFDSpeedFeedback'], this.areaChartData['VFDSpeed'], areaWidth, areaHeight, displayName, unit));
        }
    }

    buildMultipleCircularChart() {
        const areaWidth = this.responsiveStylingObj.rightChart.areaChart.width;
        const areaHeight = this.responsiveStylingObj.rightChart.areaChart.height;
        document.querySelectorAll('#areaChart-pumpOrCT').forEach(_item => _item.remove());
        document.querySelectorAll('.circular-main-div-multi').forEach((_item, i) => {
            const index = i + 1;
            const unit = this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeed`]?.[0]?.['unit'];
            if (document.querySelector(`#circular-main-div-multi-${index}`)) {
                const displayName = (this.selectedEquipData.class.includes('pump1Vertical_forward') || this.selectedEquipData.class.includes('pump1Vertical_backward')) ? 'PUMP' : 'COOLING TOWER FAN' + index;
                const vfd_speed_val = this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeed`] && this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeed`][0]['value'] ? this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeed`][0]['value'] : '';
                const vfd_speed_feedback_val = this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeedFeedback`] && this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeedFeedback`][0]['value'] ? this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeedFeedback`][0]['value'] : '';
                if (vfd_speed_feedback_val || vfd_speed_val) {
                    document.getElementById(`circular-main-div-multi-${index}`).appendChild(generateAreaChart('pumpOrCT', this.startDate, this.endDate, vfd_speed_feedback_val, vfd_speed_val,  areaWidth, areaHeight, displayName, unit));
                }
            }
        })
        this.mulitpleCTFans = this.selectedEquipData.class == 'coolingTowerTwoFan' ? Array.from({ length: 2 }, (v, k) => k + 1) : Array.from({ length: 4 }, (v, k) => k + 1);
        this.dataSetObjOfArr = this.setCTOrPumpData(true);
        this.setCircularChartForCTTwoAndFourFan();
    }

    generateVFDFeedBackValue(index) {
        return this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeedFeedback`][0]['value'] + ` ${this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeedFeedback`]?.[0]?.unit}`;
    }

    setCircularChartForCTTwoAndFourFan() {
        for (let i = 1; i <= this.mulitpleCTFans.length; i++) {
            const vfd_speed = this.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`];
            const vfd_speed_feedback = this.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeedFeedback`];
            this.chartData = [[], []];
            if ((vfd_speed && vfd_speed.length) && (vfd_speed_feedback && vfd_speed_feedback.length)) {
                this.chartData = [vfd_speed, vfd_speed_feedback];
            } else if ((vfd_speed && vfd_speed.length) && !(vfd_speed_feedback && vfd_speed_feedback.length)) {
                this.chartData = [
                    vfd_speed,
                    [{
                        'value': this.maxValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeedFeedback`],
                        'color': '#333333'
                    }, {
                        'value': this.minValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeedFeedback`],
                        'color': ''
                    }]
                ];
            } else if (!(vfd_speed && vfd_speed.length) && (vfd_speed_feedback && vfd_speed_feedback.length)) {
                this.chartData = [
                    [{
                        'value': this.maxValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeed`],
                        'color': '#333333'
                    }, {
                        'value': this.minValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeed`],
                        'color': ''
                    }],
                    vfd_speed_feedback,
                ];
            } else {
                this.chartData = [[], []];
            }
        }
    }

    generateMultipleCircularChart(i, self?) {
        let scope = this;
        let unit = scope.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`]?.[0]?.unit;
        if (!unit) {
            unit = scope.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeedFeedback`]?.[0]?.unit;
        }
        if (self) {
            scope = self;
        }
        if (scope.chartData?.length) {
            const div = document.getElementById('circularGraph-clickView-' + i);
            let vfd_speed = scope.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeed`];
            let vfd_speed_feedback = scope.dataSetObjOfArr[`CoolingTowerFan#${i}VFDSpeedFeedback`];
            if (!vfd_speed) {
                vfd_speed = [{
                    'value': scope.maxValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeed`],
                    'color': '#333333'
                }, {
                    'value': scope.minValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeed`],
                    'color': ''
                }];
            }
            if (!vfd_speed_feedback) {
                vfd_speed_feedback = [{
                    'value': scope.maxValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeedFeedback`],
                    'color': '#333333'
                }, {
                    'value': scope.minValObjVfdSpeed[`CoolingTowerFan#${i}VFDSpeedFeedback`],
                    'color': ''
                }];
            }
            const data = [vfd_speed, vfd_speed_feedback];
            scope.generateChart(data, div, unit);
        }
    }

    buildSingleCircularChart(reloadAreaChart = true) {
        document.getElementById('circularGraph-clickView')?.querySelector('svg')?.remove();
        const chartData = this.setCircularChartForCTSingleFan(this.dataset);
        const graphDiv = document.getElementById('circularGraph-clickView');
        const unit = this.selectedPoint?.['unit'] ? this.selectedPoint?.['unit']: '';
        this.selectValue = (this.dataset['VFD_Speed']?.length) ? Number(this.dataset['VFD_Speed'][0]['value']).toFixed(0) + ` ${unit}` : '';
        this.generateChart(chartData, graphDiv, unit);
        if (reloadAreaChart) {
            document.getElementById('areaChart-pumpOrCT')?.remove();
            this.buildAreaChart(unit);
        }
    }

    isPointListTagsAreSame(arr1, arr2) {
        return arr1.every(value => arr2.includes(value));
    }

    setCTOrPumpData(multipleData = false) {
        let dataPoints = this.selectedEquipData?.data?.point;
        if (this.selectedEquipData?.data?.customPoint?.length) {
            dataPoints = this.selectedEquipData?.data?.point.concat(this.selectedEquipData?.data?.customPoint);
        }
        const dataset = {};
        const dataSetObjOfArr = {};
        if (dataPoints?.length) {
            dataPoints.forEach((_r, i) => {
                this.vfd_speed_feedback.push(this.type);
                this.vfd_speed.push(this.type);
                if (this.isPointListTagsAreSame(this.vfd_speed_feedback, dataPoints[i].tags)) {
                    let pointVal = "0";
                    const minNumber = dataPoints[i].minVal;
                    const maxNumber = dataPoints[i].maxVal;
                    const unit = dataPoints?.[i]?.unit;
                    if(dataPoints[i]?.data?.length && dataPoints[i]?.data[dataPoints[i].data.length-1].val){
                      pointVal = dataPoints[i].data[dataPoints[i].data.length-1].val.split('.')[0];
                    }
                    dataset['VFD_Speed_Feedback'] = [{ value: parseInt(pointVal), color: '#FFB134', unit }, { value: 100 - parseInt(pointVal), color: '#333333', unit }];
                    if (this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan') {
                        dataSetObjOfArr['CoolingTowerFan#'+dataPoints[i].cell+'VFDSpeedFeedback'] = dataset['VFD_Speed_Feedback'];
                        this.minValObjVfdSpeed['CoolingTower'+dataPoints[i].cell+'VFDSpeedFeedback'] = minNumber;
                        this.maxValObjVfdSpeed['CoolingTower'+dataPoints[i].cell+'VFDSpeedFeedback'] = maxNumber;
                        this.areaChartData['CoolingTower'+dataPoints[i].cell+'VFDSpeedFeedback'] = dataPoints[i].data;
                    } else {
                        this.minValObjVfdSpeed['VFDSpeedFeedback'] = minNumber;
                        this.maxValObjVfdSpeed['VFDSpeedFeedback'] = maxNumber;
                        this.areaChartData['VFDSpeedFeedback'] = dataPoints[i].data;
                    }
                } else if (this.isPointListTagsAreSame(this.vfd_speed, dataPoints[i].tags)) {
                    const minNumber = dataPoints[i].minVal;
                    const maxNumber = dataPoints[i].maxVal;
                    const unit = dataPoints?.[i]?.unit;
                    let pointVal = "0";
                    if(dataPoints[i]?.writableData?.length && dataPoints[i]?.writableData[dataPoints[i].writableData.length-1].val){
                      pointVal = dataPoints[i].writableData[dataPoints[i].writableData.length-1].val.split('.')[0];
                    }
                    dataset['VFD_Speed'] = [{ value: parseInt(pointVal), color: '#FF5147', unit }, { value: 100 - parseInt(pointVal), color: '#333333', unit }];
                    if (this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan') {
                        this.selectedPoint[dataPoints[i].cell] = dataPoints[i];
                        dataSetObjOfArr['CoolingTowerFan#'+dataPoints[i].cell+'VFDSpeed'] = dataset['VFD_Speed'];
                        this.minValObjVfdSpeed['CoolingTower'+dataPoints[i].cell+'VFDSpeed'] = minNumber;
                        this.maxValObjVfdSpeed['CoolingTower'+dataPoints[i].cell+'VFDSpeed'] = maxNumber;
                        this.areaChartData['CoolingTower'+dataPoints[i].cell+'VFDSpeed'] = dataPoints[i].data;
                        this.options[dataPoints[i].cell] = this.mockVFDArray(Number(minNumber), Number(maxNumber), unit);
                    } else {
                        this.selectedPoint = dataPoints[i];
                        this.minValObjVfdSpeed['VFDSpeed'] = minNumber;
                        this.maxValObjVfdSpeed['VFDSpeed'] = maxNumber;
                        this.areaChartData['VFDSpeed'] = dataPoints[i].data;
                        this.options['VFDSpeed'] = this.mockVFDArray(Number(minNumber), Number(maxNumber), unit);
                    }
                } 
            });
        }
        if (multipleData) {
            return dataSetObjOfArr;
        }
        return dataset;
    }

    mockVFDArray(start, end, unit) {
        const arr = [];
        let s = start;
        for (let i = 0; i < end - start + 1; i++) {
            arr.push(s + ` ${unit}`);
            s = s + 1;
        }
        return arr;
    }

    onMultipleChange(event, index) {
        document.getElementById(`circularGraph-clickView-${index}`).querySelector('svg').remove();
        this.multipleVfdSelection[index] = event.value;
        this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeed`][0]['value'] = parseInt(event.value.split(' ')[0]);
        this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeed`][1]['value'] = parseInt(this.maxValObjVfdSpeed[`CoolingTower${index}VFDSpeed`]) - parseInt(event.value.split(' ')[0]);
        this.onWritablePointsDropdownChange(event, this.selectedPoint[index], index);
    }

    onChange(event, point) {
        document.getElementById('circularGraph-clickView').querySelector('svg').remove();
        this.dataset['VFD_Speed'][0]['value'] = parseInt(event.value.split(' ')[0]);
        this.dataset['VFD_Speed'][1]['value'] = parseInt(this.maxValObjVfdSpeed['VFDSpeed']) - parseInt(event.value.split(' ')[0]);
        this.selectValue = event.value;
        this.onWritablePointsDropdownChange(event, point);
    }

    onWritablePointsDropdownChange(event, point, index?) {
        const checkProrityArray = point.writableData.find(priorityArray => Number(priorityArray.level) < 10);
        const data = point.writableData && point.writableData.length ? point.writableData : point.data;
        if (checkProrityArray) {
          this.openConfirmationMessageOnPriorityChange(point.id, data, event['value'], point.dis, index);
        } else {
          this.forceOverrideData(point.id, data, event['value'], index);
        }
      }
    
      async openConfirmationMessageOnPriorityChange(id, pointData: any, val, name, index) {
        let htmlContent = '';
          const dialogRef = this.dialog.open(ConfirmationModelComponent, {
            panelClass: 'fs-mat-dialog-container',
            width: '450px',
            disableClose: true,
          });
          // tslint:disable-next-line
          htmlContent = `<div>Currently the ${name} has the following higher priorities in effect.\n Would you like to
          override those priorities? </div>`;
    
          dialogRef.componentInstance.title = 'Confirmation';
          dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);
          const alert = await dialogRef.afterClosed().toPromise();
          this.onPopupConfirm(alert, id, pointData, val, index);
      }
    
      onPopupConfirm(alert, id, pointData: any, val, index) {
        if (alert === 'confirm') {
          this.forceOverrideData(id, pointData, val, index);
        } else {
          this.multipleVfdSelection[index] = ObjectUtil.deepClone(pointData[0].val.split(' ')[0]);
        }
      }

      setPriorityData(id, priorityArray, val) {
        return { 
          ref: id,
          level: String(priorityArray.level),
          who: `web_${this.getUserName()}`,
          duration: '0ms',
          val: val 
        };
      }
    
      getUserName() {
        if (Object.keys(this.userInfo)?.length) {
          return this.userInfo['firstName'] + ' ' + this.userInfo['lastName'];
        } else {
          return null;
        }
      }
    
      forceOverrideData(id, prevData: any, val, index?) {
        const self = this;
        const overRideArrayLevel = [];
        let overrideLevel8: any = {};
        overrideLevel8 = this.setPriorityData(id, {level: '10'}, val.split(' ')[0]);
        overRideArrayLevel.push(overrideLevel8);
        // If priority array data is empty then setting the val.
        prevData.map(priorityArray => {
          // If the priority level is less than 10 returning the val as 'N'
          if (priorityArray.level < 10) {
            overrideLevel8 = this.setPriorityData(id, priorityArray, 'N');
            overRideArrayLevel.push(overrideLevel8);
          }
        });

        this.writeDataToHaystack(overRideArrayLevel, '', prevData).pipe(
          map(this.cpmService.stripHaystackTypeMapping)).subscribe(
            (res: any) => {
                if (this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan') {
                    this.generateMultipleCircularChart(index, self);
                } else {
                    this.buildSingleCircularChart(false);
                }
        });
      }
    
      writeDataToHaystack(data: any, reason, prevData) {
        return this.cpmService.updateBulkWritablePointData(data, reason)
          .pipe(takeUntil(this.unsubscribe));
         
      }

    setCircularChartForCTSingleFan(dataset) {
        if ((dataset['VFD_Speed'] && dataset['VFD_Speed'].length) && (dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
            return [dataset['VFD_Speed'], dataset['VFD_Speed_Feedback']];
        } else if ((dataset['VFD_Speed'] && dataset['VFD_Speed'].length) && !(dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
            return [
                dataset['VFD_Speed'],
                [{ value: this.maxValObjVfdSpeed['VFDSpeedFeedback'], color: '#333333' }]
            ]
        } else if (!(dataset['VFD_Speed'] && dataset['VFD_Speed'].length) && (dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
            return [
                [{ value: this.maxValObjVfdSpeed['VFDSpeed'], color: '#333333' }],
                dataset['VFD_Speed_Feedback']
            ]
        } else {
            return [[], []];
        }
    }
    generateChart(dataset, graphDiv, unit?) {
        const width = 120;
        const height = 120;
        let pie = d3.pie().sort(null);
        let arc = d3.arc();
        const svgElement = d3.select(graphDiv)
            .append('svg')
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
        // Shadow effect for arc

        let defs = svgElement.append("defs");

        let filter2 = defs.append("filter")
            .attr("id", "glow2");

        filter2.append("feColorMatrix")
            .attr("type", "matrix")
            .attr("values", "0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0 0");

        filter2.append("feGaussianBlur")
            .attr("stdDeviation", 2)
            .attr("result", "coloredBlur");

        let feMerge2 = filter2.append("feMerge");

        feMerge2.append("feMergeNode")
            .attr("in", "coloredBlur")
        feMerge2.append("feMergeNode")
            .attr("in", "SourceGraphic");


        let filter = defs.append("filter")
            .attr("id", "glow");

        filter.append("feColorMatrix")
            .attr("type", "matrix")
            .attr("values", "1.00 0 0 0 0  0 0.69 0 0 0  0 0 0.20 0 0  0 0 0 1 0");

        filter.append("feGaussianBlur")
            .attr("stdDeviation", 2.5)
            .attr("result", "coloredBlur");

        let feMerge = filter.append("feMerge");

        feMerge.append("feMergeNode")
            .attr("in", "coloredBlur")
        feMerge.append("feMergeNode")
            .attr("in", "SourceGraphic");

        let filter1 = defs.append("filter")
            .attr("id", "glow1")

        filter1.append("feColorMatrix")
            .attr("type", "matrix")
            .attr("values", "1.00 0 0 0 0  0 0.32 0 0 0  0 0 0.28 0 0  0 0 0 1 0");

        filter1.append("feGaussianBlur")
            .attr("stdDeviation", 10.5)
            .attr("result", "coloredBlur");

        let feMerge1 = filter1.append("feMerge");

        feMerge1.append("feMergeNode")
            .attr("in", "coloredBlur")
        feMerge1.append("feMergeNode")
            .attr("in", "SourceGraphic");
        // Append Max Value 
        svgElement.append("text")
            .text(`0 ${unit}`)
            .attr("x", 10)
            .attr("y", -50)
            .attr("font-size", '10px')
            .attr("fill", "#999999");
        // Append Min Value 
        svgElement.append("text")
            .text(`100 ${unit}`)
            .attr("x", -30)
            .attr("y", -50)
            .attr("font-size", '10px')
            .attr("fill", "#999999");
        svgElement.append("line")
            .attr("transform", "translate(40,10)")
            .attr("x1", -40)
            .attr("y1", -70)
            .attr("x2", -40)
            .attr("y2", -10)
            .attr("stroke-width", 1)
            .attr("stroke", "#666666");

        let g = svgElement.selectAll("g")
            .data(function () {
                let values = dataset;
                return values.map(val => val.map(_val => _val.value));
            }).enter().append("g");
        g.selectAll("path")
            .data(function (d, i) {
                return pie(d).map(function (item) {
                    const data = dataset[i][0];
                    const color = data.color;
                    return { data: item, parentIndex: i, color: color };
                });
            }).enter().append("path")
            // .style("filter", function (d, i) {
            //     let glow = (d.color == '#FFB134') ? "url(#glow)" : "url(#glow1)"
            //     if (i == 1) {
            //         glow = "url(#glow2)"
            //     }
            //     return glow;
            // })
            .attr("fill", function (d, i) {
                let chartColor = d.color;
                let color = '#333333';
                if (i == 0) {
                    color = chartColor;
                }
                return color;
            }).attr("d", function (d, i) {
                return arc.innerRadius(function () {
                    let value = (i == 0) ? 15 : 20;
                    if (d.color == "#333333") {
                        value = 20;
                    } else {
                        value = i == 0 ? 15 : 20;
                    }
                    return value + 15 * d.parentIndex;
                }).outerRadius(11 + 15 * (d.parentIndex + 1))(d.data);
            });
        return graphDiv;
    }

    dynamicHeightForRightContainer() {
        let maxVal = 0;
        [1,2,3,4].forEach(index => {
            if (this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeed`] || this.dataSetObjOfArr[`CoolingTowerFan#${index}VFDSpeedFeedback`]) {
                maxVal++;
            }
        })
        if (maxVal == 4 ) {
            this.rightContainerHeight = {height: "345px", 'overflow-y': 'scroll'};
        } else if (maxVal == 3 || maxVal == 2) {
            this.rightContainerHeight = {height: "360px", 'overflow-y': 'scroll'};
        } else if (maxVal == 1) {
            this.rightContainerHeight = {height: "390px", 'overflow-y': 'hidden'};
        }
    }
}