import { AlertService } from '@/shared/services/alert.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  private subscription: Subscription;
  message: any;
  overlayShow = false;

  constructor(
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.getMessage();
  }

  getMessage() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      // Prevent a message without a trace from overshadowing an active message that does have a trace
      if (this.message?.traceId && !message?.traceId && message.type === 'success') {
        return;
      }
      this.message = message;
      if (message && (message.type === 'success' || message.timeout)) {
        setTimeout(() => {
          this.message = false;
        }, message.timeout || 3000);
      } else {
        this.overlayShow = true;
      }
    });
  }
  
  closeErrorDialog() {
    this.overlayShow = false;
    this.message = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
