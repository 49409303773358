<!-- If there are no errors line items, make the message bold. Zach thinks it looks better this way -->
<span>{{ message }}</span>

<div class="error-list-container">
  <ul *ngIf="errors.length > 0">
    <li *ngFor="let error of errors">
      {{ error }}
    </li>
  </ul>
</div>

<div *ngIf="notes.length > 0">
  <span class="section-header">NOTE:</span>
  <ul>
    <li *ngFor="let note of notes">
      {{ note }}
    </li>
  </ul>
</div>

<div class="action-container">
  <button mat-button (click)="ok()">OK</button>
</div>