<div class="pumpOrCTContainer" [ngClass]="selectedEquipData.class" [ngStyle]="{'height': rightContainerHeight.height, 'overflow': rightContainerHeight['overflow']}" id="pumpOrCT">
    <ng-container
        *ngIf="(selectedEquipData.class == 'pump1Vertical_forward') || selectedEquipData.class.includes('pump1Vertical_backward') || (this.selectedEquipData.class == 'coolingTowerSingleFan')">
        <div class="circular-main-div" *ngIf="(dataset['VFD_Speed']?.length || dataset['VFD_Speed_Feedback']?.length)">
            <div id="circularGraph-clickView" class="circularChart-clicked-view">

            </div>
            <div id="graphHeaderText" class="graphPointHeader">
                <ng-container *ngIf="dataset['VFD_Speed']?.length"> VFD SPEED </ng-container>
                <ng-container *ngIf="dataset['VFD_Speed']?.length && dataset['VFD_Speed_Feedback']?.length"> |
                </ng-container>
                <ng-container *ngIf="dataset['VFD_Speed_Feedback']?.length">FEEDBACK</ng-container>
                <div>
                    <ng-container *ngIf="dataset['VFD_Speed']?.length">
                        <div class="VFDDropDownContainer">
                            <div class="d-flex">
                                <mat-select [disabled]="loginUserTypeData?.isPublic || loginUserTypeData?.isVofm"  [ngClass]="{'sharedMatDropDown': loginUserTypeData?.isPublic}" class="vfd-select" id="mySelect-pump" (selectionChange)="onChange($event, this.selectedPoint)"
                                    [(ngModel)]="selectValue">
                                    <mat-option *ngFor="let option of options['VFDSpeed']" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="dataset['VFD_Speed_Feedback']?.length">
                        <span class="graphPointValText outer-chart-color">{{dataset['VFD_Speed_Feedback'][0]['value'] +
                            ' ' + dataset['VFD_Speed_Feedback']?.[0]?.['unit']}}</span>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container
        *ngIf="(selectedEquipData.class == 'coolingTowerFourFan') || (selectedEquipData.class == 'coolingTowerTwoFan')">
        <ng-container *ngFor="let item of mulitpleCTFans; let i = index;">
            <div class="circular-main-div-multi" *ngIf="dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeed']?.length || dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeedFeedback']?.length" [id]="'circular-main-div-multi-'+item">
                <div class="sub-section">
                    <div [id]="'circularGraph-clickView-' + item" class="circularChart-clicked-view">
                    </div>
                    <div [id]="'graphHeaderText-'+ item" class="graphPointHeader">
                        <div class="multipleChartSubText">FAN {{item}}</div>
                        <ng-container *ngIf="dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeed']?.length"> VFD SPEED
                        </ng-container>
                        <ng-container
                            *ngIf="dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeed']?.length && dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeedFeedback']?.length">
                            |
                        </ng-container>
                        <ng-container *ngIf="dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeedFeedback']?.length">
                            FEEDBACK</ng-container>
                        <div>
                            <ng-container *ngIf="dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeed']?.length">
                                <div class="VFDDropDownContainer">
                                    <div class="d-flex">
                                        <mat-select [disabled]="loginUserTypeData?.isPublic"  [ngClass]="{'sharedMatDropDown': loginUserTypeData?.isPublic}" class="vfd-select" [id]="'mySelect-pump-'+item"
                                            (selectionChange)="onMultipleChange($event, item)"
                                            [(ngModel)]="multipleVfdSelection[item]">
                                            <mat-option *ngFor="let option of options[item]" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="dataSetObjOfArr['CoolingTowerFan#'+item+'VFDSpeedFeedback']?.length">
                                <span
                                    class="graphPointValText outer-chart-color">{{generateVFDFeedBackValue(item)}}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>