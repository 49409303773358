import * as dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
declare const d3: any;
let unit;

export function generateBarChart(startDate, endDate, efficiencyData, min, max, type, chartWidth?, chartHeight?) {
    unit = efficiencyData.unit != undefined ? efficiencyData.unit : '';
    const graphDiv = document.createElement("div");
    graphDiv.setAttribute('id', 'efficiency-chart')
    const headerDiv = document.createElement("div");
    headerDiv.setAttribute("class", "bar-chart-header");
    if (type == 'chiller') {
        headerDiv.innerHTML = `Chiller EFFICIENCY (${unit})`
    }
    else if (type == 'CT') {
        headerDiv.innerHTML = `Cooling tower Sump Level (${unit})`;
    }


    graphDiv.appendChild(headerDiv);


    const barChartDiv = document.createElement("div");
    barChartDiv.setAttribute("id", "bar-chart");
    barChartDiv.setAttribute("class", "bar-chart-Container");

    graphDiv.appendChild(barChartDiv);

    var data = generateMockTimeChartData(efficiencyData, min, max);
    let numberOFDays = dayjs(endDate).diff(dayjs(startDate), "day");

    const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S")

    data.forEach(function (d) {
        d.date = parseTime(d.date);
    });

    const xAxisAreaChartParameters = generateXAxisChartParameters(data, numberOFDays);
    let minDateValue = xAxisAreaChartParameters[0];
    let maxDateValue = xAxisAreaChartParameters[1];
    let numberOfTicks = xAxisAreaChartParameters[2];
    let tickDateFormat = xAxisAreaChartParameters[3];

    var margin = {
        top: 20,
        right: 20,
        bottom: 55,
        left: 30
    },
        width = chartWidth - margin.left - margin.right,
        height = chartHeight - margin.top - margin.bottom;


    // create a tooltip
    const tooltip = d3.select(barChartDiv)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "#202020cc")
        .style("z-index", "9999999")
        .style("position", "absolute")
        .style("width", type == 'chiller'?'144px':'170px')
        .style("height", "42px")
        .style("color", "rgb(153, 153, 153)")
        .style("text-align", "left");

    var svg = d3.select(barChartDiv).append("svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var x = d3.scaleTime().range([0, width])
        .domain([minDateValue, maxDateValue]);


    // add the X Axis
    svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .style("color", "#999999")
        .style("font-weight", "550")
        .style("font-size", "9px")
        .style("pointer-events", "none")
        .call(d3.axisBottom(x)
            .tickFormat(d3.timeFormat(tickDateFormat))
            .ticks(numberOfTicks)
            .tickSize(5)
        )
        .selectAll("text")
        .style("text-anchor", "start")
        .attr("dx", "1.0em")
        .attr("dy", "-0.6em")
        .attr("transform", "rotate(90)");

    var y = d3.scaleLinear()
        .range([height, 0]);

    var color = d3.scaleOrdinal(['#12D681', '#666666']);

    var tick1 = svg.append("g")
        .attr("class", "tick")
        .attr("transform", "translate(0,90)")
        .style("pointer-events", "none");

    tick1.append("text")
        .attr("fill", "#999999")
        .attr("x", "-25")
        .attr("dy", "0.32em")
        .text(min);

    var tick2 = svg.append("g")
        .attr("class", "tick")
        .attr("transform", "translate(0,45)")
        .style("pointer-events", "none");

    tick2.append("text")
        .attr("fill", "#999999")
        .attr("x", "-25")
        .attr("dy", "0.32em")
        .text(Math.round(Number(max/2)));

    var tick3 = svg.append("g")
        .attr("class", "tick")
        .attr("transform", "translate(0,0)")
        .style("pointer-events", "none");

    tick3.append("text")
        .attr("fill", "#999999")
        .attr("x", "-25")
        .attr("dy", "0.32em")
        .text(max);

    color.domain(Object.keys(data[0]).filter(function (key) {
        return key !== "date";
    }));

    data.forEach(function (d: any) {
        var y0 = 0;

        d.rates = color.domain().map(function (name) {
            return {
                name: name,
                y0: y0,
                y1: y0 += +d[name],
                point: d
            };
        });
        d.rates.forEach(function (d) {
            d.y0 /= y0;
            d.y1 /= y0;
        });
    });

    var date = svg.selectAll(".date").data(data).enter().append("g").attr("class", "date").attr("transform", function (d) {
        return "translate(" + x(d.date) + ",0)";
    });

    date.selectAll("rect").data(function (d) {
        return d.rates;
    }).enter().append("rect").attr("width", 2).attr("y", function (d) {
        return y(d.y1);
    }).attr("height", function (d) {
        return y(d.y0) - y(d.y1);
    }).style("fill", function (d) {
        return color(d.name);
    })

    var mouseover = function (d) {
        d3.select(".tooltip").style('opacity', '1');
    }
    var mousemove = function (d) {
        var htmlContent = `<ul style="padding-left: 9px; padding-right: 9px; margin-top:5px;">@ ${dayjs(d.srcElement.__data__.point.date).format('ddd MMM DD, YYYY | hh:mm a')}</ul><br>`
        if (d.srcElement.__data__.point.d_d) {
            htmlContent += `<ul style = 'color : #12D681; margin-top: -25px; padding-left: 10px;'>${type == 'chiller'?'Chiller efficiency':'Cooling tower Sump Level'}: ${d.srcElement.__data__.point.d_d} ${unit}</ul>`;
        } else {
            htmlContent += `<ul style = 'margin-top: -25px; padding-left: 10px;'>${type == 'chiller'?'Chiller efficiency':'Cooling tower Sump Level'}: N/A</ul>`
        }

        tooltip
            .html(htmlContent)
            .style("left", (d3.pointer(d)[0] - 80) + "px")
            .style("top", (d3.pointer(d)[1] - 30) + "px")
    }
    var mouseleave = function (d) {
        d3.select(".tooltip").style('opacity', '0');
    }


    svg.on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)

    return graphDiv;
}

export function generateMockTimeChartData(efficiencyData, min, max) {
    let arr = [];
    for (let i = 0; i < efficiencyData.data.length; i++) {
        var obj = {};
        const chillerEfficiency = efficiencyData.data[i].val ? Number(efficiencyData.data[i].val?.split(" ")[0]).toFixed(2): null;
        obj["d_d"] = chillerEfficiency;
        obj["default"] = calculateDefaultValue(obj["d_d"], max);
        obj["date"] = dayjs(efficiencyData.data[i].ts.substring(0, 19)).format('YYYY-MM-DDTHH:mm:ss');
        arr.push(obj);
    }
    return arr;
}

function randomValue(min, max) {
    return Number((Math.random() * (max - min) + min).toFixed(2));
}

function percentageConvert(randomNumber, min, max) {
    let percentConvert = ((randomNumber - min) / (max - min));
    return Number((percentConvert * 100).toFixed(2));
}

function calculateDefaultValue(chillerEfficiencyVal, max) {
    if (chillerEfficiencyVal) {
        return Number(max) - Number(parseInt(chillerEfficiencyVal).toFixed(2));
    }
    return Number(max);
}


function generateXAxisChartParameters(data, duration) {
    let minDateValue = d3.min(data, function (d) { return d.date; })
    let maxDateValue = d3.max(data, function (d) { return d.date; })
    minDateValue = new Date(dayjs(minDateValue).startOf('day').toString());
    maxDateValue = new Date(dayjs(maxDateValue).toString());
    var tickDateFormat, numberOfTicks
    var resultArray
    if (duration == 0) {
        tickDateFormat = "%I %p"
        numberOfTicks = 5
        maxDateValue = new Date(dayjs(dayjs(maxDateValue).endOf('day').toString()).add(2, 'minute').toString());
    } else if (duration == 1) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 2
    }
    else if (duration >= 2 && duration < 4) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 3
    } else if (duration == 4 || duration == 5) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 5
    } else if (duration == 6) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 6
    } else {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 5
    }

    resultArray = [minDateValue, maxDateValue, numberOfTicks, tickDateFormat]
    return resultArray;
}
