import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  
  constructor(
    private http: HttpClient,
    private configService: ConfigurationService,
    @Inject(DOCUMENT) private document: any,
    @Inject('LOCALSTORAGE') public localStorage: any) {
    
  }

  async logout() {
    const gatekeeperUrl = this.configService.getConfig('auth').gatekeeperUrl;
    const currentUrl = this.document.location.href;
    const redirectURL = currentUrl
      ? `/sso/login?serviceURL=${currentUrl}`
      : '/';
    await this.http.get(`${gatekeeperUrl}` + '/user/logout').subscribe();
    this.redirectToLogin(redirectURL);
  }

  redirectToLogin(redirectURL) {
    const gatekeeperUrl = this.configService.getConfig('auth').gatekeeperUrl;
    this.localStorage.clear();
    this.document.location.href = `${gatekeeperUrl}${redirectURL}`;
  }

}

