import * as dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
declare const d3: any;

export function generateAreaChart(type, startDate, endDate, chartData1, chartData2, chartWidth = 250, chartHeight = 100, tooltipDisplayName?,unit?) {
   
    // set the dimensions and margins of the graph
    const margin = { top: 5, right: 20, bottom: 30, left: 35 },
        width = chartWidth - margin.left - margin.right,
        height = chartHeight - margin.top - margin.bottom;
    const graphDiv = document.createElement("div");
    graphDiv.setAttribute("id", "areaChart" + "-" + type);
    graphDiv.setAttribute("class", "areaChart-clicked-view");
    let svg = d3.select(graphDiv).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", 130)
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    if (chartData1 == undefined && chartData2 == undefined) {
        return graphDiv;
    }

    let numberOFDays = dayjs(endDate).diff(dayjs(startDate), "day");
    // assign classes based on chilled or condenser water
    let areaClass, lineClass, areaClass2, lineClass2, waterGradientData, waterGradientData2, gradientId, gradientId2 = "", yDomainMin, yDomainMax, data;
    let tooltipTop = 60;
    let allPointArr = [];

    if (chartData2?.length) {
        chartData2.forEach(point => {
            const pointVal = point.val ? Number(point?.val?.split(" ")[0]).toFixed(2): null;
            allPointArr.push(pointVal);
        });
    }
    if (chartData1?.length) {
        chartData1.forEach(point => {
            const pointVal = point.val ? Number(point?.val?.split(" ")[0]).toFixed(2): null;
            allPointArr.push(pointVal);
        });
    }

    if (type == "chilledWater") {
        areaClass = "chilledWaterarea1"
        lineClass = "chilledWaterLine1"
        areaClass2 = "chilledWaterarea2"
        lineClass2 = "chilledWaterLine2"
        waterGradientData = assignAreaChartGradient("chilledWater")
        gradientId = "chilledWaterGradientID"
        yDomainMin = Math.min.apply(Math, allPointArr);
        yDomainMax = Math.max.apply(Math, allPointArr);
        data = formatAreaChartData(chartData1, chartData2);

    } else if (type == "condenserWater") {
        areaClass = "condenserWaterarea2"
        lineClass = "condenserWaterLine1"
        areaClass2 = "condenserWaterarea1"
        lineClass2 = "condenserWaterLine2"
        waterGradientData = assignAreaChartGradient("condenserWater")
        gradientId = "condenserWaterGradientID"
        yDomainMin = Math.min.apply(Math, allPointArr);
        yDomainMax = Math.max.apply(Math, allPointArr);
        tooltipTop = 300;
        data = formatAreaChartData(chartData1, chartData2);
    } else if (type == 'pumpOrCT') {
        areaClass = "pumpOrCoolingTowerarea1"
        lineClass = "pumpOrCoolingTowerLine1"
        areaClass2 = "pumpOrCoolingTowerarea2"
        lineClass2 = "pumpOrCoolingTowerLine2"
        waterGradientData = assignAreaChartGradient("pumpArea1")
        waterGradientData2 = assignAreaChartGradient("pumpArea2")
        gradientId = "pumpOrCoolingTowerGradientID1"
        gradientId2 = "pumpOrCoolingTowerGradientID2"
        yDomainMin = Math.min.apply(Math, allPointArr);
        yDomainMax = Math.max.apply(Math, allPointArr);
        tooltipTop = 230;
        data = formatAreaChartData(chartData1, chartData2);
    } else if (type == 'boiler') {
        areaClass = "pumpOrCoolingTowerarea1"
        lineClass = "pumpOrCoolingTowerLine1"
        areaClass2 = "pumpOrCoolingTowerarea2"
        lineClass2 = "pumpOrCoolingTowerLine2"
        waterGradientData = assignAreaChartGradient("pumpArea1")
        waterGradientData2 = assignAreaChartGradient("pumpArea2")
        gradientId = "pumpOrCoolingTowerGradientID1"
        gradientId2 = "pumpOrCoolingTowerGradientID2"
        yDomainMin = Math.min.apply(Math, allPointArr);
        yDomainMax = Math.max.apply(Math, allPointArr);
        tooltipTop = 50;
        data = formatAreaChartData(chartData1, chartData2);
    }

    yDomainMin = Math.floor(yDomainMin)
    yDomainMax = Math.ceil(yDomainMax)
    const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S")
    const bisectDate = d3.bisector(function (d) { return d.date; }).left;

    data.forEach(function (d) {
        d.date = parseTime(d.date);
        d.enteringVal = +d.enteringVal;
        d.leavingVal = +d.leavingVal;
    });

    const xAxisAreaChartParameters = generateXAxisAreaChartParameters(data, numberOFDays)
    let minDateValue = xAxisAreaChartParameters[0]
    let maxDateValue = xAxisAreaChartParameters[1]
    let numberOfTicks = xAxisAreaChartParameters[2]
    let tickDateFormat = xAxisAreaChartParameters[3]

    // create a tooltip
    const tooltip = d3.select(graphDiv)
        .append("div")
        .style("opacity", 0)
        .style("background-color", "#202020cc")
        .style("z-index", "9999999")
        .style("position", "absolute")
        .style("min-height", "35px")
        .style("top", '60px');

    if (type == 'pumpOrCT' || type == 'boiler') {
        tooltip.style("width", "228px");
    } else {
        tooltip.style("width", "144px");
    }

    const mouseover = function (d) {
        tooltip
            .style("opacity", 1)
        d3.select(this)
            .style("opacity", 1)
    }

    const mousemove = function (event) {
        var x0 = x.invert(d3.pointer(event, this)[0]);
        var i = bisectDate(data, x0, 1);
        var d0 = data[i - 1];
        var d1 = data[i];
        var d = x0 - d0?.date > d1?.date - x0 ? d1 : d0;
          var  supplyColor, returnColor, supplyValue, returnValue;
        if (type == "chilledWater") {
            supplyColor = "#4EABFF"
            returnColor = "#9CD1FF"
            supplyValue = Number(d.leavingVal).toFixed(2)
            returnValue = Number(d.enteringVal).toFixed(2)
        } else if (type == "condenserWater") {
            supplyColor = "#7EF3C2"
            returnColor = "#12D681"
            supplyValue = Number(d.leavingVal).toFixed(2)
            returnValue = Number(d.enteringVal).toFixed(2)
        } else if (type == 'pumpOrCT' || type == 'boiler') {
            supplyColor = "#FF5147"
            returnColor = "#FF9F59"
            supplyValue = Number(d.leavingVal).toFixed(2)
            returnValue = Number(d.enteringVal).toFixed(2)
        }
        let toopTipParamPosition = -10
        if (chartData1 && !chartData2) {
            toopTipParamPosition = -21
        }
        var htmlContent = `<ul style="padding-left: 9px; padding-right: 9px; margin-top:5px;z-index:2000px">@ ${dayjs(d.date).format('ddd MMM DD, YYYY | hh:mm a')}</ul><br>`
        if (chartData2) {
            htmlContent += `<ul style = 'color : ${supplyColor}; margin-top: -25px; padding-left: 10px;'>${type == 'pumpOrCT' ? tooltipDisplayName + ' VFD SPEED' : (type == 'boiler' ? 'BOILER LEAVING WATER TEMPERATURE' : 'SUPPLY')}: ${supplyValue} ${unit}</ul>`
        }
        if (chartData1) {
            htmlContent += `<ul style = 'color : ${returnColor}; margin-top: ${toopTipParamPosition}px; padding-left: 10px;'>${type == 'pumpOrCT' ? tooltipDisplayName + ' VFD SPEED FEEDBACK' : (type == 'boiler' ? 'BOILER ENTERING WATER TEMPERATURE' : 'RETURN')}: ${returnValue} ${unit}</ul>`
        }

        tooltip
            .html(htmlContent)
            .style("top", event.layerY - 50 + "px")
            .style("left",event.layerX + 10 + "px")
            .style("color", "#999999")
    }

    const mouseleave = function (d) {
        tooltip
            .style("opacity", 0)
        d3.select(this)
            .style("stroke", "none")
            .style("opacity", 1)
    }

    // set the ranges
    let x = d3.scaleTime().range([0, width]);
    let y = d3.scaleLinear().range([height, 0]);

    //linearGradient

    svg.append("defs").append("linearGradient")
        .attr("id", gradientId)
        .attr("x1", "0%")
        .attr("x2", "0%")
        .attr("y1", "0%")
        .attr("y2", "100%")
        .selectAll("stop")
        .data(waterGradientData)
        .enter().append("stop")
        .attr("offset", function (d) { return d.offset; })
        .attr("stop-color", function (d) { return d.color; });

    if (gradientId2) {
        svg.append("defs").append("linearGradient")
            .attr("id", gradientId2)
            .attr("x1", "0%")
            .attr("x2", "0%")
            .attr("y1", "0%")
            .attr("y2", "100%")
            .selectAll("stop")
            .data(waterGradientData2)
            .enter().append("stop")
            .attr("offset", function (d) { return d.offset; })
            .attr("stop-color", function (d) { return d.color; });
    }
    // scale the range of the data
    x.domain([minDateValue, maxDateValue]);
    y.domain([yDomainMin, yDomainMax]);

    if (chartData1) {
        // define the area1
        let area1 = d3.area()
            .x(function (d) { return x(d.date); })
            .y0(height)
            .y1(function (d) { return y(d.enteringVal); });

        // add the area1
        svg.append("path")
            .data([data])
            .attr("class", areaClass)
            .attr("d", area1);

        // define the line1
        let valueline1 = d3.line()
            .x(function (d) { return x(d.date); })
            .y(function (d) { return y(d.enteringVal); });

        // add the valueline path 1.
        svg.append("path")
            .data([data])
            .attr("class", lineClass)
            .style("stroke-width", "4")
            .attr("d", valueline1);
    }

    if (chartData2) {
        // define the area2
        let area2 = d3.area()
            .x(function (d) { return x(d.date); })
            .y0(height)
            .y1(function (d) { return y(d.leavingVal); });

        // add the area2
        svg.append("path")
            .data([data])
            .attr("class", areaClass2)
            .attr("d", area2);

        // define the line2
        let valueline2 = d3.line()
            .x(function (d) { return x(d.date); })
            .y(function (d) { return y(d.leavingVal); });

        // add the valueline path 2.
        svg.append("path")
            .data([data])
            .attr("class", lineClass2)
            .style("stroke-width", "4")
            .attr("d", valueline2);
    }

    // add the X Axis
    svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .style("color", "#999999")
        .style("font-weight", "550")
        .style("font-size", "9px")
        .style("pointer-events", "none")
        .call(d3.axisBottom(x)
            .tickFormat(d3.timeFormat(tickDateFormat))
            .ticks(numberOfTicks)
            .tickSize(5)
        )
        .selectAll("text")
        .style("text-anchor", "start")
        .attr("dx", "1.0em")
        .attr("dy", "-0.6em")
        .attr("transform", "rotate(90)");

    // add the Y Axis
    svg.append("g")
        .style("color", "#999999")
        .style("font-weight", "550")
        .style("font-size", "10px")
        .style("pointer-events", "none")
        .call(d3.axisLeft(y)
            .tickValues([yDomainMin, yDomainMax])
            .tickFormat(function (d) {
                return d + unit;
            })
            .tickPadding(5)
            .tickSize(0)

        );

    // add the Y Axis right
    svg.append("g")
        .attr("transform", "translate(" + x(d3.max(data, function (d) { return Math.max(d.date); })) + ", 0)")
        .style("color", "#999999")
        .call(d3.axisRight(y)
            .tickSize(0)
            .tickValues([])
        );

    svg.on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)

    return graphDiv;
}

function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateXAxisAreaChartParameters(data, duration) {
    let minDateValue = d3.min(data, function (d) { return d.date; })
    let maxDateValue = d3.max(data, function (d) { return d.date; })
    minDateValue = new Date(dayjs(minDateValue).startOf('day').toString());
    maxDateValue = new Date(dayjs(maxDateValue).toString());
    var tickDateFormat, numberOfTicks
    var resultArray
    if (duration == 0) {
        tickDateFormat = "%I %p"
        numberOfTicks = 5
        maxDateValue = new Date(dayjs(dayjs(maxDateValue).endOf('day').toString()).add(2, 'minute').toString());
    } else if (duration == 1) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 2
    }
    else if (duration >= 2 && duration < 4) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 3
    } else if (duration == 4 || duration == 5) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 5
    } else if (duration == 6) {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 6
    } else {
        tickDateFormat = "%d-%m-%Y"
        numberOfTicks = 5
    }

    resultArray = [minDateValue, maxDateValue, numberOfTicks, tickDateFormat]
    return resultArray;
}
function assignAreaChartGradient(type) {
    let gradient;
    let chilledWaterGradientData = [
        { offset: "0%", color: "#4EABFF" },
        { offset: "30%", color: "#9CD1FF" },
        { offset: "45%", color: "#6b90b0" },
        { offset: "55%", color: "#6b90b0" },
        { offset: "90%", color: " #4d677d" },
        { offset: "100%", color: " #495a69" }
    ]
    let condenserWaterGradientData = [
        { offset: "0%", color: "#55b58d" },
        { offset: "30%", color: "#55b58d" },
        { offset: "45%", color: "#35785c" },
        { offset: "55%", color: "#35785c" },
        { offset: "90%", color: "#1b3e2f" },
        { offset: "100%", color: "#11291f" }
    ]

    let pumpOrCoolingToweGradientData = [
        { offset: "0%", color: "#FFB134" },
        { offset: "30%", color: "#da982f" },
        { offset: "45%", color: "#a48450" },
        { offset: "55%", color: "#a48450" },
        { offset: "90%", color: "#807d7b" },
        { offset: "100%", color: "#807d7b" }
    ]
    let pumpOrCoolingToweGradientData1 = [
        { offset: "0%", color: "#FF5147" },
        { offset: "30%", color: "#c6504a" },
        { offset: "45%", color: "#c6504a" },
        { offset: "55%", color: "#7a3330" },
        { offset: "90%", color: "#7a3330" },
        { offset: "100%", color: "#4d2f2e" }
    ]
    let pumpOrCoolingToweGradientData2 = [
        { offset: "0%", color: "#FFB134" },
        { offset: "30%", color: "#da982f" },
        { offset: "45%", color: "#a48450" },
        { offset: "55%", color: "#a48450" },
        { offset: "90%", color: "#807d7b" },
        { offset: "100%", color: "#807d7b" }
    ]
    if (type == "chilledWater") {
        gradient = chilledWaterGradientData;
    } else if (type == "condenserWater") {
        gradient = condenserWaterGradientData;
    } else if (type == "pumpArea1") {
        gradient = pumpOrCoolingToweGradientData1;
    } else if (type == "pumpArea2") {
        gradient = pumpOrCoolingToweGradientData2;
    }
    return gradient;
}

function formatAreaChartData(chartData1, chartData2) {
    let areachartArr = [];
    let obj = {};

    let dateArr = [];
    if (chartData1?.length) {
        dateArr = chartData1.map(pointData => pointData.ts);
    } else if (chartData2?.length) {
        dateArr = chartData2.map(pointData => pointData.ts);
    }

    if (dateArr?.length) {
        dateArr.forEach(date => {
            let leaveVal = chartData2?.find(o => o.ts == date);
            let enterVal = chartData1?.find(o => o.ts == date);
            date = dayjs(date.substring(0, 19)).format('YYYY-MM-DDTHH:mm:ss');
            obj = {
                date: date,
                enteringVal: enterVal != undefined && enterVal?.val ? Number(enterVal.val.split(" ")[0]).toFixed(2) : null,
                leavingVal: leaveVal != undefined && leaveVal?.val ? Number(leaveVal.val.split(" ")[0]).toFixed(2) : null
            }
            areachartArr.push(obj);
        })
    }
    return areachartArr;
}
