import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CpmService {

  url: string;

  constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.url = this.configService.getConfig('haystackUrl');
  }

  getmultiSiteTags(siteRefs): Observable<any> {
    let data = `ver:"3.0"\nid`;
    siteRefs.forEach((siteRef) => {
      data += `\n@${siteRef}`;
    });
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/zinc');
    return this.http.post(`${this.configService.getConfig('tagsUrl')}`, data, { headers }).pipe(catchError(this.handleError));
  }

  sortResponse(res) {
    res.rows = res.rows.reverse();
    return res;
  }

  stripHaystackTypeMapping(response: any) {
    return (response) ? JSON.parse(JSON.stringify(response).replace(/"r:|"c:|"n:|"t:|"b:|"m:|"z:|"s:|"d:/g, '"')) : '';
  }

  getBulkWritablePointData(pointList: Array<any>) {
    let data = `ver:"2.0"\nid`;
    pointList.forEach((_id) => {
      data += `\n@${_id}`;
    });
    return this.http.post(`${this.url}v2/pointWriteMany/`, data).pipe(catchError(this.handleError));
  }

  updateBulkWritablePointData(pointList: any[], reason?: string) {
    let requestGrid = reason ? `ver:"2.0" reason:"${reason}"\n` : 'ver:"2.0"\n'
    requestGrid += 'id,level,val,who,duration';
    pointList.forEach(({ ref, level, who, duration, val }) => {
      requestGrid += `\n@${ref},${level},${val},"${who}",${duration}`;
    });
    return this.http.post(`${this.url}v2/pointWriteMany/`, requestGrid).pipe(catchError(this.handleError));
  }

  findByQuery(queryString: string) {
    const data = `ver:"2.0"\nfilter\n"${queryString}"`;
    return this.http.post(`${this.url}v1/read/`, data).pipe(catchError(this.handleError));
  }

  getReadById(pointId: string) {
    const data = `ver:"2.0"\nid\n@${pointId}`;
    return this.http.post(`${this.url}v1/read/`, data).pipe(catchError(this.handleError));
  }

  getCurrenthisReadMany(pointsList: any) {
    const range = `range:"last"`;
    let data = `ver:"3.0" ${range}\nid`;
    for (const point of pointsList) {
      data += '\n@' + point;
    }
    return this.http.post<any>(`${this.url}v2/hisReadMany/`, data).pipe(catchError(this.handleError));
  }

  getHaystackDataByQuery(query) {
    const data = `ver:"3.0"\nfilter\n"${query}"`
    return this.http.post(`${this.url}v1/read/`, data).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
