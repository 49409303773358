<mat-form-field  [floatLabel]="'never'" [ngClass]="customClass">
    <mat-select  [ngClass]="customClass" #selectDropdown [(value)] = "selectedValue" (keydown.ArrowDown)="openDropdown()" (keydown.Enter)="$event.stopImmediatePropagation()" (keydown.ArrowUp)="$event.stopImmediatePropagation()" (keydown.ArrowLeft)="$event.stopImmediatePropagation()" (keydown.ArrowRight)="$event.stopImmediatePropagation()" (change)="setOption($event)" [placeholder]="placeholderLabel" [disableOptionCentering]="true">
      <!-- search added -->
      <div class="mat-select-search-inner"
          [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple}">
        <input matInput
              class="mat-select-search-input notranslate"
              #searchSelectInput
              (keydown)="_handleKeydown($event)"
              [(ngModel)]="searchText"
              (input)="searchText"
              (ngModelChange)="inputChanged($event)"
              [placeholder]="'Search'"
              autocomplete="off"
        />
        <button id="select-search" mat-button *ngIf="searchText"
            mat-icon-button
            aria-label="Clear"
            (click)="_reset(true)"
            class="mat-select-search-clear">
            <i class="fas fa-times appIcon" aria-hidden="true"></i>
            
        </button>
      </div>
      <div *ngIf="(noEntriesFoundLabel && filteredList.length === 0 && !emptyResults && !isSearching)"
          class="mat-select-search-no-entries-found">
        {{noEntriesFoundLabel}}
      </div>
      <div *ngIf="searchingLabel && isSearching"
          class="mat-select-search-no-entries-found">
        {{searchingLabel}}
      </div>
      <!-- search end -->
      <div class="selectionOptions" [ngStyle]="{'max-height': displayKey == 'scheduleName' ? '190px' : '230px'}" #dropDownOptions *ngIf="!isSearching">
        <mat-option *ngFor="let item of filteredList" id="{{'select-'+ displayKey?item[displayKey]:item}}" [value]="valueKey?item[valueKey]:item"  [ngClass] ="{'notranslate':disableTranslation,'indeterminate-state': selectedValues.length > 0 && selectedValues.length != data.length && item[valueKey] == 'all' }" (click)="toggleSelection(item)">
          {{displayKey?item[displayKey]:item}}
        </mat-option>
      </div>
      <ng-container *ngIf="showSelectVal">
        <div class="divider" *ngIf="selectedValues.length"></div>
        <div class="selectedOptionsList">
          <ng-container *ngFor="let item of selectedValues">
            <mat-option [ngClass]="{'notranslate':disableTranslation}" id="{{'singleselect-'+displayKey?item[displayKey]:item}}" *ngIf="checkSelection(item)" [value]="valueKey?item[valueKey]:item" (click)="toggleSelection(item)">
              {{displayKey?item[displayKey]:item}}</mat-option>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="emptyResults">
        <div class="selectedOptionsList">
          <mat-option [disabled] ="emptyResults" class="hide-checkbox">
            {{noEntriesFoundLabel}}
          </mat-option>
        </div>
      </ng-container>
    </mat-select>
  </mat-form-field>
