import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ArrayUtil } from '@/shared/utils/array-util';
import { MatSelect } from '@angular/material/select';
import { NgSelectComponent } from '@ng-select/ng-select';
import find from 'lodash-es/find';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CpmService } from '@/shared/services/cpm.service';

@Component({
  selector: 'app-add-point-model',
  templateUrl: './add-point-model.component.html',
  styleUrls: ['./add-point-model.component.scss']
})
export class AddPointModelComponent implements OnInit {
  isCustom: boolean = false;
  @ViewChild('select', { static: true }) matSelect: MatSelect;
  @ViewChild('tagSelect') public tagSelect: NgSelectComponent;

  sites = [];

  tagList: Array<any> = [];
  selectedTags: string[] = [];
  numResults: number = 0;
  hayStackQuery = '';
  isQueryMode: boolean = false;
  selectedSites: Array<any> = [];
  equipSearch: any = '';
  pointSearch: any = '';
  equipList: Array<any> = [];
  pointList: Array<any> = [];
  selectedPoint: Array<any> = [];
  isPointSelected: boolean = false;
  showBuildChangeWarning: boolean = false;
  buildTypechange: any = '';
  fetchingPoints: boolean = false;
  selectedEquipsId: Array<any> = [];
  pointsCalled: boolean = false;
  siteTempArr: Array<any> = [];
  equipCalled: boolean = false;
  hasError: boolean = false;
  errorTxt: any = '';

  constructor(
    public cpmService:CpmService,
    public dialogRef: MatDialogRef<AddPointModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.selectedSites = [this.data.sites[0].id];
    this.getSites();
    this.generateQuery();
    this.getSiteTags();
  }


  onSiteSelect(data) {
    this.selectedSites = data;
    if (!this.isCustom) {
      this.generateQuery();
      this.getEquip();
    }
  }

  queryView() {
    this.isQueryMode = !this.isQueryMode;
  }

  getSites() {
    this.sites = [];
    if (this.data && this.data.sites) {
      this.siteTempArr = ArrayUtil.clone(this.data.sites);
    }
    this.sites = this.data.sites;
  }


  getSiteTags() {
    this.cpmService.getmultiSiteTags(this.selectedSites).subscribe((res) => {
      this.tagList = res.rows[0].tags;
      if (this.tagList.length == 0) {
        this.selectedTags = [];
        this.pointList = [];
        this.selectedPoint = [];
      }
    })
  }

  getEquip() {
    this.equipCalled = false;
    this.selectedEquipsId = [];
    let query = `equip and `;
    query += this.genereateQueryForRefs(this.selectedSites, 'siteRef');
    this.cpmService.getHaystackDataByQuery(query).subscribe((res) => {
      res = this.cpmService.stripHaystackTypeMapping(res);
      this.equipList = res.rows;
      this.equipList.map(equip => {
        equip.checked = false; 
        equip.pointName = equip?.shortDis ? equip?.shortDis: equip?.dis;
        equip.pointId = equip.id;
      });
      this.equipCalled = true;
    })
  }


  onEquipSelect(equip) {
    let selectEquip = find(this.equipList, equip);
    selectEquip.checked = !selectEquip.checked;
    this.generateQuery();
  }

  trackByPoints(index: number, item: any) {
    return item.id;
  }


  changeBuildType() {
    this.showBuildChangeWarning = true;
    this.buildTypechange = this.isCustom ? 'Builder ' : 'Custom ';
  }

  buildWarningSelected() {
    this.sites = [];
    this.isCustom = !this.isCustom;
    this.equipList = [];
    this.pointList = [];
    this.selectedTags = [];
    this.selectedPoint = [];
    this.selectedEquipsId = [];
    this.sites = this.siteTempArr;
    this.showBuildChangeWarning = false;
    this.cd.detectChanges();
    if (this.isCustom) {
      this.hayStackQuery = '';
    }
  }

  removeSiteName(pointName){
    return pointName.replace(`${this.data.sites[0].dis}-`,'');
  }

  getPoints() {
    this.hasError = false;
    this.fetchingPoints = true;
    this.pointsCalled = false;
    this.cpmService.getHaystackDataByQuery(this.hayStackQuery).subscribe((res) => {
      res = this.cpmService.stripHaystackTypeMapping(res);
      this.pointList = res.rows;
      this.pointList.map(point => {
        point.pointName = point.shortDis ? point.shortDis: this.removeSiteName(point.dis);
        point.pointId = point.id;
      });
      this.fetchingPoints = false;
      this.pointsCalled = true;
    },
      err => {
        this.fetchingPoints = false;
        this.hasError = true;
        this.errorTxt = 'There was an error in executing the Query !';
      })
  }

  onPointSelect(point) {
    let pointLoading: boolean = true;
    this.selectedPoint = [];
    let selectedPoint = find(this.pointList, point);
    selectedPoint.checked = !selectedPoint.checked;
    pointLoading = false;
    this.selectedPoint = this.pointList.filter(point => point.checked)
    if (!pointLoading) {
      this.isPointSelected = this.selectedPoint.length > 0 ? true : false;
    }
  }

  scopeChanged(event) {
    this.pointsCalled = false;
    if (!this.isCustom) {
      this.generateQuery();
      this.getEquip();
    }

  }

  generateQuery() {
    let query = '';
    const queryTags = new Set();
    queryTags.add('point');
    if (this.selectedTags && Array.isArray(this.selectedTags) && this.selectedTags.length) {
      this.selectedTags.forEach(tag => {
        queryTags.add(tag);
      });
    }

    query = `${[...queryTags].join(' and ')} and `;

    query += this.genereateQueryForRefs(this.selectedSites, 'siteRef');


    const selectedEquips: any[] = this.equipList.filter(equip => equip.checked)
    this.selectedEquipsId = selectedEquips.map(equip => equip.id);

    if (this.selectedEquipsId.length) {
      query += ` and `;
    }

    let equipQuery = '';
    if (this.selectedEquipsId.length) {
      equipQuery = this.genereateQueryForRefs(this.selectedEquipsId, 'equipRef');
    }

    const allQueries = [equipQuery].filter(createdQuery => createdQuery !== '');
    const addedQuery = allQueries.join(' or ');

    query += addedQuery;

    this.hayStackQuery = query;
  }

  generateSelectedIds(arr) {
    if (!arr || (Array.isArray(arr) && !arr.length)) {
      return [];
    }
    return arr.filter((_item) => _item['checked']).map((_mapItem) => {
      const id = _mapItem['id'].split(' ')[0];
      return id;
    });
  }


  genereateQueryForRefs(array, ref) {
    let query = ``;
    if (!array || (Array.isArray(array) && !array.length)) {
      return query;
    }
    query += '(';
    array.forEach((site, i) => {
      query += `${ref} == @${site}`;
      if (array.length > 1) {
        query += `${(i == array.length - 1) ? '' : ' or '}`;
      }
    });
    query += ')';
    return query;
  }

  onTagSelect(item) {
    const self = this;
    self.selectedTags.push(item);
    this.generateQuery();
  }

  onTagsClear() {
    this.pointList = [];
  }

  onTagDeSelect(item) {
    const self = this;
    self.selectedTags = self.selectedTags.filter((_item) => _item != item['value']);
    if (self.selectedTags.length == 0) {
      this.pointList = [];
    }
    this.generateQuery();
  }

  markFirst() {
    let cond = this.numResults == 0;
    return cond;
  }


  onClear() {
    const self = this;
    self.selectedTags = [];
  }

  searchTag(searchTerm: string, tag: any) {

    searchTerm = searchTerm.toLowerCase();
    if (searchTerm.startsWith('!')) {
      if (tag.startsWith(searchTerm.slice(1))) {
        return true;
      }
      return false;
    }
    else {
      if (tag.startsWith(searchTerm)) {
        return true;
      }
      return false;
    }
  }


  cancel() {
    this.dialogRef.close();
  }

  addPoint() {
    this.dialogRef.close(this.selectedPoint);
  }
}
