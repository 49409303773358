import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { SelectDropdownComponent } from '@/cpm/shared/components/select-dropdown/select-dropdown.component';
import { ApiService } from '@/shared/services/api.service';

@Component({
  selector: 'app-edit-plant-modal',
  templateUrl: './edit-plant-modal.component.html',
  styleUrls: ['./edit-plant-modal.component.scss']
})
export class EditPlantModalComponent implements OnInit {
  plantName: any;
  selectedSite = [];
  @ViewChild('buildingListRef') buildingRef: SelectDropdownComponent;
  errorText: any = "";
  sitePlaceHolderText: any;

  constructor(@Inject(DIALOG_DATA) public data,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditPlantModalComponent>,
    public apiService: ApiService,) { }

  ngOnInit(): void {
    if (this.data.type == "edit") {
      this.data.plantName = this.data.plantData.chillerPlantName;
      this.data.building.filter((building) => {
        if (this.data.plantData.siteId == building.id) {
          this.selectedSite.push(building);
          this.sitePlaceHolderText = this.selectedSite[0].dis;
        }
      });
    } else {
      this.sitePlaceHolderText = "Select Building";
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  buildingSelected(site) {
    if (site.length) {
      this.selectedSite = site;
    }
  }

  save() {
    if (this.data.type == 'add') {
      let plantData = {
        chillerPlantName: this.data.plantName.trim(),
        orgId: this.data.seletedOrg.orgId,
        orgName: this.data.seletedOrg.orgName,
        siteId: this.selectedSite[0].id
      }
      this.errorText = "";
      this.apiService
        .addPlant(plantData)
        .subscribe((_res) => {
          this.dialogRef.close(_res);
        },
          (err) => {
            this.errorText = err.error.error;
          });
    } else if (this.data.type == 'edit') {
      let plantData = {
        chillerPlantName: this.data.plantName.trim(),
        siteId: this.selectedSite[0].id
      }
      this.errorText = "";
      this.apiService
        .editPlant(this.data.plantData.chillerPlantId, this.data.seletedOrg.orgName, plantData)
        .subscribe((_res) => {
          this.dialogRef.close(_res);
        },
          (err) => {
            this.errorText = err.error.error;
          });
    }
  }
}
