import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { HGrid, TrioWriter } from '@j2inn/haystack-core';
import { ApiService } from './api.service';
import domtoimage from 'dom-to-image-more';
export type SupportedExtensions = 'pdf' | 'png' | 'xlsx' | 'xls' | 'docx' | 'doc' | 'txt' | 'csv' | 'json' | 'xml' | 'trio';
import * as htmlToImage from 'html-to-image';
import { environment } from 'src/environments/environment'; 

export interface ExportAsConfig {
    type: SupportedExtensions;
    elementIds: Array<any>;
    download?: boolean;
    fileName?: string;
    options?: any;
    terrainIds?: Array<string>;
    idType: string;
}

window['html2canvas'] = html2canvas;

@Injectable({
    providedIn: 'root'
})
export class ExportAsService {

    constructor(public apiService: ApiService) {

    }
    /**
     * Main base64 get method, it will return the file as base64 string
     * @param config your config
     */
    get(config: ExportAsConfig): Observable<string | null> {
        // structure method name dynamically by type
        const func = 'get' + config.type.toUpperCase();
        // if type supported execute and return
        if (this[func]) {
            return this[func](config);
        }

        // throw error for unsupported formats
        return new Observable((observer) => { observer.error('Export type is not supported.'); });
    }

    /**
     * Save exported file in old javascript way
     * @param config your custom config
     * @param fileName Name of the file to be saved as
     */
    save(config: ExportAsConfig, fileName: string): Observable<string | null> {
        // set download
        config.download = true;
        // get file name with type
        config.fileName = fileName + '.' + config.type;
        return this.get(config);
    }

    private getPNG(config: ExportAsConfig): Observable<string | null> {
        return new Observable((observer) => {
            const element: HTMLElement = document.getElementById(config.elementIds[0]);
            const imageConfig = {
                skipAutoScale:true,
                pixelRatio: 0.7,
                width: window.innerWidth > element?.getBoundingClientRect().width ?window.innerWidth : element?.getBoundingClientRect().width, 
                height: window.innerHeight > element?.getBoundingClientRect().height ?window.innerHeight : element?.getBoundingClientRect().height
            }
            const filter = (node: HTMLElement) => {
                const exclusionClasses = ['legends', 'plant-button-container', 'discardPopUp', 'btn-expand', 'bar-zoom'];
                return !exclusionClasses.some((classname) => node.classList?.contains(classname));
            }
            if (config.elementIds[0] == 'drawflow') {
                imageConfig['filter'] = filter;
                delete imageConfig['pixelRatio'];
            }
            htmlToImage.toPng(element, imageConfig)
            .then((dataUrl) => {
                var img = new Image();
                img.src = dataUrl;
                this.downloadFromDataURL(config.fileName, dataUrl);
                observer.next();
                
            })
            .catch((error) => {
                console.error('oops, something went wrong!', error);
                observer.error(error);
            });
        });
    }

    downloadFromDataURL(fileName: string, dataURL: string): void {
        // create blob
        this.contentToBlob(dataURL).subscribe(blob => {
            // download the blob
            this.downloadFromBlob(blob, fileName);
        });
    }

    downloadFromBlob(blob: Blob, fileName: string) {
        // get object url
        const url = window.URL.createObjectURL(blob);
        // check for microsoft internet explorer
        const nav = (window.navigator as any);
        if (nav?.msSaveOrOpenBlob) {
            // use IE download or open if the user using IE
            nav.msSaveOrOpenBlob(blob, fileName);
        } else {
            // if not using IE then create link element
            const element = document.createElement('a');
            // set download attr with file name
            element.setAttribute('download', fileName);
            // set the element as hidden
            element.style.display = 'none';
            // append the body
            document.body.appendChild(element);
            // set href attr
            element.href = url;
            // click on it to start downloading
            element.click();
            // remove the link from the dom
            document.body.removeChild(element);
        }
    }

    /**
     * Converts content string to blob object
     * @param content string to be converted
    **/

    contentToBlob(content: string): Observable<Blob> {
        return new Observable((observer) => {
            // get content string and extract mime type
            const arr = content.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            observer.next(new Blob([u8arr], { type: mime }));
            observer.complete();
        });
    }


    getPdf(config, equipDetails) {
        return new Observable((observer) => {
        const id = config.elementIds[0];
        const element: HTMLElement = <HTMLElement>document.getElementById(id);
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'px'
        });
        const imageConfig = {
            skipAutoScale:true,
            pixelRatio: 0.7,
            backgroundColor: '#666666',
            width: window.innerWidth > element?.getBoundingClientRect().width ?window.innerWidth : element?.getBoundingClientRect().width, 
            height: window.innerHeight > element?.getBoundingClientRect().height ?window.innerHeight : element?.getBoundingClientRect().height
        }
        const filter = (node: HTMLElement) => {
            const exclusionClasses = ['legends', 'plant-button-container', 'discardPopUp', 'btn-expand', 'bar-zoom'];
            return !exclusionClasses.some((classname) => node.classList?.contains(classname));
        }
        if (config.elementIds[0] == 'drawflow') {
            imageConfig['filter'] = filter;
            delete imageConfig['pixelRatio'];
        }
        htmlToImage.toPng(element, imageConfig)
        .then((dataUrl) => {
            let img = new Image();
            img.src = dataUrl;
            const imgProps= pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.internal.pageSize.height = pdfHeight;
                pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${equipDetails.toString().replaceAll(' ','')}.pdf`);
            observer.next();
            
        })
        .catch((error) => {
            console.error('oops, something went wrong!', error);
            observer.error(error);
        });

        })
    }

    exportExcel(fileName, data) {
        return  new Promise<void>(async (resolve,reject)=>{
            let workbook = new Workbook();
            let worksheet = workbook.addWorksheet(`${fileName}`);
            let imagebase64;
            try {
                let svgData = await this.apiService.http.get('/assets/images/excelLogo.svg', { responseType: 'text' }).toPromise();
                imagebase64 = "data:image/svg;base64,"+btoa(svgData);
                this.formWorkSheet(data,worksheet,workbook,imagebase64);
            }
            catch (error) {
                console.error('Error fetching SVG file:', error);
                imagebase64='';
                this.formWorkSheet(data,worksheet,workbook,imagebase64);
              }
    
              workbook.xlsx.writeBuffer().then((d) => {
                let blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, `${fileName}.xlsx`);
                resolve()
            }, err => {
                reject()
                console.error('Error fetching SVG file:', err);
            });
             
        })
        
        
    }

    formWorkSheet(data,worksheet,workbook, imagebase64) {
        let imageWidth = 460;
        if (environment.partner === true) {
            imageWidth = 848;
        }
        let data_to_export = [];
        let dataHolder = {};
        let dataValues = [];
        dataValues = dataValues.concat(data?.selectedPlantData?.data?.custom || []);
        dataValues = dataValues.concat(data?.selectedPlantData?.data?.point || []);
        dataValues.forEach((point) => {
            if (point.hasOwnProperty('his') && point.hasOwnProperty('hisInterpolate')) {
                data_to_export.push(point);
                let headername = (point.hasOwnProperty('shortDis') ? point.shortDis : point.dis)?.toLowerCase() + (point.unit? ` (${point.unit})` :'');
                dataHolder[headername] = point.data;
            }
        })

        let header = data_to_export.map((point) => {
            return (point.hasOwnProperty('shortDis') ? point.shortDis : point.dis)?.toLowerCase() + (point.unit? ` (${point.unit})` :'');
        })
        let dateObject = dataValues?.reduce((maxArrayvalues, point) => {
            if (point.data.length > maxArrayvalues.data.length) {
                return point;
            } else {
                return maxArrayvalues;
            }
        });

        header = [...new Set(header)];
        header.unshift('Datetime');

        for(let i =0; i<8;i++) {
            worksheet.addRow([])
        }
        const headerRow = worksheet.addRow(header.map(h=>h));


   
        headerRow.eachCell((cell) => {

            cell.fill = {

                type: 'pattern',

                pattern: 'solid',

                fgColor: { argb: '888888' },

            };

            cell.font = {

                bold: true,

                color: { argb: 'FFFFFF' },

                size: 12,

                name: 'Lato Regular',

            };

            cell.alignment = {

                vertical: 'middle',

                horizontal: 'center',

            };

        });


        let myLogoImage = workbook.addImage({

            base64: imagebase64,
      
            extension: 'svg',
      
        });
      
        worksheet.eachRow((row, rowNumber) => {
      
            if (rowNumber % 2 === 0) {
      
              row.eachCell(cell => {
      
                cell.fill = {
      
                  type: 'pattern',
      
                  pattern: 'solid',
      
                  fgColor: { argb: 'F2F2F2' },
      
                }
      
                cell.alignment = {
      
                  vertical: 'middle',
      
                  horizontal: 'center',
      
                };
      
              })
      
            } else {
      
              row.eachCell(cell => {
      
                cell.alignment = {
      
                  vertical: 'middle',
      
                  horizontal: 'center',
      
                };
      
              })
      
            }
      
          });
          
          worksheet.columns.forEach((col,i)=>{
                col.width = 40;
          })
        // worksheet.mergeCells('A1:C3');
        // worksheet.addImage(myLogoImage, 'A1:C3')
        worksheet.addImage(myLogoImage, {
            tl: { col: 0, row: 0 }, 
            ext: { width: imageWidth, height: 45 } 
        })

        let dateLoopObj = dateObject?.data || [];

        if (dateLoopObj.length > 0) {
            dateLoopObj.forEach((dataVal) => {
                let row = [];
                header.forEach(key => {
                    if (key == 'Datetime') {
                        row.push(dataVal['ts']);
                    } else {
                        let valForTimeStamp = dataHolder[key].find(val => val.ts == dataVal['ts']);
                        row.push(valForTimeStamp?.val?.split(' ')[0] || '-');
                    }

                })
                worksheet.addRow(row);
            })

        }
    }

    setAlertText(alert) {
        let alertTxt = alert?.d_d == 0 ? 'NO' : ''
        if (alert?.d_d == 1) {
            alertTxt = 'LOW';
        } else if (alert?.d_d == 2) {
            alertTxt = 'MODERATE';
        } else if (alert?.d_d == 3) {
            alertTxt = 'SEVERE';
        }
        return alertTxt;
    }

    setStatusText(status) {
        let statusText = status?.d_d == 0 ? 'OFF' : '';
        if (status?.d_d) {
            statusText = 'ON';
        }
        return statusText;
    }

    setConsoldatedData(statusData, chillerLoadData, alertData, buildingScheduleData, energyConsumptionData) {
        let consoldatedData = [];
        if (statusData?.length) {
            consoldatedData = statusData.map(_r => _r.time);
        } else if (chillerLoadData?.length) {
            consoldatedData = chillerLoadData.map(_r => _r.time);
        } else if (alertData?.length) {
            consoldatedData = alertData.map(_r => _r.time);
        } else if (buildingScheduleData?.length) {
            consoldatedData = buildingScheduleData.map(_r => _r.time);
        } else if (energyConsumptionData?.length) {
            consoldatedData = energyConsumptionData.map(_r => _r.time);
        }

        return consoldatedData;
    }

    exportJsonFile(data, fileName) {
        return new Observable((observer) => {
            try {
                const jsonStr = JSON.stringify(data, null, 4);
                const blob = new Blob([jsonStr], { type: 'application/json' });
                fs.saveAs(blob, fileName + '.json');
                observer.next();
                observer.complete();
            } catch (e) {
                observer.error(e);
            }
        });
    }

    exportTRIOFile(data, fileName) {
        return new Observable((observer) => {
            try {     
                const trio = new TrioWriter().addGrid(HGrid.make(data));
                const blob = new Blob([trio.toString()], { type: 'text/plain' });
                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', fileName + ".trio");
                a.click();
                observer.next();
                observer.complete();
            } catch (e) {
                observer.error(e);
            }
        });
    }

}