<div class="d-flex flex-column export-section">

    <div class="flex-body p-0-20 relative">
        <ng-container *ngIf="!showLoader">

            <div class="flex-body-content">
                <div class="row  align-items-center export-head">
                    <div class="col-6 p-l-0">
                        <b class="text-lg">Export</b>
                    </div>
                    <div class="col-6 d-flex align-items-center justify-content-end p-r-0">
                        <label class="tabs" [ngClass]="{'active':(selectedTab == 'download')}" (click)="onTabSelect('download')">Download</label>
                        <label class="tabs" [ngClass]="{'active':(selectedTab == 'webpage')}"
                        (click)="onTabSelect('webpage')"
                          *ngIf="data.fromPlant">Share as
                          Webpage</label>
                    </div>
                </div>
                <div class="row p-t-20 widgets-container" *ngIf="selectedTab == 'download'">
                    <div class="col-6">
                        <label class="label-text">Select file type</label>
                        <ul class="list-none export-radio-group m-t-10 d-flex flex-column">
                            <li class="export-radio-button" *ngFor="let option  of exportOptions;let i = index;">
                                <label>
                                    <input type="radio" name="type" (ngModelChange)="onOptionChange($event)"
                                        [(ngModel)]="selectedOption" [value]="option?.type" id="{{'radio-'+ i}}" />
                                    <span class="radiobtn"></span>
                                    <span class="option-name">
                                        {{option?.name}}
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-t-20 widgets-container" *ngIf="selectedTab == 'webpage'">

                <div class="row align-items-center w-100 p-b-16">
                    <div class="col-12 p-r-0 p-l-0 label-text">
                        <label>Shareable Webpage</label><br />
                    </div>

                    <div class="col-3 p-r-0 p-l-0 d-flex align-items-center">
                       
                        <mat-slide-toggle disableRipple=true
                            [(ngModel)]="sharableWebpage"
                            (change)="onWebpageChange($event)">
                        </mat-slide-toggle> <span class="toggle-label-txt p-l-5">ON</span>
                    </div>
                    <ng-container *ngIf="!isLoading;else loaderTpl">
                      <div class="col-9 d-flex align-items-center justify-content-end p-r-0" style="font-size: 13px;"
                          *ngIf="sharableWebpage">
                          <button class="btn btn-text color-primary text-uppercase p-t-0 p-b-0 preview-btn" (click)="generateShareLink()">Generate
                              new Link</button>
                          <button class="btn btn-text color-primary text-uppercase share p-t-0 p-b-0 preview-btn">
                              <a target="_blank" href={{link}}>Preview Webpage</a>
                          </button>
                      </div>
                    </ng-container>

                    <ng-template #loaderTpl>
                      <div class="d-flex align-items-center justify-content-center" *ngIf="isLoading">
                        <img src="./assets/images/loader.gif" width="36" />
                      </div>
                    </ng-template>

                </div>
                <ng-container *ngIf="sharableWebpage && !isLoading">
                    <div class="col-12 p-l-0 p-r-0 m-t-10 share-link">
                        <label class="lbl label-text" (click)="copyLink()">Link - <small>Click to copy</small>
                            <span class="copied" *ngIf="linkCopiedToClipboard">Copied to Clipboard</span>
                        </label>
                        <mat-form-field class="d-block  p-l-10" [floatLabel]="'never'">
                            <input matInput type="text" [(ngModel)]="link" (click)="copyLink()" />
                            <i matPrefix class="fas fa-copy color-primary p-r-5" aria-hidden="true"
                                (click)="copyLink()"></i>
                        </mat-form-field>
                    </div>
                    <div class="col-12 p-l-0 p-r-0 m-t-10 share-link">
                        <label class="lbl label-text" (click)="copyEmbedText()">Embed - <small>Click to copy</small>
                            <span class="copied" *ngIf="embedCopiedToClipboard">Copied to Clipboard</span>
                        </label>
                        <mat-form-field class="d-block  p-l-10" [floatLabel]="'never'">
                            <input matInput type="text" [(ngModel)]="embedLink" (click)="copyEmbedText()" />
                            <i matPrefix class="fas fa-copy color-primary p-r-5" aria-hidden="true"
                                (click)="copyEmbedText()"></i>
                        </mat-form-field>
                    </div>
                </ng-container>

            </div>
            </div>
        </ng-container>

        <div class="d-flex align-items-center justify-content-center fixedLoader" *ngIf="showLoader">
            <img src="./assets/images/loader.gif" alt="" width="64"/>
        </div>
    </div>
    <ng-container *ngIf="!showLoader">
        <div class="flex-footer d-flex justify-content-end align-items-center">
            <button class="btn btn-outline-primary color-primary text-uppercase" (click)="onNoClick()">{{selectedTab == 'download'? 'Cancel' : 'Close'}}</button>
            <button class="btn btn-outline-primary color-primary text-uppercase" *ngIf="selectedTab == 'download'" [disabled]="showLoader" (click)="download()">Download</button>
        </div>
    </ng-container>
</div>