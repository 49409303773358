import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CPMMainPageComponent } from './components/cpm-main-page/cpm-main-page.component';
import { CPMTopBarComponent } from './components/cpm-top-bar/cpm-top-bar.component';
import { CPMPlantBuilderComponent } from './components/cpm-plant-builder/cpm-plant-builder.component';
import { CpmInfoBarComponent } from './components/cpm-info-bar/cpm-info-bar.component'
import { AddPointModelComponent } from './components/add-point-model/add-point-model.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiteSelectorComponent } from '../cpm/components/site-selector/site-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EquipConfigModelComponent } from './components/equip-config-model/equip-config-model.component';
import { AddAlertModelComponent } from './components/add-alert-model/add-alert-model.component';
import { ConfirmationModelComponent } from './components/confirmation-model/confirmation-model.component';
import { CPMMasterViewEquipModalComponent } from './components/master-view-equip-modal/master-view-equip-modal.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CircularWidgetComponent } from './shared/components/circular-widget/circular-widget.component';
import { EditPlantModalComponent } from './components/edit-plant-modal/edit-plant-modal.component';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
import { TooltipDirective } from '@/shared/directives/tooltip.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { SelectDropdownComponent } from '@/cpm/shared/components/select-dropdown/select-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSkeletonLoaderModule,
    NgxDaterangepickerMd.forRoot(),
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
  ],
  declarations: [
    CPMMainPageComponent,
    CPMTopBarComponent,
    CPMPlantBuilderComponent,
    CpmInfoBarComponent,
    AddPointModelComponent,
    SiteSelectorComponent,
    EquipConfigModelComponent,
    AddAlertModelComponent,
    ConfirmationModelComponent,
    CPMMasterViewEquipModalComponent,
    CircularWidgetComponent,
    EditPlantModalComponent,
    TooltipDirective,
    ToastMessageComponent,
    SelectDropdownComponent

  ],  
  exports:[
    CPMMainPageComponent,
    CPMTopBarComponent,
    CPMPlantBuilderComponent,
    MatDialogModule,
    CPMPlantBuilderComponent,
    AddPointModelComponent,
    CpmInfoBarComponent,
    CircularWidgetComponent,
    SelectDropdownComponent
  ],
entryComponents: [AddPointModelComponent, CPMMasterViewEquipModalComponent],
  schemas :[CUSTOM_ELEMENTS_SCHEMA]
})
export class CPMModule { }
