import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  
  persistToken(token) {
    localStorage.setItem("bearer_token", token);
  }

  getToken() {
    return localStorage.getItem("bearer_token");
  }

	persistUserId(id) {
    localStorage.setItem("userId", id);
	}

	getUserId() {
		return localStorage.getItem("userId");
	}
}
