<div mat-dialog-title class="model-title">{{data.title}}</div>
<div mat-dialog-content class="model-content" [innerHTML]="data.content">
</div>
<div mat-dialog-actions>
    <div class="flex-footer d-flex justify-content-end p-t-18">
        <div class="col-9 d-flex justify-content-end align-items-center">
            <button (click)="cancel()" class="text-uppercase btn color-primary text-uppercase">{{isCancel ?'CANCEL':'STAY ON THIS
                PAGE'}}</button>
            <span class="separatorIcon">|</span>
            <button (click)="confirm()" class="text-uppercase btn color-primary text-uppercase">{{data.buttonText}}</button>
        </div>
    </div>
</div>