<h1 mat-dialog-title class="text-capitalize sg-modal-title m-b-10" *ngIf="title">{{title}}</h1>
<div mat-dialog-content>

    <div class="row align-items-center">
        <div class="col-2 text-center">
            <i class="fa fa-exclamation-triangle color-primary fa-lg" aria-hidden="true"></i>
        </div>
        <div class="p-l-0 pre-wrap col-10">
            <div [innerHtml]="htmlContent"></div> 
        </div>
    </div>

</div>
<div mat-dialog-actions class="pull-right">
    <ng-container>
        <button class="text-uppercase btn color-primary" (click)="cancel()">CANCEL</button>
        <span class="seperator">|</span>
        <button class="text-uppercase btn btn-text color-primary" (click)="confirm()"
            cdkFocusInitial>CONFIRM</button>
    </ng-container>
</div>