import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-model',
  templateUrl: './confirmation-model.component.html',
  styleUrls: ['./confirmation-model.component.scss']
})
export class ConfirmationModelComponent implements OnInit {
  @Input() htmlContent;
  @Input() title;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModelComponent>
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  confirm() {
    this.dialogRef.close('confirm');
  }

}
