import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  forwardRef,
  AfterViewInit
} from '@angular/core';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { ConfigurationService } from '@/shared/services/configuration.service';
import { ObjectUtil } from '@/shared/utils/object-util';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ]
})
export class SelectDropdownComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  get data(): any[] {
    return this.list;
  }
  set data(val: any[]) {
    this.list = val || [];
    this.isSearching = false;
    this.filterResults();
  }

  @Input() customClass : string;
  // Whatever name for this (selectedValue) you choose here, use it in the .html file.
  public get selectedValue(): any { return this._value; }
  public set selectedValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      if (!v) {
        this.selectedValues = [];
      }
      this.onChange(v);
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, public router: Router, private config: ConfigurationService) { }
  /** CPM INPUTS */
  @Input() placeholderLabel = 'Select';
  @Input() noEntriesFoundLabel = 'No results found';
  @Input() searchInExistingList? = true;
  @Input() remoteFilter = false;
  @Input() displayKey = '';
  @Input() valueKey = '';
  @Input() showSelectVal: boolean = false;
  @Input() emptyResults = false;
  @Output() filterMode: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged = new EventEmitter();
  @Input() disableTranslation = false;

  disableTranslationForLabel: boolean = false;
  
  searchUpdate = new Subject<string>();
  list: Array<any> = [];
  searchingLabel?= 'Searching ...';
  private _value: string;
  public filteredList = [];
  selectedValues = [];
  searchText = '';
  isSearching = false;
  
  /** Reference to the search input field */
  @ViewChild('searchSelectInput', { read: ElementRef, static: true }) searchSelectInput: ElementRef;

  /** Reference to the search input field */
  @ViewChild('selectDropdown', { static: true }) matSelect: MatSelect;
  @ViewChild('dropDownOptions', { read: ElementRef }) public dropDownOptions: ElementRef<any>;
  
  /** Reference to the MatSelect options */
  public _options: QueryList<MatOption>;

  /** Whether the backdrop class has been set */
  private overlayClassSet = false;

  /** Event that emits when the current value changes */
  private change = new EventEmitter<string>();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selectedValue = value;

  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnInit() {
    // set custom panel class
    const panelClass = 'mat-select-search-panel';
    if (this.matSelect.panelClass) {
      if (Array.isArray(this.matSelect.panelClass)) {
        this.matSelect.panelClass.push(panelClass);
      } else if (typeof this.matSelect.panelClass === 'string') {
        this.matSelect.panelClass = [this.matSelect.panelClass, panelClass];
      } else if (typeof this.matSelect.panelClass === 'object') {
        this.matSelect.panelClass[panelClass] = true;
      }
    } else {
      this.matSelect.panelClass = panelClass;
    }

    // Debounce search.
    this.searchUpdate.pipe(
      takeUntil(this._onDestroy),
      debounceTime(400))
      .subscribe(value => {
        if (this.remoteFilter) {
          this.filterMode.emit({
            searchText: this.searchText
          });
        }

        if (this.searchInExistingList) {
          this.filterResults();
        } else {
          if (this.remoteFilter) {
            this.isSearching = true;
          }
        }
      });

    // when the select dropdown panel is opened or closed
    this.matSelect.openedChange
      .pipe(takeUntil(this._onDestroy))
      .subscribe((opened) => {
        if (opened) {
          // focus the search field when opening
          this._focus();
        } else {
          // clear it when closingtOpto
          this._reset();
          this.valueChanged.emit(this.selectedValues);
        }
      });

    // set the first item active after the options changed
    this.matSelect.openedChange
      .pipe(take(1))
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this._options = this.matSelect.options;
      });

    // detect changes when the input changes
    this.change
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  inputChanged(event) {
    this.searchUpdate.next(event);
  }

  ngAfterViewInit() {
    this.setOverlayClass();
  }

  filterResults() {
    this.filteredList = this.list;
    if (!this.searchInExistingList) return;
    
    // get the search keyword
    let search = this.searchText;
    search = search.toLowerCase();
    // filter the banks
    if (search.length) {
      this.filteredList = this.list.filter(item => {
        if (this.displayKey) {
          return item[this.displayKey].toLowerCase().includes(search);
        } else {
          return item.toString().toLowerCase().includes(search);
        }
      });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.searchText = '';
  }

  setScrollTop(e){
    this.dropDownOptions.nativeElement.scrollTo({ top: (this.dropDownOptions.nativeElement.scrollTop - 100), behavior: 'auto' }); 
  }

  setScrollBottom(e){
    this.dropDownOptions.nativeElement.scrollTo({ top: (this.dropDownOptions.nativeElement.scrollTop + 100), behavior: 'auto' }); 
  }

  setOption(optionChangedEvent: any) {
    if (this.matSelect.panelOpen) {
      this.selectedValue = optionChangedEvent.value;

      if (typeof this.selectedValue == 'string') {
        this.selectedValues = this.list.filter((selectedRow) => selectedRow[this.valueKey] == this.selectedValue);
      }

      if (typeof this.selectedValue == 'object') {
        let selectedValue = ObjectUtil.deepClone(this.selectedValue);
        this.list.forEach((item) => {
          if (this.selectedValue.length) {
            selectedValue.forEach((selectedItem) => {
              if (selectedItem == item[this.valueKey]) {
                this.selectedValues.push(item);
              }
            })
          }
        })
      }
      // filter the values for the selected value;
      this.valueChanged.emit(this.selectedValues);
    }
  }

  /**
   * Handles the key down event with MatSelect.
   * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
   * @param KeyboardEvent event
   */
  _handleKeydown(event) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === 9) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.keyCode === 32) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
    if (event.keyCode === 38) {
      this.dropDownOptions.nativeElement.scrollTo({ top: (this.dropDownOptions.nativeElement.scrollTop - 26), behavior: 'auto' });
    }
    else if (event.keyCode === 40) {
      this.dropDownOptions.nativeElement.scrollTo({ top: (this.dropDownOptions.nativeElement.scrollTop + 26), behavior: 'auto' });
    }
  }

  openDropdown() {
    this.matSelect.open();
  }

  /**
   * Focuses the search input field
   */
  public _focus() {
    if (!this.searchSelectInput) {
      return;
    }
    // save and restore scrollTop of panel, since it will be reset by focus()
    // note: this is hacky
    const panel = this.matSelect.panel.nativeElement;
    const scrollTop = panel.scrollTop;

    // focus
    this.searchSelectInput.nativeElement.focus();

    panel.scrollTop = scrollTop;
    this.filterResults();
  }

  /**
   * Resets the current search value
   * @param boolean focus whether to focus after resetting
   */
  public _reset(focus?: boolean) {
    this.searchText = '';
    this.filterResults();
    if (this.remoteFilter) {
      this.filterMode.emit({
        searchText: this.searchText
      });
    }

    if (focus) {
      this._focus();
    }
  }

  /**
   * Sets the overlay class  to correct offsetY
   * so that the selected option is at the position of the select box when opening
   */
  private setOverlayClass() {
    if (this.overlayClassSet) {
      return;
    }
    
    const overlayClass = 'cdk-overlay-pane-select-search';
    // note: this is hacky, but currently there is no better way to do this
    if (!this.searchSelectInput) { return; }
    this.searchSelectInput.nativeElement?.parentElement?.parentElement
      ?.parentElement?.parentElement?.parentElement?.classList.add(overlayClass);

    this.overlayClassSet = true;
  }

  checkSelection(item) {
    if (!item) { return false; }
    if (this.displayKey) {
      const index = this.filteredList.findIndex((val) => {
        return val[this.displayKey] === item[this.displayKey];
      });
      return index === -1;
    }
    return this.list.indexOf(item) === -1;
  }

  toggleSelection(item) {
    this.selectedValues = [item];
  }

  getDisplayValues() {
    if(this.selectedValues.length == this.data.length) {
      this.disableTranslationForLabel = false;
      return ((this.displayKey?this.data[0][this.displayKey]:this.data[0]))
    } else {
      this.disableTranslationForLabel = true;
      return (this.selectedValues[1] ? (this.displayKey?this.selectedValues[1][this.displayKey]:this.selectedValues[1]) :'')
    }
  }

  clearSelection(event,type) {
    // Prevent the dropown drop appearing when the clear button is clicked
    if(type){
      event.stopPropagation();
    }
    this.selectedValue = undefined;
    this.selectedValues = [];
    this.valueChanged.emit(this.selectedValues);
  }
}