import { predefinedParametersObj } from "@/shared/constants/master-view-equip-clicked";
import { DIALOG_DATA } from "@angular/cdk/dialog";
import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, SecurityContext, ViewChild } from "@angular/core";
import * as dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
import { generateAreaChart } from '../../shared/helpers/generate-area-chart';
import { generateBulletChart } from '../../shared/helpers/generate-bullet-chart';
import { Drawflow } from '../../../../assets/js/drawflow.min.js';
import { generateBarChart } from '../../shared/helpers/generate-bar-chart';
import { StorageService } from '@/shared/services/storage.service';
import { emptyChartData, generateAlertsArcChart, generateArcChart, generateChillerLoadChart, generateCircularBarPlotChart } from "@/cpm/shared/helpers/generate-arc-chart";
import { Subject, catchError, debounceTime, forkJoin, map, of, takeUntil } from "rxjs";
import { ApiService } from '@/shared/services/api.service';
import { ToastMessageComponent } from "../toast-message/toast-message.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ObjectUtil } from "@/shared/utils/object-util";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperMethodsService } from "@/cpm/shared/services/helper-methods.service";
import { ExportModalComponent } from "@/shared/components/export-modal/export-modal.component";
import { UserService } from '@/cpm/shared/services/user.service';
declare const d3: any;
import { defaultClickViewPoint } from 'src/app/shared/constants/default-click-point';
import { CpmService } from "@/shared/services/cpm.service";


@Component({
  templateUrl: './master-view-equip-modal.component.html',
  styleUrls: ['./master-view-equip-modal.component.scss']
})
export class CPMMasterViewEquipModalComponent implements OnInit, OnDestroy {
  minDate = dayjs(Date.now()).subtract(12, 'month').add(1, 'days').startOf('day');
  maxDate = dayjs(Date.now());
  formatStartDate;
  formatEndDate;
  locale: any = {
    format: 'YYYY-MM-DD',
    displayFormat: '(MMM D, YYYY)',
    separator: ' to ',
  };
  ranges = {
    Today: [dayjs(), dayjs()],
    'Last 3 Days': [dayjs().subtract(2, 'days'), dayjs().endOf('day')],
    'Last Week': [
      dayjs().subtract(6, 'days').startOf('day'),
      dayjs().endOf('day'),
    ],
    'Last Month': [
      dayjs().subtract(1, 'month').add(1, 'days').startOf('day'),
      dayjs().endOf('day'),
    ]
  };
  equipState;
  equipRunningStates = [
    { id: '0', name: 'DISABLED' },
    { id: '1', name: 'ENABLED' },
  ];
  selected;
  drawflow = new Drawflow();
  predefinedParameters: any = {};
  equipList = [];
  selectedEquipData;
  imageURl: string;
  chillerEfficiencyMin = 0.35;
  chillerEfficiencyMax = 0.75;
  showchillerEfficiency: boolean = false;
  clickPointList: any = [];
  tempClickPointList: any = [];
  allPoints: any = [];
  equipImageDetails: any = {};
  equipCommand = '0';
  pointsToCheckArray = {
    "evaporator": ['Chilled Water Entering Temperature', 'Chilled Water Leaving Temperature', 'Chilled water setpoint'],
    "condenser": ['Condenser Water Entering Temperature', 'Condenser Water Leaving Temperature', 'Condenser water setpoint'],
    "boiler": ['Hot Water Entering Temperature', 'Hot Water Leaving Temperature', 'Hot water setpoint'],

    "chillerEfficiency": {
      waterCooledChiller: ['chiller', 'efficiency'],
      airCooledChiller: ['chiller', 'efficiency'],
    },
    "coolingTowerSump":{
      coolingTowerSingleFan: ['coolingTower', 'sump'],
      coolingTowerFourFan: ['coolingTower', 'sump'],
      coolingTowerTwoFan: ['coolingTower', 'sump']
    },
    "energyConsumption":
    {
      pump1Vertical_forward: ['pump', 'energy'],
      pump1Vertical_backward: ['pump', 'energy'],
      waterCooledChiller: ['chiller', 'energy'],
      airCooledChiller: ['chiller', 'energy'],
      steamBoiler: ['boiler', 'energy'],
      condenserBoiler: ['boiler', 'energy'],
      coolingTowerSingleFan: ['coolingTower', 'energy'],
      coolingTowerFourFan: ['coolingTower', 'energy'],
      coolingTowerTwoFan: ['coolingTower', 'energy']
    }
    ,
    "alert":
    {
      pump1Vertical_forward: ['pump', 'alarm'],
      pump1Vertical_backward: ['pump', 'alarm'],
      waterCooledChiller: ['chiller', 'alarm'],
      airCooledChiller: ['chiller', 'alarm'],
      steamBoiler: ['boiler', 'alarm'],
      condenserBoiler: ['boiler', 'alarm'],
      coolingTowerSingleFan: ['coolingTower', 'alarm'],
      coolingTowerFourFan: ['coolingTower', 'alarm'],
      coolingTowerTwoFan: ['coolingTower', 'alarm']
    },
    "status": {
      pump1Vertical_forward: ['pump', 'status'],
      pump1Vertical_backward: ['pump', 'status'],
      waterCooledChiller: ['chiller', 'status'],
      airCooledChiller: ['chiller', 'status'],
      steamBoiler: ['boiler', 'status'],
      condenserBoiler: ['boiler', 'status'],
      coolingTowerSingleFan: ['coolingTower', 'status'],
      coolingTowerFourFan: ['coolingTower', 'status'],
      coolingTowerTwoFan: ['coolingTower', 'status']
    },
    "chillerLoad": {
      waterCooledChiller: ['chiller', 'load'],
      airCooledChiller: ['chiller', 'load']
    },
    "buildingSchedule": {
      pump1Vertical_forward: ['building', 'schedule'],
      pump1Vertical_backward: ['building', 'schedule'],
      waterCooledChiller: ['building', 'schedule'],
      airCooledChiller: ['building', 'schedule'],
      steamBoiler: ['building', 'schedule'],
      condenserBoiler: ['building', 'schedule'],
      coolingTowerSingleFan: ['building', 'schedule']
    }
  }
  plantId;
  hostElement: any;
  svg: any;
  margin = { top: 10, right: 10, bottom: 10, left: 10 };
  width = 450;
  height = 450;
  innerRadius = 180;
  outerRadius = Math.min(this.width, this.height) / 2;
  scaleX;
  scaleY;
  radius = 190;
  graphData: any;
  showChillerLoadChart = false;
  showAlertChart = false;
  showStatusChart = false;
  showEnergyConsumptionChart = false;
  showbuildingSchChart = false;
  CTEquipStates = [];
  circularWidgetPosition = '45px';
  equipTitleTop;
  leftContainerTop;
  pointListHeight;
  commandHeight;
  predefinedEnablePoint = [];
  predefinedPowerPoint = [];
  predefinedRunhours = [];
  predefinedPowerConsumedPoints = [];
  predefinedCommandPoints = [];
  predefinedCommandPoint;
  isLoading = true;
  runHoursParameterVal: any = '';
  powerParameterVal: any = '';
  energyConsumedParameterVal: any = '';
  selectedEquipName = '';
  private unsubscribe: Subject<void> = new Subject();
  private resize$ = new Subject<void>();
  isEquipChilledOrHotOrCondenser = '';
  @ViewChild('clickedViewModal', {static: true}) clickedViewModalRef;
  exportData:any = {};
  loginUserTypeData:any;
  equipStatus: string = '';
  energyConsumedData: any = [];

  // Responsive styling for the view 
  responsiveStylingObj: any = {
    circularWidget: {
      circle: {
        svgWidth: 225,
        svgHeight: 220
      },
      chart: {
        width: 450,
        height: 450
      }
    },
    leftTopChart: {
      chart: {
        width: 300,
        height: 250
      }
    },
    svgDate: {
      chart: {
        width: 500,
        height: 450
      }
    },
    rightChart: {
      bulletChart: {
        width: 240,
        height: 40 
      },
      areaChart: {
        width: 220,
        height: 100 
      }
    },
    loaderStyling: {
      leftTop: {
        'margin-left': '163px',
        'height': '20px',
        'margin-top': '40px',
        'width': '300px',
        'background-color': '#eff0f1',
        'opacity': '10%'
      },
      rightTop: {
        'margin-right': '167px',
        'height': '20px',
        'margin-top': '40px',
        'width': '300px',
        'background-color': '#eff0f1',
        'opacity': '10%'
      },
      centerLeft: {
        'margin-left': '28px',
        'height': '440px',
        'background-color': '#eff0f1',
        'opacity': '10%',
        'width': '300px'
      },
      centerMiddle: {
        'margin-left': '28px',
        'height': '465px',
        'margin-right': '28px',
        'background-color': '#eff0f1',
        'opacity': '10%',
        'width': '458px',
      },
      centerRight: {
        'margin-right': '28px',
        'height': '440px',
        'width': '300px',
        'background-color': '#eff0f1',
        'opacity': '10%'
      }
    }
  }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.calculateLoaderPosition();
      this.resize$.next();
    }
  
  constructor(@Inject(DIALOG_DATA) public data,
    protected chartElement: ElementRef,
    public storageService: StorageService,
    public apiService: ApiService,
    public cpmService: CpmService,
    private toastMessage: MatSnackBar,
    private dialog: MatDialog,
    private dom: DomSanitizer,
    private helperMethodsService: HelperMethodsService,
    private userService:UserService) {
    this.hostElement = this.chartElement.nativeElement;
  }


  ngOnInit() {
    this.selectedEquipData = this.data?.selectedEquipData;
    this.calculateLoaderPosition();
    this.loginUserTypeData = this.userService.getUserType();
    this.selected = {
      startDate: dayjs().startOf('day'),
      endDate: dayjs(Date.now()),
    };
    this.formatEndDate = this.selected.endDate.format('YYYY-MM-DD');
    this.formatStartDate = this.selected.startDate.format('YYYY-MM-DD');
    this.plantId = this.data.plantId;
    this.getClickData(this.selectedEquipData);
    this.setSelectedEquipData();
    let equipClass = this.selectedEquipData.class;
    if (equipClass.includes('pump1Vertical_forward') || equipClass.includes('pump1Vertical_backward')) {
      equipClass = 'pump1Vertical';
    }
    this.predefinedParameters = predefinedParametersObj[equipClass];
    if (this.data?.selectedEquipData.equipStatus) {
      this.equipStatus = this.data.selectedEquipData.equipStatus;
    }
    this.assignImageUrl(this.selectedEquipData);

    this.resize$.pipe(debounceTime(300)).subscribe(async () => {
      this.isLoading = true;
      this.clearChartOnWindowResize();
      this.setResponsiveStyling();
      this.generateLeftAndRightContent();
      await this.initiateData();
      this.isLoading = false;
    })
  }

  checkEquipType(tags) {
    let tag = ['condenser', 'chilled', 'hot', 'boiler', 'coolingTower'];
    return tags.find(_tag => tag.includes(_tag));
  }

  /**
   * Sets the selected equipment data.
   */
  setSelectedEquipData() {
    this.selectedEquipName = this.selectedEquipData.id;
    var obj = this.data.plantJsonStr.drawflow.Home.data;
    this.equipList = Object.keys(obj).map((key) => obj[key]);
    this.equipList = this.equipList.map(_equip => {
      _equip['dropDownName'] = ObjectUtil.deepCopy(_equip?.name);
      if (_equip?.equipData?.brand && _equip?.name.split(`${this.data?.siteName}-`)[1]) {
        _equip['dropDownName'] = _equip?.name.split(`${this.data?.siteName}-`)[1];
      }
      return _equip;
    });
    this.equipList = this.equipList.filter(_equip => _equip.equipType == 'equip');
    const excluded = ["building2Pipe", "building2PipeHot", "dualTempBuilding2Pipe"];
    this.equipList = this.equipList.filter(equip => !excluded.includes(equip.class));
  }

  generateLeftAndRightContent() {
    if (this.selectedEquipData.class == "waterCooledChiller" || this.selectedEquipData.class == "airCooledChiller") {
      let efficiencyData = this.getChillerEfficiency(this.selectedEquipData);

      if (Object.keys(efficiencyData).length && efficiencyData?.data.length) {
        let allPointArr = [];
        efficiencyData.data.forEach(point => {
          const pointVal = point.val ? Number(point.val?.split(" ")[0]).toFixed(2) : null;
          allPointArr.push(pointVal);
        });

        this.chillerEfficiencyMin = Math.min.apply(Math, allPointArr);
        this.chillerEfficiencyMax = Math.max.apply(Math, allPointArr);
        this.showchillerEfficiency = true;
        document
          .getElementById('bar-Chart-content')
          .appendChild(
            generateBarChart(
              this.selected.startDate.format('YYYY-MM-DD'),
              this.selected.endDate.format('YYYY-MM-DD'),
              efficiencyData,
              this.chillerEfficiencyMin,
              this.chillerEfficiencyMax,
              'chiller',
              this.responsiveStylingObj.leftTopChart.chart.width,
              this.responsiveStylingObj.leftTopChart.chart.height
            )
          );
      }

      document.getElementById('right-side-content').appendChild(this.chilledAndCondenserWaterWidget(this.selectedEquipData, this.selected.startDate.format('YYYY-MM-DD'), this.selected.endDate.format('YYYY-MM-DD')));
    } else if (this.selectedEquipData.class == "steamBoiler" || this.selectedEquipData.class == "condenserBoiler") {
      document.getElementById('right-side-content').appendChild(this.boilerWidget(this.selectedEquipData, this.selected.startDate.format('YYYY-MM-DD'), this.selected.endDate.format('YYYY-MM-DD')));
    }
  }


  clickViewDataModification(response, reload, energyConsumed){
    if (!response?.error) {
      if (this.selectedEquipData?.childData?.tags) {
        this.isEquipChilledOrHotOrCondenser = this.checkEquipType(this.selectedEquipData.childData.tags);
      }
      // Writable Response.
      let writableResponse;
      if (response?.writablePointsData?.rows && response?.hisPointsData?.rows) {
        writableResponse = JSON.parse(JSON.stringify(response.writablePointsData.rows));
        this.clickPointList = JSON.parse(JSON.stringify(response?.hisPointsData?.rows));
      }
      if (response?.writablePointsData?.rows?.length) {
        response?.writablePointsData?.rows.forEach(writableObj => {
          let findWritablePoint = this.clickPointList.find(pointObj => pointObj.id === writableObj.id);
          if (findWritablePoint == undefined) {
            this.clickPointList.push(writableObj);
          }
        });
      }
      this.clickPointList.map(point => {
        point = this.helperMethodsService.formatEnums(point);
        let tags = Object.keys(point);
        if (writableResponse?.length) {
          const found = writableResponse.find(_writableResPoint => ((point.id == _writableResPoint.id) && _writableResPoint?.data?.length));
          if (found) {
            point['writableData'] = found.data;
          }else {
            point['writableData'] = [{
              "level": "",
              "val": "",
              "duration": "",
              "who": "",
              "lastModifiedDateTime": ""
          }];
          }
        }
        const displayName = point?.shortDis ? point?.shortDis : point?.dis;
        point['pointName'] = this.addEllipsis(displayName, 25);
        point['toolTipName'] = ObjectUtil.deepCopy(displayName);

        let energyConsumedPoint = [];
        if (energyConsumed?.length) {
          energyConsumedPoint = energyConsumed.filter(energyObj => energyObj.pointId == point.id);
        }
        if (energyConsumedPoint?.length) {
          if (energyConsumedPoint[0]?.energyConsumedValue.includes(point.unit)) {
            energyConsumedPoint[0].energyConsumedValue = energyConsumedPoint[0]?.energyConsumedValue.replace(point.unit, "");
          }
          point['pointValue'] = `${energyConsumedPoint[0]?.energyConsumedValue != 'NA' ?  Math.round(Number(energyConsumedPoint[0]?.energyConsumedValue) * 100) / 100 : ''} ${ energyConsumedPoint[0]?.energyConsumedValue != 'NA'? point?.unit : ''}`
        } else {
          if (typeof (point?.data[point?.data.length - 1])?.val === 'boolean') {
            point['pointValue'] = (point?.data[point?.data.length - 1])?.val;
          } else {
            point['pointValue'] = (point?.data[point?.data.length - 1])?.val?.split(' ')[0] ? Math.round(Number((point?.data[point?.data.length - 1])?.val?.split(' ')[0]) * 100) / 100 : 'NA';

          }
        }
        if (point?.enum?.length > 1 && point?.data?.length) {
          let checkData = point['writableData']?.length ? Number(point['writableData'][0].val.split(' ')[0]) : Number(point['pointValue']);
          const found = point?.enum?.find(_r => Number(_r.value) == checkData);
          if (found) {
            point['pointValue'] = found.name;
          }
        }
        point['tags'] = tags;
        //Added Enum to dropdown options
        if (point?.enum != undefined) {
          point.options = [];
          point?.enum.forEach(enumData => {
            //converting number to stirng 
            enumData.value = `${enumData.value}`;
            point.options.push(enumData);
          });
        }
      });
      this.selectedEquipData.data.point = this.clickPointList;
      this.tempClickPointList = ObjectUtil.deepClone(this.clickPointList);
      this.setPredefinedParametersData();
      setTimeout(async () => {
        this.setResponsiveStyling();
        this.assignImageUrl(this.selectedEquipData);
        this.generateLeftAndRightContent();
        await this.initiateData();
        this.isLoading = false;
      }, 0);

      if (reload) {
        this.reLoadChartsOnDateSelection();
      }
    }
  }

  getClickData(node, reload = false) {
    this.isLoading = true;
    let body = {
      "statusType": "PUBLISHED",
      "isTrend": "false",
      "viewType": "CLICK",
      "startDate": this.formatStartDate,
      "endDate": this.formatEndDate,
    }
    const arrayList = ["pump1Vertical_forward", "pump1Vertical_backward"];
    let equipId = arrayList.includes(node.class) ? node.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : node.equipId;

    if (this.loginUserTypeData.isPublic) {
      let masterViewAPI = this.apiService.getSharedMasterData(this.loginUserTypeData.linkShareUuid, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
      let energyConsumedAPI =  this.apiService.getsharedEnergyConsumed(this.loginUserTypeData.linkShareUuid, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));

      forkJoin([masterViewAPI,energyConsumedAPI]).subscribe({
        next: (response: any) => {
          if (response[1]?.length) {
            this.energyConsumedData = response[1];
          }
          //update object with writable and read point data
          this.clickViewDataModification(response[0], reload, response[1]);
        }, error: () => {
          this.isLoading = false;
          this.showToast('error', 'Something went wrong.');
        }
      });
    } else if (!this.loginUserTypeData.isPublic) {
      let masterViewAPI = this.apiService.getMasterPoint(this.data.plantId, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
      let energyConsumedAPI = this.apiService.getEnergyConsumed(this.data.plantId, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));

      forkJoin([masterViewAPI,energyConsumedAPI]).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          if (response[1]?.length) {
            this.energyConsumedData = response[1];
          }
          this.clickViewDataModification(response[0], reload, response[1]);
        }, error: () => {
          this.isLoading = false;
          this.showToast('error', 'Something went wrong.');
        }
      });
    }
  }

  // removePointsFromList() {
  //   const commandTags = ['run', 'cmd'].push(this.isEquipChilledOrHotOrCondenser);
  //   const enableTags = ['enable'].push(this.isEquipChilledOrHotOrCondenser);
  //   const setPoint 
  //   this.clickPointList.map(_point => {
  //     return 
  //   })
  // }

  setDateRange(event: any) {
    if (event.startDate !== null) {
      // Clear subs for time series so we can go back to that date

      const offset = dayjs().utcOffset();
      const centralOffset = dayjs().utcOffset();
      let startDate = event.startDate.toDate().toString();
      startDate = startDate.substring(0, startDate.lastIndexOf(' ')).trim();
      this.selected.startDate = dayjs(new Date(startDate)).startOf('day').add(-(offset - centralOffset), 'minutes');
      let endDate = event.endDate.toDate().toString();
      endDate = endDate.substring(0, endDate.lastIndexOf(' '));

      // Check if end date is before today's date
      if (dayjs(new Date(event.endDate)).endOf('day').isBefore(dayjs(Date.now()))) {
        this.selected.endDate = dayjs(new Date(endDate)).endOf('day').add(-(offset - centralOffset), 'minutes');
      } else if (new Date(event.endDate).toDateString() == new Date(Date.now()).toDateString()) {
        this.selected.endDate = dayjs(Date.now());
      } else if (dayjs(new Date(event.endDate)).endOf('day').isAfter(dayjs(Date.now()))) {
        this.selected.endDate = dayjs(Date.now());
      } else {
        this.selected.endDate = dayjs(new Date(endDate)).endOf('day').add(-(offset - centralOffset), 'minutes');
      }
      this.formatEndDate = this.selected.endDate.format('YYYY-MM-DD');
      this.formatStartDate = this.selected.startDate.format('YYYY-MM-DD');
      const barChartContent = document.getElementById("bar-Chart-content");

      if (barChartContent.hasChildNodes()) {
        barChartContent.removeChild(barChartContent.children[0]);
      }

      document.getElementsByClassName('bulletChartContainer')[0]?.remove();
      document.getElementById("ChillerParentContainer")?.remove();
      document.getElementById("boilerParentContainer")?.remove();

      this.getClickData(this.selectedEquipData, true);

    }
  }

  reLoadChartsOnDateSelection() {
    this.showChillerLoadChart = false;
    this.showAlertChart = false;
    this.showStatusChart = false;
    this.showEnergyConsumptionChart = false;
    this.showbuildingSchChart = false;
    // document.getElementById('center-equip-img')?.remove();
    document.getElementById('circularBarPlotChart')?.remove();
    document.getElementById('buildingSchChart')?.remove();
    document.getElementById('chillerStatusPoint')?.remove();
    document.getElementById('alertsChart')?.remove();
    document.getElementById('chillerLoadChart')?.remove();
    document.getElementsByClassName('tooltip-circular-widget') && document.getElementsByClassName('tooltip-circular-widget')[0]?.remove();
  }

// Set Click view Image based on the Equip Status 
  assignImageUrl(selectedEquipData) {
    let selectedEquipName = selectedEquipData?.class;
    this.equipImageDetails = {};
    this.equipImageDetails['imgPositionWidth'] = 100;
    this.equipImageDetails['imgPositionHeight'] = 100;
    if (selectedEquipName == "coolingTowerSingleFan") {
      this.equipImageDetails['imgPositionWidth'] = 115;
    } else if (selectedEquipName == "coolingTowerTwoFan") {
      this.equipImageDetails['imgPositionWidth'] = 70;
      this.equipImageDetails['imgPositionHeight'] = 100;
    } else if (selectedEquipName == "coolingTowerFourFan") {
      this.equipImageDetails['imgPositionWidth'] = 90;
      this.equipImageDetails['imgPositionHeight'] = 90;
    } else if (selectedEquipName == "condenserBoiler") {
      this.equipImageDetails['imgPositionWidth'] = 115;
      this.equipImageDetails['imgPositionHeight'] = 90;
    } else if (selectedEquipName == "steamBoiler") {
      this.equipImageDetails['imgPositionWidth'] = 100;
      this.equipImageDetails['imgPositionHeight'] = 90;
    } else if (selectedEquipName.includes("pump1Vertical_forward") || selectedEquipName.includes("pump1Vertical_backward")) {
      this.equipImageDetails['imgPositionWidth'] = 110;
      this.equipImageDetails['imgPositionHeight'] = 90;
    }
    this.equipImageDetails['url'] = selectedEquipData.equipStatus == 'equipOn' ? `assets/images/GIF/${selectedEquipName}.gif` : `assets/images/GIF/${selectedEquipData.class}_Cutaway.png`;
  }

  boilerWidget(dataNode, startDate, endDate) {
    const chartWidth = this.responsiveStylingObj.rightChart.bulletChart.width;
    const chartHeight = this.responsiveStylingObj.rightChart.bulletChart.height;
    const areaChart = this.responsiveStylingObj.rightChart.areaChart.width;
    const areaHeight = this.responsiveStylingObj.rightChart.areaChart.height;
    const boilerParentContainer = document.createElement('div');
    boilerParentContainer.setAttribute('id', 'boilerParentContainer');
    boilerParentContainer.classList.add('boilerParentContainer');
    const boilerContainer = document.createElement('div');
    boilerContainer.setAttribute('id', 'boilerContainer');
    document.getElementById('bulletChartWidget-' + 'boiler' + '-true')?.remove();
    document.getElementById('areaChart-boiler')?.remove();

    const boilerBulletContainer = document.createElement('div');
    boilerBulletContainer.setAttribute('id', 'boilerBulletContainer');

    let isEntering: any = this.getChillerPointsObj(dataNode, "boiler", "entering");
    let isLeaving: any = this.getChillerPointsObj(dataNode, "boiler", "leaving");

    if ((Object.keys(isEntering).length != 0 && isEntering.data.length) || Object.keys(isLeaving).length != 0 && isLeaving.data.length) {
      let plantData = this.getPlantData(this.plantId)
      boilerBulletContainer.appendChild(generateBulletChart(dataNode, 'boiler', this.data, this.cpmService, this.cpmService, this.dialog, this.dom, this.loginUserTypeData, chartWidth, chartHeight, true));
      boilerContainer.appendChild(boilerBulletContainer);
    }

    if ((Object.keys(isEntering).length != 0 && isEntering.data.length) || Object.keys(isLeaving).length != 0 && isLeaving.data.length) {
      let unit = Object.keys(isEntering).length != 0 ? isEntering.unit: Object.keys(isLeaving).length != 0 ? isLeaving.unit: '';
      boilerContainer.appendChild(generateAreaChart('boiler', startDate, endDate, isEntering.data, isLeaving.data, areaChart, areaHeight, '',unit));
    }

    boilerParentContainer.appendChild(boilerContainer);
    return boilerParentContainer;
  }

  chilledAndCondenserWaterWidget(dataNode, startDate, endDate) {
    const chartWidth = this.responsiveStylingObj.rightChart.bulletChart.width;
    const chartHeight = this.responsiveStylingObj.rightChart.bulletChart.height;
    const areaChart = this.responsiveStylingObj.rightChart.areaChart.width;
    const areaHeight = this.responsiveStylingObj.rightChart.areaChart.height;
    const parentContainer = document.createElement('div');
    parentContainer.setAttribute('id', 'ChillerParentContainer');
    parentContainer.classList.add('bulletChartContainer');
    // chilled water widget
    const subParent1 = document.createElement('div');
    subParent1.setAttribute('id', 'subParent1');
    const chilledWaterContainer = document.createElement('div');
    chilledWaterContainer.setAttribute('id', 'chilledWaterContainer');
    document
      .getElementById('bulletChartWidget-' + 'evaporator' + '-true')
      ?.remove();
    document.getElementById('areaChart-chilledWater')?.remove();
    let isEntering: any = this.getChillerPointsObj(dataNode, "evaporator", "entering");
    let isLeaving: any = this.getChillerPointsObj(dataNode, "evaporator", "leaving");
    if ((Object.keys(isEntering).length != 0 && isEntering.data.length) || Object.keys(isLeaving).length != 0 && isLeaving.data.length) {
      chilledWaterContainer.appendChild(
        generateBulletChart(dataNode, 'evaporator', this.data, this.cpmService, this.cpmService, this.dialog, this.dom,  this.loginUserTypeData, chartWidth, chartHeight, true)
      );
    }
    subParent1.appendChild(chilledWaterContainer);
    if ((Object.keys(isEntering).length != 0 && isEntering.data.length) || Object.keys(isLeaving).length != 0 && isLeaving.data.length) {
      let unit = Object.keys(isEntering).length != 0 ? isEntering.unit: Object.keys(isLeaving).length != 0 ? isLeaving.unit: '';

      subParent1.appendChild(
        generateAreaChart('chilledWater', startDate, endDate, isEntering.data, isLeaving.data,areaChart, areaHeight, '',unit)
      );
    }

    parentContainer.appendChild(subParent1);

    // condenser water widget
    const subParent2 = document.createElement('div');
    subParent2.setAttribute('id', 'subParent2');

    const condenserWaterContainer = document.createElement('div');
    condenserWaterContainer.setAttribute('id', 'condenserWaterContainer');
    document
      .getElementById('bulletChartWidget-' + 'condenser' + '-true')
      ?.remove();
    document.getElementById('areaChart-condenserWater')?.remove();
    if (this.checkChillerWidget(dataNode, "condenser")) {
      condenserWaterContainer.appendChild(
        generateBulletChart(dataNode, 'condenser', this.data, this.cpmService, this.cpmService, this.dialog, this.dom, this.loginUserTypeData, chartWidth, chartHeight, true)
      );
    }
    subParent2.appendChild(condenserWaterContainer);
    let isEnteringCondenser: any = this.getChillerPointsObj(dataNode, "condenser", "entering");
    let isLeavingCondenser: any = this.getChillerPointsObj(dataNode, "condenser", "leaving");
    if ((Object.keys(isEnteringCondenser).length != 0 && isEnteringCondenser.data.length) || Object.keys(isLeavingCondenser).length != 0 && isLeavingCondenser.data.length) {
      let unit = Object.keys(isEnteringCondenser).length != 0 ? isEnteringCondenser.unit: Object.keys(isLeavingCondenser).length != 0 ? isLeavingCondenser.unit: '';
      subParent2.appendChild(
        generateAreaChart('condenserWater', startDate, endDate, isEnteringCondenser.data, isLeavingCondenser.data, areaChart, areaHeight, '',unit)
      );
    }
    parentContainer.appendChild(subParent2);
    return parentContainer;
  }

  /**
   * Handles the change event of the view dropdown.
   * Removes the efficiency chart on equipment change.
   * Updates the selected equipment data and assigns the image URL.
   * Removes the efficiency chart and sets the selected equipment data.
   * Updates the predefined parameters based on the equipment class.
   * Removes the ChillerParentContainer element.
   * Appends the chilled and condenser water widget if specific click points are present.
   * Removes the first child of the bar-Chart-content element.
   * Sets showchillerEfficiency to false.
   * Removes the boilerParentContainer element.
   * Appends the boiler widget if specific click points are present.
   * Reloads the charts on date selection.
   * Gets the click data for the selected equipment.
   * 
   * @param event - The change event object.
   */
  onClickViewDropdownChange(event) {
    // On Equip change removing the efficiency - chart .
    document.getElementById('center-equip-img')?.remove();
    this.selectedEquipData = this.data.plantJsonStr.drawflow.Home.data[event];
    this.assignImageUrl(this.selectedEquipData);

    document.getElementById('efficiency-chart')?.remove();
    this.setSelectedEquipData();

    let equipClass = this.selectedEquipData.class;
    if (equipClass.includes('pump1Vertical_forward') || equipClass.includes('pump1Vertical_backward')) {
      equipClass = 'pump1Vertical';
    }
    this.predefinedParameters = predefinedParametersObj[equipClass];
    document.getElementById("ChillerParentContainer")?.remove();
    if (this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "evaporator") || this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "condenser")) {
      document.getElementById('right-side-content').appendChild(this.chilledAndCondenserWaterWidget(this.selectedEquipData, this.selected.startDate.format('YYYY-MM-DD'), this.selected.endDate.format('YYYY-MM-DD')));
    }

    const barChartContent = document.getElementById("bar-Chart-content");
    if (barChartContent.hasChildNodes()) {
      barChartContent.removeChild(barChartContent.children[0]);
    }

    this.showchillerEfficiency = false;

    document.getElementById("boilerParentContainer")?.remove();
    if (this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "boiler")) {
      document.getElementById('right-side-content').appendChild(this.boilerWidget(this.selectedEquipData, this.selected.startDate.format('YYYY-MM-DD'), this.selected.endDate.format('YYYY-MM-DD')));
    }
    this.reLoadChartsOnDateSelection();
    this.getClickData(this.selectedEquipData, true);
  }

  checkForSpecificClickPoints(points, type, equipClass?) {
    let arr = this.pointsToCheckArray[type][equipClass];
    let res = undefined;
    if (arr?.length && points?.length) {
      points?.forEach(pointObj => {
        if (arr.every(tag => pointObj.tags.includes(tag))) {
          res = pointObj;
        }
      });
    }
    return res;
  }


  getChillerEfficiency(data) {
    let efficiencyData: any = {}
    if (data?.data?.point?.length) {
      data?.data?.point?.forEach(pointData => {
        if (predefinedParametersObj[data.class]?.efficiency && predefinedParametersObj[data.class].efficiency.every(value => pointData.tags.includes(value))) {
          if (pointData?.data?.length) {
            efficiencyData = pointData;
          }
        }
      });
    }
    return efficiencyData;
  }

  checkChillerWidget(data, type) {
    let checkLeavePoint: boolean, checkEnterPoint: boolean, checkSetPoint: boolean = false;
    if (predefinedParametersObj[data.class] && predefinedParametersObj[data.class][type]) {
      let leavePointTags = predefinedParametersObj[data.class][type].leaving;
      let enterPointTags = predefinedParametersObj[data.class][type].entering;
      let checkSetPointTags = predefinedParametersObj[data.class][type].setPoint;
      if (data?.data?.point?.length) {
        data?.data?.point.forEach(pointData => {
          if (leavePointTags.every(value => pointData.tags.includes(value))) {
            if (pointData?.data?.length) {
              checkLeavePoint = true;
            }
          }

          if (enterPointTags.every(value => pointData.tags.includes(value))) {
            if (pointData?.data?.length) {
              checkEnterPoint = true;
            }
          }

          if (checkSetPointTags.every(value => pointData.tags.includes(value))) {
            if (pointData?.data?.length) {
              checkSetPoint = true;
            }
          }
        });
      }
    }


    if (!checkLeavePoint && !checkEnterPoint) {
      return false;
    } else if (checkLeavePoint || checkEnterPoint) {
      return true;
    }

  }

  getChillerPointsObj(data, type, point) {
    let pointObj = {};
    if (data?.data?.point?.length) {
      data?.data?.point.forEach(pointData => {
        if (predefinedParametersObj[data.class] && predefinedParametersObj[data.class][type] && predefinedParametersObj[data.class][type][point] && predefinedParametersObj[data.class][type][point].every(value => pointData.tags.includes(value))) {
          pointObj = pointData;
        }
      })
    }
    return pointObj;
  }

  findSpecificClickPoints(points, type, key) {
    let str = ""
    if (key == "entering") {
      str = this.pointsToCheckArray[type][0];
    } else if (key == "leaving") {
      str = this.pointsToCheckArray[type][1];
    }
    let filteredArray = points.filter(p => p.dis.includes(str))
    return filteredArray[0].click;
  }

  addEllipsis(text, textLimit) {
    if (text?.length) {
      return (text.length > textLimit) ? text.slice(0, textLimit - 1) + '...' : text;
    }
    return '';
  }

  getPlantData(plantId) {
    return this.data.plantData;
  }

  isCoolingTowerEnablePointSelected() {
    let foundEquipStatus = undefined;
    this.selectedEquipData.data.point.forEach(_point => {
      if (this.isPointSelected(_point, 'enable')) {
        foundEquipStatus = _point;
      }
    });
    return foundEquipStatus;
  }

  setPredefinedParametersData() {
    if (this.selectedEquipData.class != 'coolingTowerTwoFan' && this.selectedEquipData.class != 'coolingTowerFourFan' && this.selectedEquipData.class != 'coolingTowerSingleFan') {
      let foundEquipCommand = undefined;
      this.equipCommand = '';
      this.selectedEquipData.data.point.forEach(_point => {
        if (this.isPointSelected(_point, 'command')) {
          foundEquipCommand = _point;
        }
      });
      if (foundEquipCommand && this.predefinedParameters?.command) {
        this.predefinedParameters.command.pointList[0]['point'] = foundEquipCommand;
        if (foundEquipCommand.writableData?.length) {
          this.predefinedParameters.command.pointList[0].pointValue = foundEquipCommand.writableData[0].val;
          this.predefinedParameters.command.pointValue = foundEquipCommand.writableData[0].val;
        } else {
          this.predefinedParameters.command.pointValue = '0';
        }
      }
      let foundEquipEnable = undefined;
      this.clickPointList.forEach(_point => {
        if (this.isPointSelected(_point, 'enable')) {
          foundEquipEnable = _point;
        }
      });
      if (foundEquipEnable && this.predefinedParameters?.enable) {
        this.equipRunningStates = foundEquipEnable.enum;
        this.predefinedParameters.enable.pointList[0]['point'] = foundEquipEnable;
        if (foundEquipEnable?.writableData?.length) {
          this.equipState = foundEquipEnable.writableData[0].val;
          this.predefinedParameters.enable.pointList[0].pointValue = foundEquipEnable.writableData[0].val;
        } else {
          this.equipState = '0';
        }
      }

      let foundEquipRunhours = undefined;
      this.selectedEquipData?.data?.point.forEach(_point => {
        if (this.isPointSelected(_point, 'runHours')) {
          foundEquipRunhours = _point;
        }
      });
      if (foundEquipRunhours) {
        if (foundEquipRunhours?.data?.length) {
          const pointValue = foundEquipRunhours.data[foundEquipRunhours.data.length - 1].val.split(' ');
          this.runHoursParameterVal = `${pointValue[0].toString().match(/^\d+(?:\.\d{0,1})?/)} ${pointValue[1]}`;
        } else {
          this.runHoursParameterVal = 'NA';
        }
      }

      let foundEquipPower = undefined;
      this.selectedEquipData?.data?.point.forEach(_point => {
        if (this.isPointSelected(_point, 'power')) {
          foundEquipPower = _point;
        }
      });
      if (foundEquipPower) {
        if (foundEquipPower?.data?.length) {
          const pointValue = foundEquipPower.data[foundEquipPower.data.length - 1].val.split(' ');
          this.powerParameterVal = `${pointValue[0].toString().match(/^\d+(?:\.\d{0,1})?/)} ${pointValue[1]}`;
        } else {
          this.powerParameterVal = 'NA';
        }
      }

      let foundEquipEnergyConsumed = undefined;
      this.selectedEquipData?.data?.point.forEach(_point => {
        if (this.isPointSelected(_point, 'energy')) {
          foundEquipEnergyConsumed = _point;
        }
      });
      if (foundEquipEnergyConsumed) {
        if (foundEquipEnergyConsumed?.pointValue?.length) {
          this.energyConsumedParameterVal = `${foundEquipEnergyConsumed?.pointValue != undefined && foundEquipEnergyConsumed?.pointValue != " "  ? foundEquipEnergyConsumed?.pointValue : 'NA'}`;
        } else {
          this.energyConsumedParameterVal = 'NA';
        }
      }

    } else {
      this.checkCoolingTowerPredefinedPointsSelected();
      this.checkCT2AndCT4PredefinedPointsSelected();
    }
  }

  isPointSelected(_point, pointName) {
    let equipClass = this.selectedEquipData.class;
    if (equipClass.includes('pump1Vertical_forward') || equipClass.includes('pump1Vertical_backward')) {
      equipClass = 'pump1Vertical';
    }
    return predefinedParametersObj[equipClass][pointName].tags.every(_tag => _point.tags.indexOf(_tag) > -1);
  }

  getEnergyConsumedData(pointData){
    let body = {
      "statusType": "PUBLISHED",
      "isTrend": "false",
      "viewType": "CLICK",
      "startDate": this.formatStartDate,
      "endDate": this.formatEndDate,
    }

    this.apiService.getEnergyConsumed(this.data.plantId, pointData.equipRef, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)).subscribe((res) => {
      return pointData.energyConsumedValue = res[0]?.energyConsumedValue;
    });
  }

  onWritablePointsDropdownChange(event, point, from) {
      this.forceOverrideData(point.id, point.writableData, event['value'], point, from);
  }

  setPriorityData(id, priorityArray, val) {
    return {
      ref: id,
      level: String(priorityArray.level),
      who: `web_${this.getUserName()}`,
      duration: '0ms',
      val: val
    };
  }

  getUserName() {
    if (Object.keys(this.data.userInfo)?.length) {
      return this.data.userInfo['firstName'] + ' ' + this.data.userInfo['lastName'];
    } else {
      return null;
    }
  }

  forceOverrideData(id, prevData: any, val, point, from) {
    const overRideArrayLevel = [];
    let overrideLevel8: any = {};
    overrideLevel8 = this.setPriorityData(id, { level: '10' }, val);
    overRideArrayLevel.push(overrideLevel8);
    // If priority array data is empty then setting the val.
    prevData?.map(priorityArray => {
      // If the priority level is less than 10 returning the val as 'N'
      if (priorityArray.level && priorityArray.level < 10) {
        overrideLevel8 = this.setPriorityData(id, priorityArray, 'N');
        overRideArrayLevel.push(overrideLevel8);
      }
    });
    this.writeDataToHaystack(overRideArrayLevel, '', prevData).pipe(
      map(this.cpmService.stripHaystackTypeMapping)).subscribe(
        (res: any) => {
          // update point value based on the point type
          if (from == 'predefined') {
            point.writableData = res.rows;
          } else if (from == 'custom') {
            point.writableData = res?.rows[0]?.data;
          }
        });
  }

  writeDataToHaystack(data: any, reason, prevData) {
    return this.cpmService.updateBulkWritablePointData(data, reason)
      .pipe(takeUntil(this.unsubscribe))

  }

  appendImage() {
    let width = 240;
    let height = 240;
    if (this.selectedEquipData?.class == 'coolingTowerTwoFan' || this.selectedEquipData?.class == 'coolingTowerFourFan') {
      width = 270;
      height = 270;
    }
    d3.select("#graphId-chart")
      .append("svg:svg")
      .attr("id", 'center-equip-img')
      .attr("class", "chart")
      .attr("width", 400)
      .attr("height", 400).append('g')
      .attr("transform", "translate(" + this.equipImageDetails['imgPositionWidth'] + "," + this.equipImageDetails['imgPositionHeight'] + ")") // Add 100 on Y translation, cause upper bars are longer.
      .append('image').attr("xlink:href", this.equipImageDetails['url']).attr('class', 'airCooledChiller').attr('width', width).attr('height', height);
  }

  initiateData() {
    // Initializing circular graph.
    const graphDiv = document.createElement("div");
    graphDiv.setAttribute("id", "circular-chart");
    const tooltip = document.createElement('div');
    tooltip.classList.add('tooltip-circular-widget');
    let start = dayjs(dayjs(this.formatStartDate).format("YYYY-MM-DD"));
    let end = dayjs(dayjs(this.formatEndDate).format("YYYY-MM-DD"));
    let today = dayjs(dayjs().format("YYYY-MM-DD"));
    const data = {days: dayjs(end).diff(start, "day"), today: today, end, start};
    emptyChartData();
    const chartWidth = this.responsiveStylingObj.circularWidget.chart.width;
    const chartHeight = this.responsiveStylingObj.circularWidget.chart.height;
    const svgWidth = this.responsiveStylingObj.circularWidget.circle.svgWidth;
    const svgHeight = this.responsiveStylingObj.circularWidget.circle.svgHeight;
    const chartRadius = ObjectUtil.deepClone(this.responsiveStylingObj.circularWidget.circle.radius);
    // Energy Consumption Chart.
    let foundEnergyConsumption = this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "energyConsumption", this.selectedEquipData.class);
    if (this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan') {
      foundEnergyConsumption = this.coolingTower2And4FanEnergyChartData(this.selectedEquipData.data.point, "energyConsumption", this.selectedEquipData.class);
    }
    if (foundEnergyConsumption && foundEnergyConsumption?.data?.length) {
      this.showEnergyConsumptionChart = true;
      generateCircularBarPlotChart(tooltip, this.selectedEquipData.class, foundEnergyConsumption, data, chartWidth, chartHeight, svgWidth, svgHeight, chartRadius);
    }

    if(this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan' || this.selectedEquipData.class == 'coolingTowerSingleFan'){
      let coolingTowerSumpData = this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "coolingTowerSump", this.selectedEquipData.class);
      if (coolingTowerSumpData != undefined && Object.keys(coolingTowerSumpData).length && coolingTowerSumpData?.data.length) {
        let allPointArr = [];
        coolingTowerSumpData.data.forEach(point => {
          const pointVal = point.val ? Number(point.val?.split(" ")[0]).toFixed(2) : null;
          allPointArr.push(pointVal);
        });

        this.chillerEfficiencyMin = Math.min.apply(Math, allPointArr);
        this.chillerEfficiencyMax = Math.max.apply(Math, allPointArr);
        this.showchillerEfficiency = true;
        document
          .getElementById('bar-Chart-content')
          .appendChild(
            generateBarChart(
              this.selected.startDate.format('YYYY-MM-DD'),
              this.selected.endDate.format('YYYY-MM-DD'),
              coolingTowerSumpData,
              this.chillerEfficiencyMin,
              this.chillerEfficiencyMax,
              'CT',
              this.responsiveStylingObj.leftTopChart.chart.width,
              this.responsiveStylingObj.leftTopChart.chart.height
            )
          );
      }
    }

    // // Chiller Status Chart.
    let foundStatusData = this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "status", this.selectedEquipData.class);
    if (this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan') {
      foundStatusData = this.formatCoolingTower2And4fanStatusData(this.selectedEquipData.data.point, "status", this.selectedEquipData.class);
    }
    if (foundStatusData && foundStatusData?.data?.length) {
      this.showStatusChart = true;
      const statusPointChart = { width: chartWidth, height: chartHeight, svgWidth: svgWidth, svgHeight: svgHeight, id: '#graphId-chart', innerRadius: chartRadius - 8, outerRadius: chartRadius - 5, color: '#12D681' };
      generateArcChart(statusPointChart, 'chillerStatusPoint', tooltip, foundStatusData.data, this.selectedEquipData.class, data);
    }
    // // Alerts Chart.
    let foundAlertsData = this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "alert", this.selectedEquipData.class);
    if (this.selectedEquipData.class == 'coolingTowerFourFan' || this.selectedEquipData.class == 'coolingTowerTwoFan') {
      foundAlertsData = this.coolingTower2And4FanAlertChartData(this.selectedEquipData.data.point, "alert", this.selectedEquipData.class);
    }
    if (foundAlertsData && foundAlertsData?.data?.length) {
      this.showAlertChart = true;
      const alertsChart = { width: chartWidth, height: chartHeight, svgWidth: svgWidth, svgHeight: svgHeight, id: '#graphId-chart', innerRadius: chartRadius - 13, outerRadius: chartRadius - 10 };
      generateAlertsArcChart(alertsChart, tooltip, this.selectedEquipData.class, foundAlertsData, data);
    }
    // // Chiller Load Chart.
    const foundChillerData = this.checkForSpecificClickPoints(this.selectedEquipData.data.point, "chillerLoad", this.selectedEquipData.class);
    if (foundChillerData && foundChillerData.data.length) {
      this.exportData.chillerLoadData = foundChillerData;
      this.showChillerLoadChart = true;
      const chillerLoadChart = { width: chartWidth, height: chartHeight, svgWidth: svgWidth, svgHeight: svgHeight, id: '#graphId-chart', innerRadius: chartRadius - 18, outerRadius: chartRadius - 15 };
      generateChillerLoadChart(chillerLoadChart, tooltip, this.selectedEquipData.class, foundChillerData, data);
    }
    document.getElementById('graph-wrapper').appendChild(tooltip);
    this.setCircleWidthAndHeight();
    this.calculateDatePostions();
    this.generateChartDates(this.formatStartDate, this.formatEndDate);
    this.calculatePositionOnPoints(tooltip);
  }

  checkClickPointSelected(dataNode) {
    dataNode = dataNode.point.concat(dataNode.customPoint);
    if (dataNode.some((data) => data.click === true)) {
      return true
    } else {
      return false;
    }
  }

  coolingTower2And4FanEnergyChartData(points, type, equipClass?) {
    let arr = this.pointsToCheckArray[type][equipClass];
    const count = this.selectedEquipData?.class == 'coolingTowerFourFan' ? 4: 2;
    let res = [];
    if (arr && arr?.length && points?.length) {
      points?.forEach(pointObj => {
        if (arr.every(tag => pointObj.tags.includes(tag))) {
          if (pointObj.data?.length) {
            res.push(pointObj);
          }
        }
      });
    }
    const formatedData: any = {data: []};
    if (res && res?.length) {
      res[0].data.map(_data => _data.ts).forEach(_ts => {
        if (count == 2) {
          const found1 = res[0] && res[0].data.length ? res[0].data.find(_d => _d.ts == _ts)?.val: undefined;
          const found2 = res[1] && res[1].data.length ? res[1].data.find(_d => _d.ts == _ts)?.val: undefined;
          const appendArr = [found1, found2].filter(_a => _a);
          let val = null;
          let unit = '';
          appendArr.forEach(_val => {
            val += Number(_val.split(' ')[0]);
            unit = _val.split(' ')[1];
          });
          formatedData['data'].push({ts: _ts, val: `${val} ${unit}`});
        } else if (count == 4) {
          const found1 = res[0] && res[0].data.length ? res[0].data.find(_d => _d.ts == _ts)?.val: undefined;
          const found2 = res[1] && res[1].data.length ? res[1].data.find(_d => _d.ts == _ts)?.val: undefined;
          const found3 = res[2] && res[2].data.length ? res[2].data.find(_d => _d.ts == _ts)?.val: undefined;
          const found4 = res[3] && res[3].data.length ? res[3].data.find(_d => _d.ts == _ts)?.val: undefined;
          let appendArr = [found1, found2, found3, found4].filter(_a => _a);
          let val = null;
          let unit = '';
          appendArr.forEach(_val => {
            val += Number(_val.split(' ')[0]);
            unit = _val.split(' ')[1];
          });
          formatedData['data'].push({ts: _ts, val: `${val} ${unit}`});
        }
      });
    }
    return formatedData;
  }

  coolingTower2And4FanAlertChartData(points, type, equipClass?) {
    let arr = this.pointsToCheckArray[type][equipClass];
    const count = this.selectedEquipData.class == 'coolingTowerFourFan' ? 4: 2;
    let res = [];
    if (arr && arr?.length && points?.length) {
      points?.forEach(pointObj => {
        if (arr.every(tag => pointObj.tags.includes(tag))) {
          if (pointObj.data?.length) {
            res.push(pointObj);
          }
        }
      });
    }
    const formatedData: any = {data: []};
    if (res && res?.length) {
      res[0].data.map(_data => _data.ts).forEach(_ts => {
        
          if (count == 2) {
            const found1 = res[0] && res[0].data.length ? res[0].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found2 = res[1] && res[1].data.length ? res[1].data.find(_d => _d.ts == _ts)?.val: undefined;
            let val = [found1, found2].filter(_a => _a);
            const maxVal = Math.max(...val);
            formatedData['data'].push({ts: _ts, val: maxVal});
          } else if (count == 4) {
            const found1 = res[0] && res[0].data.length ? res[0].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found2 = res[1] && res[1].data.length ? res[1].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found3 = res[2] && res[2].data.length ? res[2].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found4 = res[3] && res[3].data.length ? res[3].data.find(_d => _d.ts == _ts)?.val: undefined;
            let val = [found1, found2, found3, found4].filter(_a => _a);
            const maxVal = Math.max(...val);
            formatedData['data'].push({ts: _ts, val: maxVal});
          }
      });
    }
    return formatedData;
  }

  formatCoolingTower2And4fanStatusData(points, type, equipClass?) {
    let arr = this.pointsToCheckArray[type][equipClass];
    const count = this.selectedEquipData?.class == 'coolingTowerFourFan' ? 4: 2;
    let res = [];
    if (arr && arr?.length && points?.length) {
      points?.forEach(pointObj => {
        if (arr.every(tag => pointObj.tags.includes(tag))) {
          if (pointObj.data?.length) {
            res.push(pointObj);
          }
        }
      });
    }
    const formatedData: any = {data: []};
    if (res && res?.length) {
      res[0].data.map(_data => _data.ts).forEach(_ts => {
        
          if (count == 2) {
            const found1 = res[0] && res[0].data.length ? res[0].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found2 = res[1] && res[1].data.length ? res[1].data.find(_d => _d.ts == _ts)?.val: undefined;
            let val = found1;
            if (found1 > 0) {
              val = found1;
            } else if(found2 > 0) {
              val = found2;
            } else {
              val = found1;
            }
            formatedData.data.push({ts: _ts, val: val});
          } else if (count == 4) {
            const found1 = res[0] && res[0].data.length ? res[0].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found2 = res[1] && res[1].data.length ? res[1].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found3 = res[2] && res[2].data.length ? res[2].data.find(_d => _d.ts == _ts)?.val: undefined;
            const found4 = res[3] && res[3].data.length ? res[3].data.find(_d => _d.ts == _ts)?.val: undefined;
            let val = found1;
            if (found1 > 0) {
              val = found1;
            } else if(found2 > 0) {
              val = found2;
            } else if (found3 > 0) {
              val = found3;
            } else  if (found4 > 0) {
              val = found4;
            }
            formatedData['data'].push({ts: _ts, val: val});
          }
      });
    }
    return formatedData;
  }

  getPointListHeight(clickPointList) {
    if (!this.showchillerEfficiency && clickPointList.length <= 13) {
      return "pointlist-center-" + clickPointList.length;
    } else if (!this.showchillerEfficiency && clickPointList.length >= 14 && this.selectedEquipData.class != 'coolingTowerFourFan') {
      return "pointlist-center-14";
    } else if (!this.showchillerEfficiency && clickPointList.length >= 1 && this.selectedEquipData.class == 'coolingTowerFourFan') {
      return "pointlist-center-CT4-14";
    } else if (this.showchillerEfficiency && (clickPointList.length >= 10 || clickPointList.length <= 11)) {
      return "pointlist-center-10";
    }

    return "pointlist-center-14";
  }

  checkPointSelected(point) {
    let found = undefined;
    this.selectedEquipData.data.point.forEach(_point => {
      if (point.tags.every(tag => _point.tags.includes(tag))) {
        found = _point;
      }
    });
    return found;
  }

  // Method to display the values of predefined parameters for equips.
  checkCoolingTowerPredefinedPointsSelected() {
    const predefinedparamsList = ['runHours', 'enable', 'power', 'command', 'energy'];
    predefinedparamsList.forEach(_param => {
      const _this = this;
      this.predefinedParameters[_param].pointList.map((_pointList, i) => {
        // Fetching the points based on the matching tags of the predefined parameters.
        const _point = _this.clickPointList.find((point) => {
          const isEveryTagExists = _this.predefinedParameters[_param].tags.every((_tag) => {
            return point.tags.includes(_tag);
          });
          if (_this.selectedEquipData.class !== 'coolingTowerSingleFan') {
            const isCellMatch = point.cell === String(i + 1);
            return isCellMatch && isEveryTagExists;
          } else {
            return isEveryTagExists;
          }
        });
        // Assigning the point to the predefined parameters.
        if (_point) {
          _pointList['point'] = _point;
          if (_param === 'energy') { 
            _pointList['pointValueToDisplay'] = `${_point['pointValue'] && (_point['pointValue'] !== undefined && _point['pointValue']?.length) && _point['pointValue'] != " "  ? _point['pointValue'] : 'NA'}`;
          } else if (_point.data.length && _point.data[0]) {
            // Displaying the value for the predefined parameters, if the point have a value.
            if (_param === 'runHours') {
              const pointVal = _point.data[_point.data.length - 1].val.split(" ");
              _pointList['pointValueToDisplay'] = `${Number(pointVal[0])} ${pointVal[1] && pointVal[1] !== undefined ? pointVal[1] : 'NA'}`;
            } else {
              const pointVal = _point.data[_point.data.length - 1].val;
              _pointList['pointValueToDisplay'] = pointVal;
            }
          } else {
            // Displaying the value for predefined parameters as 'NA', if the point doesn't have any value.
            _pointList['pointValueToDisplay'] = _param === 'enable' || _param === 'command' ? _pointList['pointValue'] : 'NA';
          }
        } else {
          // Hiding the param in UI if the point is not available.
          _pointList['pointValueToDisplay'] = '';
        }
        return _pointList;
      });

      // Adding the count of points to display for each predefined parameter
      this.predefinedParameters[_param]['pointsToDisplayCount'] = this.predefinedParameters[_param].pointList.filter(_point => _point['pointValueToDisplay']).length;
    });
  }

  checkCT2AndCT4PredefinedPointsSelected() {
    const predefinedparamsList = ['runHours', 'enable', 'power', 'command', 'energy'];
    predefinedparamsList.forEach(_param => {
      this.predefinedParameters[_param].pointList.map((_pointList, i) => {
        this.clickPointList.forEach(_point => {
          if (this.selectedEquipData.class != 'coolingTowerSingleFan') {
            if (_point.cell == i + 1 && this.predefinedParameters[_param].tags.every(_tag => _point.tags.includes(_tag))) {
              _pointList['point'] = _point;
             if(_param == 'energy'){
                _pointList.pointValue = `${_point.pointValue != undefined ? _point.pointValue: ''}`;
              } else if (_point.data.length && _point.data[0]) {
                if ( _param == 'runHours') {
                  const pointVal = _point.data[_point.data.length - 1].val.split(" ");
                  _pointList.pointValue = `${Number(pointVal[0]).toFixed(2)} ${pointVal[1] != undefined ?pointVal[1]: '' }`;
                } else {
                  const pointVal = _point.data[_point.data.length - 1].val;
                  _pointList.pointValue = pointVal;
                }
              }
            }
          } else {
            if (this.predefinedParameters[_param].tags.every(_tag => _point.tags.includes(_tag))) {
              _pointList['point'] = _point;
              if (_param == 'energy') {
                _pointList.pointValue = `${_point.pointValue != undefined ? _point.pointValue: ''}`;
              } else if (_point.data.length && _point.data[0]) {
                if (_point.data.length && _point.data[0]) {
                  if (_param == 'runHours') {
                    const pointVal = _point.data[_point.data.length - 1].val.split(" ");
                    _pointList.pointValue = `${Number(pointVal[0]).toFixed(2)} ${pointVal[1] != undefined ? pointVal[1]: '' }`;
                  } else {
                    const pointVal = _point.data[_point.data.length - 1].val;
                    _pointList.pointValue = pointVal;
                  }
                }
              }
            }
          }
        });
        return _pointList;
      });
    });
  }

  calculatePositionOnPoints(tooltip) {
    let maxVal = [];

    if (this.selectedEquipData.class == 'coolingTowerSingleFan' || this.selectedEquipData.class == 'coolingTowerTwoFan' || this.selectedEquipData.class == 'coolingTowerFourFan') {
      if (this.predefinedParameters?.command?.pointList) {
        let count = 0;
        this.predefinedParameters?.command?.pointList.forEach((res, i) => {
          count++;
        })
        if (count == 4) {
          this.commandHeight = "commandCT-4-point";
        } else if (count == 3) {
          this.commandHeight = "commandCT-3-point";
        } else if (count == 2) {
          this.commandHeight = "commandCT-2-point";
        } else {
          this.commandHeight = "commandCT-1-point";
        }
      }
      if (this.predefinedParameters?.enable?.pointList) {
        let count = 0;
        this.predefinedParameters?.enable?.pointList.forEach((res, i) => {
          count++;
        })
        if (count == 4) {
          this.circularWidgetPosition = "-40px";
          this.equipTitleTop = "-150px"
          this.leftContainerTop = this.showchillerEfficiency ? '29%' : '9%';
          this.pointListHeight = this.showchillerEfficiency ? '168px' : '375px';
          return;
        } else {
          maxVal.push(count);
        }
      }

      if (this.predefinedParameters?.runHours?.pointList) {
        let count = 0;
        this.predefinedParameters?.runHours?.pointList.forEach((res, i) => {
          count++;
        })
        if (count == 4) {
          this.circularWidgetPosition = "-40px";
          this.equipTitleTop = "-150px"
          this.leftContainerTop = this.showchillerEfficiency ? '29%' : '9%';
          this.pointListHeight = this.showchillerEfficiency ? '168px' : '375px';
          return;
        } else {
          maxVal.push(count);
        }
      }

      if (this.predefinedParameters?.power?.pointList) {
        let count = 0;
        this.predefinedParameters?.power?.pointList.forEach((res, i) => {
          count++;
        })
        if (count == 4) {
          this.circularWidgetPosition = "-40px";
          this.equipTitleTop = "-150px";
          this.leftContainerTop = this.showchillerEfficiency ? '29%' : '9%';
          this.pointListHeight = this.showchillerEfficiency ? '168px' : '375px';
          return;
        } else {
          maxVal.push(count);
        }
      }

      if (this.predefinedParameters?.energy?.pointList) {
        let count = 0;
        this.predefinedParameters?.energy?.pointList.forEach((res, i) => {
          count++;
        })
        if (count == 4) {
          this.circularWidgetPosition = "-40px";
          this.equipTitleTop = "-150px";
          this.leftContainerTop = this.showchillerEfficiency ? '29%' : '9%';
          this.pointListHeight = this.showchillerEfficiency ? '168px' : '375px';
          return;
        } else {
          maxVal.push(count);
        }
      }

      if (Math.max(...maxVal) == 4 && this.selectedEquipData?.class == 'coolingTowerFourFan') {
        this.circularWidgetPosition = "-90px";
        this.equipTitleTop = "-150px";
        this.leftContainerTop = "29%";
        this.leftContainerTop = this.showchillerEfficiency ? '29%' : '9%';
        this.pointListHeight = this.showchillerEfficiency ? '168px' : '375px';
      } else if (Math.max(...maxVal) == 3) {
        this.circularWidgetPosition = "-53px";
        this.equipTitleTop = "-115px";
        this.leftContainerTop = this.showchillerEfficiency ? '21%' : '3%';
        this.pointListHeight = this.showchillerEfficiency ? '225px' : '260px';
      } else if (Math.max(...maxVal) == 2) {
        this.circularWidgetPosition = "-9px";
        this.equipTitleTop = "-80px";
        this.leftContainerTop =  this.showchillerEfficiency ? '18%' : '4%';
        this.pointListHeight =  this.showchillerEfficiency ? '260px' : '450px';
      } else if (Math.max(...maxVal) == 1) {
        this.circularWidgetPosition = "3px";
        this.equipTitleTop = "-30px";
        this.leftContainerTop = "11%";
        this.leftContainerTop =  this.showchillerEfficiency ? '14%' : '3%';
        this.pointListHeight =  this.showchillerEfficiency ? '290px' : '420px';
      }
    } else {
      this.circularWidgetPosition = "18px";
      this.equipTitleTop = "-30px";
      this.leftContainerTop =  this.showchillerEfficiency ? '11%' : '4%';
      this.pointListHeight =  this.showchillerEfficiency ? '270px' : '400px';
      this.commandHeight = "command-1-point";
    }
    // Appends the tooltip for the charts.
    setTimeout(() => {
      document.getElementById('graph-wrapper').appendChild(tooltip);
    }, 0);
  }

  addPreDefinedMargin() {
    if (this.selectedEquipData?.class == 'coolingTowerSingleFan' || this.selectedEquipData.class == 'coolingTowerTwoFan' || this.selectedEquipData.class == 'coolingTowerFourFan') {
      return "p-l-32";
    } else {
      return "p-l-0";
    }
  }

  showToast(type: string, message: string, duration = 500) {
    this.toastMessage.openFromComponent(ToastMessageComponent, {
      duration: duration,
      data: {
        type: type,
        message: message
      },
    });
  }

  ngOnDestroy(): void {

  }

  checkCustomPoint(pointData) {
    let showPoint = true;
    if (pointData?.tags?.length) {
      let equipName;
      if (this.selectedEquipData?.class.includes('pump')) {
        equipName = 'pump1';
      } else {
        equipName = this.selectedEquipData.class;
      }

      defaultClickViewPoint[equipName].forEach(pointTags => {

        if (pointTags.every(value => pointData?.tags?.includes(value))) {
          showPoint = false;
        }
      });
    }
    return showPoint;
  }

  generateChartDates(start, end) {
    if (this.showChillerLoadChart || this.showAlertChart || this.showEnergyConsumptionChart || this.showStatusChart || this.showbuildingSchChart) {
      let days = dayjs(end).diff(start, "day");
      const customDateTextIds = ['Time-12-pm', 'Time-12-am', 'Time-6-am', 'Time-6-pm'];
      const currentDayIds = ['text-1', 'text-2', 'text-3', 'text-4'];
      if (days == 0) {
        customDateTextIds.forEach(_id => {
          document.getElementById(_id)?.setAttribute('display', 'none');
        });
        currentDayIds.forEach(_id => {
          document.getElementById(_id)?.style?.removeProperty('display');
        });
        const topDateDisplayText = document.getElementById('text-1'); //12pm
        const rightDateDisplayText = document.getElementById('text-2'); // 6pm
        const bottomDateDisplayText = document.getElementById('text-3'); //12am
        const leftDateDisplayText = document.getElementById('text-4'); // 6am
        topDateDisplayText.textContent = '12PM';
        rightDateDisplayText.textContent = '6PM';
        bottomDateDisplayText.textContent = '12AM';
        leftDateDisplayText.textContent = '6AM';
      } else {
        const topDateDisplayText = document.getElementById('Time-12-pm');
        const bottomDateDisplayText = document.getElementById('Time-12-am');
        const leftDateDisplayText = document.getElementById('Time-6-am');
        const rightDateDisplayText = document.getElementById('Time-6-pm');
        customDateTextIds.forEach(_id => {
          document.getElementById(_id)?.style?.removeProperty('display');
        });
        currentDayIds.forEach(_id => {
          document.getElementById(_id)?.setAttribute('display', 'none');
        });
        topDateDisplayText.classList.add('circular-top-date-text');
        rightDateDisplayText.classList.add('circular-right-date-text');
        bottomDateDisplayText.classList.add('circular-bottom-date-text');
        leftDateDisplayText.classList.add('circular-left-date-text');
        if (days == 1) {
          topDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
        } else if (days == 2) {
          topDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = '';
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(1, 'day').format('MMM DD, YYYY');
        } else if (days == 3) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(2, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(1, 'day').format('MMM DD, YYYY');
        } else if (days == 4) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(3, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(2, 'day').format('MMM DD, YYYY');
        }
        else if (days == 5 || days == 6 || days == 7) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(4, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(2, 'day').format('MMM DD, YYYY');
        } else if (days == 8 || days == 9 || days == 10) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(6, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(3, 'day').format('MMM DD, YYYY');
        } else if (days == 11 || days == 12 || days == 13) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(8, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(4, 'day').format('MMM DD, YYYY');
        } else if (days == 14 || days == 15) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(10, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(5, 'day').format('MMM DD, YYYY');
        } else if (days == 16 || days == 17 || days == 18 || days == 19) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(10, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(5, 'day').format('MMM DD, YYYY');
        } else if (days == 20 || days == 21 || days == 22 || days == 23) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(11, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(6, 'day').format('MMM DD, YYYY');
        } else if (days == 24 || days == 25 || days == 26) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(13, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(7, 'day').format('MMM DD, YYYY');
        } else if (days == 27 || days == 28 || days == 29 || days == 30) {
          topDateDisplayText.innerHTML = this.selected.startDate.add(20, 'day').format('MMM DD, YYYY');
          rightDateDisplayText.innerHTML = this.selected.endDate.format('MMM DD, YYYY');
          bottomDateDisplayText.innerHTML = this.selected.startDate.format('MMM DD, YYYY');
          leftDateDisplayText.innerHTML = this.selected.startDate.add(10, 'day').format('MMM DD, YYYY');
        }
      }
    }
  }

  export() {
    const dialogRef = this.dialog.open(ExportModalComponent, {
      width: '600px',
      panelClass: 'builder-modal-container',
      data: {
        fromPlant: false,
        selectedPlantData: this.selectedEquipData,
        ref: this.clickedViewModalRef,
        energyConsumptionData: this.exportData.energyConsumptionData,
        buildingScheduleData: this.exportData.buildingScheduleData,
        alertData: this.exportData.alertData,
        statusData: this.exportData.statusData,
        chillerLoadData: this.exportData.chillerLoadData,
        equipType: this.selectedEquipName
      }
    });
    dialogRef.afterClosed().subscribe();
  }

  setResponsiveStyling() {
    const isCoolingTowerSelected = ['coolingTowerSingleFan' , 'coolingTowerTwoFan' ,'coolingTowerFourFan'].includes(this.selectedEquipData.class);
    document.getElementById('center-img').setAttribute('href', this.equipImageDetails['url']);
    if (window.innerWidth >= 1024 && window.innerWidth <= 1550) {
      this.responsiveStylingObj.circularWidget.circle = {
        svgWidth: 225,
        svgHeight: 200,
        radius: isCoolingTowerSelected ? this.selectedEquipData.class == 'coolingTowerFourFan' ? 140 :160 : 170
      };
      this.responsiveStylingObj.circularWidget.chart = {
        width: 450,
        height: 410,
        radius: isCoolingTowerSelected ? this.selectedEquipData.class == 'coolingTowerFourFan' ? 140 :160 : 170
      };
      this.responsiveStylingObj.leftTopChart.chart = {
        width: 250,
        height: 150,
      }
      this.responsiveStylingObj.svgDate.chart = {
        width: 500,
        height: 450
      };
      this.responsiveStylingObj.rightChart = {
        bulletChart: {
          width: 240,
          height: 40 
        },
        areaChart: {
          width: 230,
          height: 80 
        }
      };
      document.getElementById('center-img').setAttribute('x', '125');
      document.getElementById('center-img').setAttribute('y', '100');
    } else if (window.innerWidth >= 1551 && window.innerWidth <= 1720 ) {
      this.responsiveStylingObj.circularWidget.circle = {
        svgWidth: 205,
        svgHeight: 200,
        radius: 180
      }
      this.responsiveStylingObj.circularWidget.chart = {
        width: 400,
        height: 400,
        radius: 180
      }
      this.responsiveStylingObj.leftTopChart.chart = {
        width: 250,
        height: 150,
      }
      this.responsiveStylingObj.svgDate.chart = {
        width: 450,
        height: 450
      }
      this.responsiveStylingObj.rightChart = {
        bulletChart: {
          width: 240,
          height: 40 
        },
        areaChart: {
          width: 250,
          height: 100 
        }
      }
      document.getElementById('center-img').setAttribute('width', '200');
      document.getElementById('center-img').setAttribute('height', '200');
      document.getElementById('horizontal-line').setAttribute('y1', ((400/2)).toString()+'px');
      document.getElementById('horizontal-line').setAttribute('y2', ((400/2)).toString()+'px');
      document.getElementById('vertical-line').setAttribute('x1', ((400/2) + 5).toString()+'px');
      document.getElementById('vertical-line').setAttribute('x2', ((400/2) + 5).toString()+'px');
      document.getElementById('center-img').setAttribute('x', ((400/2)/2).toString());
      document.getElementById('center-img').setAttribute('y', ((400/2)/2).toString());
    } else if (window.innerWidth >= 1720 && window.innerWidth <= 1799) {
      // if (window.innerHeight <= 850) {
        this.responsiveStylingObj.circularWidget.circle = {
          svgWidth: 225,
          svgHeight: 220,
          radius: 180
        }
        this.responsiveStylingObj.circularWidget.chart = {
          width: 450,
          height: 450,
          radius: 180
        }
        this.responsiveStylingObj.leftTopChart.chart = {
          width: 280,
          height: 180,
        }
        this.responsiveStylingObj.svgDate.chart = {
          width: 500,
          height: 500
        }
        document.getElementById('center-img').setAttribute('width', '200');
        document.getElementById('center-img').setAttribute('height', '200');
        document.getElementById('center-img').setAttribute('x', (200/2).toString());
        document.getElementById('center-img').setAttribute('y', (200/2).toString());
        document.getElementById('horizontal-line').setAttribute('y1', '225px');
        document.getElementById('horizontal-line').setAttribute('y2', '225px');
        document.getElementById('vertical-line').setAttribute('x1', '225px');
        document.getElementById('vertical-line').setAttribute('x2', '225px');
    } else if (window.innerWidth >= 1800 && window.innerWidth <= 1880) {
        this.responsiveStylingObj.circularWidget.circle = {
          svgWidth: 225,
          svgHeight: 200,
          radius: 170
        };
        this.responsiveStylingObj.circularWidget.chart = {
          width: 450,
          height: 450,
          radius: 170
        };
        this.responsiveStylingObj.leftTopChart.chart = {
          width: 250,
          height: 150,
        }
        this.responsiveStylingObj.svgDate.chart = {
          width: 500,
          height: window.innerHeight < 1000 ? 460: 500
        }
        document.getElementById('center-img').setAttribute('x', '125');
        document.getElementById('center-img').setAttribute('y', '100');
    } else if (window.innerWidth >= 1881 ) {
      this.responsiveStylingObj.circularWidget.circle = {
        svgWidth: 225,
        svgHeight: 200,
        radius: 170
      };
      this.responsiveStylingObj.circularWidget.chart = {
        width: 450,
        height: 430,
        radius: 170
      };
      this.responsiveStylingObj.leftTopChart.chart = {
        width: 250,
        height: 150,
      }
      this.responsiveStylingObj.svgDate.chart = {
        width: 500,
        height: 480
      }
      this.responsiveStylingObj.rightChart = {
        bulletChart: {
          width: 290,
          height: 50
        },
        areaChart: {
          width: 280,
          height: 120
        }
      }
      document.getElementById('center-img').setAttribute('width', '200');
      document.getElementById('center-img').setAttribute('height', '200');
      document.getElementById('center-img').setAttribute('x', (200/2).toString());
      document.getElementById('center-img').setAttribute('y', (200/2).toString());
    }
    const svgGraphDiv = document.getElementById('svg-div');
    svgGraphDiv.style.width = this.responsiveStylingObj.circularWidget.chart.width + 'px';
    svgGraphDiv.style.height = this.responsiveStylingObj.circularWidget.chart.height + 'px';
  }

  setCircleWidthAndHeight() {
    const circleIds = ['energy-consumption-chart', 'status-chart', 'alert-chart', 'chiller-load-chart'];
    let radius = ObjectUtil.deepClone(this.responsiveStylingObj.circularWidget.circle.radius);
    circleIds.forEach((_circleId, i) => {
      const element = document.getElementById(`${_circleId}`);
      if (element) {
        if (radius && i != 0) {
          radius = radius - 5
        }
        element.setAttribute('cx', this.responsiveStylingObj.circularWidget.circle.svgWidth);
        element.setAttribute('cy', this.responsiveStylingObj.circularWidget.circle.svgHeight);
        element.setAttribute('r', (radius)?.toString());
        radius = radius;
      }
    });
  }

  calculateDatePostions() {
    const dateTextIds = ['text-1', 'text-2', 'text-3', 'text-4'];
    const width = this.responsiveStylingObj.svgDate.chart.width;
    const height = this.responsiveStylingObj.svgDate.chart.height;
    dateTextIds.forEach(_dateId => {
      const element = document.getElementById(`${_dateId}`);
      if (element) {
        if (_dateId == 'text-1') {
          element.setAttribute('x', ((width/2) - 10 ).toString());
          element.setAttribute('y', '20');
        } else if (_dateId == 'text-2') {
          element.setAttribute('x', (width - 20).toString());
          if (window.innerWidth >= 1800) {
            element.setAttribute('y', ((height/2) - 15 ).toString());
          } else {
            element.setAttribute('y', ((height/2) + 5 ).toString());
          }
        } else if (_dateId == 'text-3') {
          element.setAttribute('x', ((width/2) - 10 ).toString());
          if (window.innerWidth >= 1800 && window.innerWidth <= 1880) {
            element.setAttribute('y', (height + 20).toString());
          } else {
            element.setAttribute('y', (height).toString());
          }
        } else if (_dateId == 'text-4') {
          element.setAttribute('x', '0');
          if (window.innerWidth >= 1800) {
            element.setAttribute('y', ((height/2) - 15 ).toString());
          } else {
            element.setAttribute('y', ((height/2)).toString());
          }
        }
      }
    });
  }

  clearChartOnWindowResize() {
    const barChartContent = document.getElementById("bar-Chart-content");

    if (barChartContent.hasChildNodes()) {
      barChartContent.removeChild(barChartContent.children[0]);
    }

    document.getElementsByClassName('bulletChartContainer')[0]?.remove();
    document.getElementById("ChillerParentContainer")?.remove();
    document.getElementById("boilerParentContainer")?.remove();
    this.reLoadChartsOnDateSelection();
  }

  calculateLoaderPosition() {
    const isCoolingTowerSelected = ['coolingTowerSingleFan' , 'coolingTowerTwoFan' ,'coolingTowerFourFan'].includes(this.selectedEquipData.class);
    if (window.innerWidth >= 1240) {
      this.responsiveStylingObj.loaderStyling.leftTop['margin-left'] = '145px';
      this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '70px';
      this.responsiveStylingObj.loaderStyling.rightTop['margin-right'] = '145px';
      this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '70px';
      this.responsiveStylingObj.loaderStyling.centerLeft['width'] = '250px';
      this.responsiveStylingObj.loaderStyling.centerLeft['height'] = '350px';
      this.responsiveStylingObj.loaderStyling.centerRight['width'] = '250px';
      this.responsiveStylingObj.loaderStyling.centerRight['height'] = '350px';
      this.responsiveStylingObj.loaderStyling.centerMiddle['width'] = '445px';
      this.responsiveStylingObj.loaderStyling.centerMiddle['height'] = '350px';
      if (isCoolingTowerSelected) {
        this.responsiveStylingObj.loaderStyling.rightTop['margin-left'] = '145px';
      }
      if (window.innerWidth >= 1600 && window.innerWidth <= 1700) {
        this.responsiveStylingObj.loaderStyling.centerMiddle['width'] = '545px';
      }
      if (window.innerWidth >= 1700) {
        this.responsiveStylingObj.loaderStyling.centerMiddle['width'] = '625px';
      }
      if (window.innerHeight >= 760 && window.innerHeight <= 800) {
        this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '90px';
        this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '90px';
        if (window.innerWidth >= 1700) {
          this.responsiveStylingObj.loaderStyling.leftTop['margin-left'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-right'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '50px';
          this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '50px';
        }
      } else if (window.innerHeight >= 801 && window.innerHeight <= 900) {
        this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '150px';
        this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '150px';
        if (isCoolingTowerSelected) {
          this.responsiveStylingObj.loaderStyling.rightTop['margin-left'] = '325px';
        }
        if (window.innerWidth >= 1700) {
          this.responsiveStylingObj.loaderStyling.leftTop['margin-left'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-right'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '110px';
          this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '110px';
        }
      } else if (window.innerHeight >= 901 && window.innerHeight <= 1000) {
        if (window.innerWidth >= 1700) {
          this.responsiveStylingObj.loaderStyling.leftTop['margin-left'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-right'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '150px';
          this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '150px';
        }
        this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '100px';
        this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '100px';
        this.responsiveStylingObj.loaderStyling.centerRight['width'] = '300px';
        this.responsiveStylingObj.loaderStyling.centerLeft['width'] = '300px';
        this.responsiveStylingObj.loaderStyling.centerMiddle['width'] = '650px';

        if (isCoolingTowerSelected) {
          this.responsiveStylingObj.loaderStyling.rightTop['margin-left'] = '325px';
        }
      } else if (window.innerHeight >= 1001 && window.innerHeight <= 1200) {
        if (window.innerWidth >= 1700) {
          this.responsiveStylingObj.loaderStyling.leftTop['margin-left'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-right'] = '165px';
          this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '180px';
          this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '180px';
        }
        this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '100px';
        this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '100px';
        this.responsiveStylingObj.loaderStyling.centerRight['width'] = '300px';
        this.responsiveStylingObj.loaderStyling.centerLeft['width'] = '300px';
        this.responsiveStylingObj.loaderStyling.centerMiddle['width'] = '650px';
        if (isCoolingTowerSelected) {
          this.responsiveStylingObj.loaderStyling.rightTop['margin-left'] = '325px';
        }
      } else if (window.innerHeight >= 1200){
        if (isCoolingTowerSelected) {
          this.responsiveStylingObj.loaderStyling.rightTop['margin-left'] = '325px';
        }
        this.responsiveStylingObj.loaderStyling.rightTop['margin-top'] = '100px';
        this.responsiveStylingObj.loaderStyling.leftTop['margin-top'] = '100px';
        this.responsiveStylingObj.loaderStyling.centerRight['width'] = '300px';
        this.responsiveStylingObj.loaderStyling.centerLeft['width'] = '300px';
        this.responsiveStylingObj.loaderStyling.centerMiddle['width'] = '650px';
      }
    }
  }

  showEnableTooltip(data) {
    let selectedvalue = data?.pointList[0]?.point?.enum.filter(obj => obj.value == data.pointList[0].pointValue);
    if (selectedvalue.length) {
      return selectedvalue[0].name;
    }
  }


  showCTEnableTooltip(data) {
    let selectedvalue = data?.enum.filter(obj => obj.value == data.writableData[0].val);
    if (selectedvalue.length) {
      return selectedvalue[0].name;
    }
  }
}


