<div class="alertHeaderText">Add Alert</div>
<mat-form-field *ngIf="!loading && alertList?.length>0" class="search-input" [floatLabel]="'never'">
    <input matInput placeholder="Search for alert" #searchTextField [(ngModel)]="alertSearch" autocomplete="off" />
    <i class="fas fa-search searchIcon" matSuffix aria-hidden="true"></i>
</mat-form-field>
<div *ngIf="!loading && alertList?.length>0" class="addContainer">
    <div class="alertRowStyle" *ngFor="let alert of alertList | filter:alertSearch:'alertName'">

        <div class="d-flex p-rl-10">
            <div>
                <mat-checkbox (change)="alertChanged()" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="alert.selected" [checked]="alert.selected"></mat-checkbox>
            </div>
            <div (click)="alertSelected(alert)" class="alertName">{{alert.alertName}}</div>
        </div>
    </div>
</div>
<div *ngIf="!loading && alertList?.length == 0">
  <div class="noAlertsText">  <i class="fas fa-exclamation-triangle warningIcon"></i> There are no alerts to show.</div> 
</div>

<div class="flex-footer  d-flex justify-content-end p-t-15">
    <div class="col-9 d-flex justify-content-end align-items-center">
        <button  (click)="cancel()" class="text-uppercase btn color-primary text-uppercase">CANCEL</button>
        <span class="separatorIcon">|</span>

        <button (click)="addAlert()" [ngClass]="{'disabled' : alertList.length == 0}" [disabled]="alertList.length == 0"  class="text-uppercase btn color-primary text-uppercase p-r-0">ADD ALERT</button>

    </div>
</div>