import { ObjectUtil } from '../utils/object-util';
import { Injectable } from '@angular/core';
import { StorageKeys } from '../enums/StorageKeys.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  selectedOrgId: any = '';
  selectedPlant = {name:'', id:''};
  selectedSite: any = [];
  equipConfig: any = [];
  selectedPlantData: any = {};
  /**
   * Clear local and session storage item for the key supplied
   * @param key Key to clear
   */
  clearItem(key: string) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  /**
   * Get the current bearer-token
   */
  get bearerToken() {
    return localStorage.getItem(StorageKeys.BEARER_TOKEN);
  }

  /**
   * Store the bearer token in local storage
   */
  set bearerToken(token: string) {
    localStorage.setItem(StorageKeys.BEARER_TOKEN, token);
  }

  /**
   * Get the current user
   */
  get user() {
    return JSON.parse(localStorage.getItem(StorageKeys.USER));
  }

  /**
   * Set the current user in local storage
   */
  set user(user: any) {
    localStorage.setItem(StorageKeys.USER, JSON.stringify(user));
  }

  /**
   * Get the current user details (name, phone, etc...)
   */
  get userDetails() {
    return JSON.parse(localStorage.getItem(StorageKeys.USER_DETAILS));
  }

  /**
   * Set the current user details (name, phone, etc...) in local storage
   */
  set userDetails(details: any) {
    localStorage.setItem(StorageKeys.USER_DETAILS, JSON.stringify(details));
  }

  /**
   * Get the current route
   */
  get route() {
    return sessionStorage.getItem(StorageKeys.ROUTE);
  }

  /**
   * Set the current route in local storage
   */
  set route(route: string) {
    sessionStorage.setItem(StorageKeys.ROUTE, route);
  }

  selectedOrg(orgId) {
    this.selectedOrgId = orgId;
  }

  selectedPlantObj(selectedPlant) {
    this.selectedPlant = selectedPlant;
  }

  selectedSites(selectedSites) {
    this.selectedSite = selectedSites;
  }

  equipConfigData(equipData) {
    this.equipConfig = JSON.parse(localStorage.getItem(StorageKeys.EQUIPCONFIG)) || [];
    if (this.equipConfig?.length > 0) {
      let index = this.equipConfig.findIndex(equip => equip.equipID == equipData.equipID);
      if (index == -1) {
        this.equipConfig.push(equipData);
      } else {
        this.equipConfig[index] = equipData;
      }
    } else {
      this.equipConfig.push(equipData);
    }

    localStorage.setItem(StorageKeys.EQUIPCONFIG, JSON.stringify(this.equipConfig));
  }


  savePlant(data) {
    let plantData = ObjectUtil.deepClone(data)
    let plantConfigList = JSON.parse(localStorage.getItem(StorageKeys.PLANTCONFIG));
    if (plantConfigList == null) {
      localStorage.setItem(StorageKeys.PLANTCONFIG, JSON.stringify([plantData]));
    } else if (plantConfigList.length > 0) {
      let index = plantConfigList.findIndex(plant => plant.id === plantData.id);
      if (index == -1) {
        plantConfigList.push(plantData);
      } else {
        plantConfigList[index] = plantData;
      }
      localStorage.setItem(StorageKeys.PLANTCONFIG, JSON.stringify(plantConfigList));
    }
  }

  get plantConfigList() {
    return JSON.parse(localStorage.getItem(StorageKeys.PLANTCONFIG));
  }

  setEquipConfigListData(equipData){
    localStorage.setItem(StorageKeys.EQUIPCONFIG, JSON.stringify(equipData));
  }
  
  get equipConfigList() {
    return JSON.parse(localStorage.getItem(StorageKeys.EQUIPCONFIG));
  }

  setSelectedPlantData(selectedPlantData) {
    this.selectedPlantData = selectedPlantData;
    let equipData = [];
    let configData = this.selectedPlantData.plantJsonStr.drawflow.Home.data;
    if (configData != undefined && Object.keys(configData).length > 0) {
      Object.keys(configData).forEach(function (key) {
        if(Object.keys(configData[key].data).length > 0){
          equipData.push(configData[key].data);
        }
      });
    }
    this.setEquipConfigListData(equipData);
  }

  getSelectedPlantData() {
    return this.selectedPlantData;
  }
}
