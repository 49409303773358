export const predefinedParametersObj = {
    waterCooledChiller: {
        leftPosition:'chillerLeftPointClass',
        rightPosition:'waterChillerRightPointClass',
        titleTop:'-150px',
        enable: {
            icon: 'enable_svg-1',
            pointList: [{ name: 'CHILLER ENABLE', pointName: 'Chiller Enable', pointValue: '0' }],
            tags: ['chiller', 'enable']
        },
        runHours: {
            icon: 'runHours1_svg',
            pointList: [{ name: 'CHILLER RUN HOURS', pointName: 'Chiller Run Hours' }], 
            tags: ['chiller', 'run', 'time']
        },
        power: {
            icon: 'power_svg',
            pointList: [{ name: 'CHILLER POWER', pointName: 'Chiller Power' }],
            tags: ['power', 'elec', 'chiller']
        },
        energy: {
            icon: 'energyConsumed_svg',
            pointList: [{ name: 'ENERGY CONSUMED', pointName: 'Chiller Energy Consumption' }],
            tags: ['energy', 'chiller']
        },
        command: {
            pointList: [{ name: 'CHILLER COMMAND', pointName: 'Chiller Command', pointValue: '0' }],
            tags: ['chiller', 'cmd', 'run']
        },
        evaporator: {
            'leaving': ["chilled", "water", "temp","leaving", "sensor"],
            'entering': ["chilled", "water", "temp", "entering", "sensor"],
            'setPoint': ["chilled", "water", "sp"]
        },
        condenser: {
            'leaving': ["condenser", "water", "temp","leaving", "sensor"],
            'entering': ["condenser", "water", "temp", "entering", "sensor"],
            'setPoint': ["condenser", "water", "sp"]
        },
        efficiency:['chiller','efficiency']
    },
    airCooledChiller: {
        leftPosition:'chillerLeftPointClass',
        rightPosition:'waterChillerRightPointClass',
        titleTop:'-150px',
        enable: {
            icon: 'enable_svg-1',
            pointList: [
                { name: 'CHILLER ENABLE', pointName: 'Chiller Enable', pointValue: '0' }], 
                tags: ['chiller', 'enable']
        },
        runHours: {
            icon: 'runHours1_svg',
            pointList: [
                { name: 'CHILLER RUN HOURS', pointName: 'Chiller Run Hours' }],
            tags: ['chiller', 'run', 'time']
        },
        power: {
            icon: 'power_svg',
            pointList: [
                { name: 'CHILLER POWER', pointName: 'Chiller Power' }],
            tags: ['power', 'elec', 'chiller']
        },
        energy: {
            icon: 'energyConsumed_svg',
            pointList: [
                { name: 'ENERGY CONSUMED', pointName: 'Chiller Energy Consumption' }],
            tags: ['energy', 'chiller']
        },
        command: {
            pointList: [
                { name: 'CHILLER COMMAND', pointName: 'Chiller Command', pointValue: '0' }
            ],
            tags: ['chiller', 'cmd', 'run']
        },
        evaporator: {
            'leaving': ["chilled", "water", "temp","leaving", "sensor"],
            'entering': ["chilled", "water", "temp", "entering", "sensor"],
            'setPoint': ["chilled", "water", "sp"]
        },
        condenser: {
            'leaving': ["condenser", "water", "temp","leaving", "sensor"],
            'entering': ["condenser", "water", "temp", "entering", "sensor"],
            'setPoint': ["condenser", "water", "sp"]
        },
        efficiency:['chiller','efficiency']
    },
    pump1Vertical: {
        leftPosition:'pumpLeftPointClass',
        rightPosition:'pumpRightPointClass',
        titleTop:'-150px',
        enable: {
            icon: 'pumpenable_svg-1',
            pointList: [
                { name: 'PUMP ENABLE', pointName: 'Pump Enable' }],
                tags: ['pump', 'enable'],
        },
        runHours: {
            icon: 'runHours1_svg',
            pointList: [
                { name: 'PUMP RUN HOURS', pointName: 'Pump Run Hours' }],
                tags: ['pump', 'run', 'time']

        },
        power: {
            icon: 'power_svg',
            pointList: [
                { name: 'PUMP POWER', pointName: 'Pump Power' }],
            tags: ['pump', 'power', 'elec'],
        },

        energy: {
            icon: 'energyConsumed_svg',
            pointList: [
                { name: 'ENERGY CONSUMED', pointName: 'Pump Energy Consumption' }],
            tags: ['pump', 'energy']
        },

        command: {
            pointList: [
                { name: 'PUMP COMMAND', pointName: 'Pump Command', pointValue: '0' }],
            tags: ['pump', 'cmd', 'run']
        }
    },
    coolingTowerSingleFan: {
        rightPosition: 'CTRightPointClass',
        titleTop: '-150px',
        enable: {
            icon: 'enable_svg',
            pointList: [{ name: 'COOLING TOWER FAN ENABLE', pointName: 'Cooling Tower Fan Enable', pointValue: '0' }],
            tags: ['coolingTower', 'enable']
        },
        runHours: {
            icon: 'runHours_svg',
            pointList: [{ name: 'COOLING TOWER FAN RUN HOURS', pointName: 'Cooling Tower Fan Run Hours', pointValue: 'NA' }],
            tags: ['coolingTower', 'run', 'time']
        },
        power: {
            icon: 'power_svg',
            pointList: [{ name: 'COOLING TOWER FAN POWER', pointName: 'Cooling Tower Fan Power', pointValue: 'NA' }],
            tags: ['coolingTower', 'power', 'elec']
        },
        energy: {
            icon: 'energyConsumed1_svg',
            pointList: [{ name: 'COOLING TOWER FAN CONSUMED', pointName: 'Cooling Tower Fan Energy Consumption', pointValue: 'NA' }],
            tags: ['coolingTower', 'energy']
        },
        command: {
            pointList: [{ name: 'COOLING TOWER FAN COMMAND', pointName: 'Cooling Tower Fan Command', pointValue: '0' }],
            tags: ['coolingTower', 'run', 'cmd']
        }
    },

    coolingTowerTwoFan: {
        rightPosition:'CTRightPointClass',
        titleTop:'-150px',
        enable: {
            icon: 'enable_svg',
            pointList: [
                {
                    name: 'COOLING TOWER FAN 1 ENABLE',
                    pointName: 'Cooling Tower Fan 1 Enable',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 2 ENABLE',
                    pointName: 'Cooling Tower Fan 2 Enable',
                    pointValue: '0'
                }],
            tags: ['coolingTower', 'enable']
        },

        runHours: {
            icon: 'runHours_svg',
            pointList: [
                {
                    name: 'COOLING TOWER FAN 1 RUN HOURS',
                    pointName: 'Cooling Tower Fan 1 Run Hours',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 2 RUN HOURS',
                    pointName: 'Cooling Tower Fan 2 Run Hours',
                    pointValue: 'NA'
                }],
            tags: ['coolingTower', 'run', 'time']
        },
        power: {
            icon: 'power_svg', pointList: [
                {
                    name: 'COOLING TOWER FAN 1 POWER',
                    pointName: 'Cooling Tower Fan 1 Power',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 2 POWER',
                    pointName: 'Cooling Tower Fan 2 Power',
                    pointValue: 'NA'
                }],
            tags: ['power', 'elec', 'coolingTower']
        },

        energy: {
            icon: 'energyConsumed1_svg', pointList: [
                {
                    name: 'COOLING TOWER FAN 1 CONSUMED',
                    pointName: 'Cooling Tower Fan 1 Energy Consumption',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 2 CONSUMED',
                    pointName: 'Cooling Tower Fan 2 Energy Consumption',
                    pointValue: 'NA'
                }],
            tags: ['coolingTower', 'energy']
        },

        command: {
            pointList: [
                {
                    name: 'COOLING TOWER FAN 1 COMMAND',
                    pointName: 'Cooling Tower Fan 1 Command',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 2 COMMAND',
                    pointName: 'Cooling Tower Fan 2 Command',
                    pointValue: '0'
                }],
            tags: ['coolingTower', 'run', 'cmd']
        }
    },

    coolingTowerFourFan: {
        rightPosition:'CTRightPointClass',
        titleTop:'-150px',
        enable: {
            icon: 'enable_svg', pointList: [
                {
                    name: 'COOLING TOWER FAN 1 ENABLE',
                    pointName: 'Cooling Tower Fan 1 Enable',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 2 ENABLE',
                    pointName: 'Cooling Tower Fan 2 Enable',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 3 ENABLE',
                    pointName: 'Cooling Tower Fan 3 Enable',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 4 ENABLE',
                    pointName: 'Cooling Tower Fan 4 Enable',
                    pointValue: '0'
                }],
            tags: ['coolingTower', 'enable']
        },

        runHours: {
            icon: 'runHours_svg', pointList: [
                {
                    name: 'COOLING TOWER FAN 1 RUN HOURS',
                    pointName: 'Cooling Tower Fan 1 Run Hours',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 2 RUN HOURS',
                    pointName: 'Cooling Tower Fan 2 Run Hours',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 3 RUN HOURS',
                    pointName: 'Cooling Tower Fan 3 Run Hours',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 4 RUN HOURS',
                    pointName: 'Cooling Tower Fan 4 Run Hours',
                    pointValue: 'NA'
                }],
            tags: ['coolingTower', 'run', 'time']
        },

        power: {
            icon: 'power_svg', pointList: [
                {
                    name: 'COOLING TOWER FAN 1 POWER',
                    pointName: 'Cooling Tower Fan 1 Power',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 2 POWER',
                    pointName: 'Cooling Tower Fan 2 Power',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 3 POWER',
                    pointName: 'Cooling Tower Fan 3 Power',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 4 POWER',
                    pointName: 'Cooling Tower Fan 4 Power',
                    pointValue: 'NA'
                }],
            tags: ['power', 'elec', 'coolingTower']
        },

        energy: {
            icon: 'energyConsumed1_svg', pointList: [
                {
                    name: 'COOLING TOWER FAN 1 CONSUMED',
                    pointName: 'Cooling Tower Fan 1 Energy Consumption',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 2 CONSUMED',
                    pointName: 'Cooling Tower Fan 2 Energy Consumption',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 3 CONSUMED',
                    pointName: 'Cooling Tower Fan 3 Energy Consumption',
                    pointValue: 'NA'
                }, {
                    name: 'COOLING TOWER FAN 4 CONSUMED',
                    pointName: 'Cooling Tower Fan 4 Energy Consumption',
                    pointValue: 'NA'
                }],
            tags: ['coolingTower', 'energy']
        },

        command: {
            pointList: [
                {
                    name: 'COOLING TOWER FAN 1 COMMAND',
                    pointName: 'Cooling Tower Fan 1 Command',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 2 COMMAND',
                    pointName: 'Cooling Tower Fan 2 Command',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 3 COMMAND',
                    pointName: 'Cooling Tower Fan 3 Command',
                    pointValue: '0'
                }, {
                    name: 'COOLING TOWER FAN 4 COMMAND',
                    pointName: 'Cooling Tower Fan 4 Command',
                    pointValue: '0'
                }
            ],
            tags: ['coolingTower', 'run', 'cmd']
        }
    },

    condenserBoiler: {
        leftPosition:'boilerLeftPointClass',
        rightPosition:'boilerRightPointClass',
        titleTop:'-150px',
        enable: {
            icon: 'boilerEnable_svg',
            pointList: [
                { name: 'BOILER ENABLE', pointName: 'Boiler Enable',  pointValue: '0' }],
            tags: ['boiler', 'enable']
        },
        runHours: {
            icon: 'runHours1_svg',
            pointList: [
                { name: 'BOILER RUN HOURS', pointName: 'Boiler Run Hours' }],
            tags: ['boiler', 'run', 'time']
        },
        power: {
            icon: 'power_svg',
            pointList: [
                { name: 'BOILER POWER', pointName: 'Boiler Power' }],
            tags: ['power', 'elec', 'boiler']
        },
        energy: {
            icon: 'energyConsumed_svg',
            pointList: [
                { name: 'BOILER FLOW', pointName: 'Boiler Energy Consumption' }],
            tags: ['energy', 'boiler']
        },
        command: {
            pointList: [
                {
                    name: 'BOILER COMMAND',
                    pointName: 'Boiler Command',
                    pointValue: '0'
                }
            ],
            tags: ['boiler', 'run', 'cmd']
        },
        boiler: {
            'leaving': ["hot", "leaving", "temp", "water","sensor"],
            'entering': ["hot", "entering", "temp", "water","sensor"],
            'setPoint': ["hot", "water", "sp"]
        }
    },
    steamBoiler: {
        leftPosition:'boilerLeftPointClass',
        rightPosition:'boilerRightPointClass',
        titleTop:'-150px',
        enable: {
            icon: 'boilerEnable_svg',
            pointList: [
                { name: 'BOILER ENABLE', pointName: 'Boiler Enable',  pointValue: '0' }],
            tags: ['boiler', 'enable']
        },
        runHours: {
            icon: 'runHours1_svg',
            pointList: [
                { name: 'BOILER RUN HOURS', pointName: 'Boiler Run Hours' }],
            tags: ['boiler', 'run', 'time']
        },
        power: {
            icon: 'power_svg',
            pointList: [
                { name: 'GAS POWER', pointName: 'Boiler Power' }],
            tags: ['power', 'elec', 'boiler']
        },
        energy: {
            icon: 'energyConsumed_svg',
            pointList: [
                { name: 'GAS FLOW', pointName: 'Boiler Energy Consumption' }],
            tags: ['energy', 'boiler']
        },
        command: {
            pointList: [
                {
                    name: 'BOILER COMMAND',
                    pointName: 'Boiler Command',
                    pointValue: '0'
                }
            ],
            tags: ['boiler', 'run', 'cmd']
        },
        boiler: {
            'leaving': ["hot", "leaving", "temp", "water","sensor"],
            'entering': ["hot", "entering", "temp", "water","sensor"],
            'setPoint': ["hot", "water", "sp"]
          }
    }
};
